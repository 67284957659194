import {Icon} from "@chakra-ui/react";
import {IoDocumentsSharp} from "react-icons/io5";
import roles from "variables/roles";

const {HelpIcon, WalletIcon, StatsIcon, ProfileIcon, DocumentIcon} = require("components/Icons/Icons");

const allRoles = [roles.super, roles.admin, roles.partner, roles.client, roles.doctor];
const sapcRoles = [roles.super, roles.admin, roles.partner, roles.client];
const sapRoles = [roles.super, roles.admin, roles.partner];
const saRoles = [roles.super, roles.admin];

const menuItems = [
	{
		name: "Dashboards",
		path: "/dashboard",
		roles: sapcRoles,
		icon: <StatsIcon color="inherit"/>,
		collapse: true,
		items: [
			{
				name: "Laboratório",
				path: "/dashboard/laboratorios",
				roles: saRoles
			},
			{
				name: "Operacional",
				path: "/dashboard/empresas",
				roles: sapRoles
			},
			{
				name: "Dados Demográficos",
				path: "/dashboard/demografico",
				roles: sapcRoles
			},
			{
				name: "Perfil Epidemiológico",
				path: "/dashboard/epidemiologico",
				roles: sapcRoles
			},
			{
				name: "Resultados dos Exames",
				path: "/dashboard/exames",
				roles: sapRoles
			},
		]
	},
	{
		name: "Solicitações",
		path: "/solicitacoes",
		icon: <WalletIcon color="inherit"/>,
		collapse: true,
		roles: sapcRoles,
		items: [
			{
				name: "Solicitação de Kits",
				path: "/solicitacoes/kits",
				roles: sapRoles
			},
			{
				name: "Coletas",
				path: "/solicitacoes/coletas",
				roles: sapcRoles
			},
		]
	},
	{
		name: "Administração",
		path: "/administracao",
		icon: <Icon as={IoDocumentsSharp} color="inherit"/>,
		collapse: true,
		roles: sapRoles,
		items: [
			{
				name: "Usuários",
				path: "/administracao/usuarios",
				roles: sapRoles
			},
			{
				name: "Unidades",
				path: "/administracao/unidades",
				roles: sapRoles
			},
		]
	},
	{
		name: "Clientes",
		path: "/clientes",
		roles: [roles.doctor],
		icon: <DocumentIcon color="inherit"/>,
		collapse: false,
	},
	// {
	//     name: "Perfil",
	//     path: "/perfil",
	//     roles: sapcRoles,
	//     icon: <ProfileIcon color="inherit"/>,
	//     collapse: false,
	// },
	{
		name: "Suporte",
		path: "/suporte",
		roles: allRoles,
		icon: <HelpIcon color="inherit"/>,
		collapse: false,
	},
]

export default menuItems;
