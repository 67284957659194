import { API } from 'services/api'

const DASHBOARDS_ROUTE = "/dashboard";

export async function SAMPLES_RECEIVED(filters = "") {
  const { data } = await API.get(`${DASHBOARDS_ROUTE}/samples_received${filters}`);

  return data;
}

export async function SAMPLE_PENDING_ANALYSIS(filters = "") {
  const { data } = await API.get(`${DASHBOARDS_ROUTE}/sample_pending_analysis${filters}`);

  return data;
}

export async function SAMPLES_WITH_CONCLUSIVE_RESULTS(filters = "") {
  const { data } = await API.get(`${DASHBOARDS_ROUTE}/samples_with_conclusive_results${filters}`);

  return data;
}

export async function REJECTED_SAMPLES(filters = "") {
  const { data } = await API.get(`${DASHBOARDS_ROUTE}/rejected_samples${filters}`);

  return data;
}

export async function EVOLUTION_REJECTED_SAMPLES(filters = "") {
  const { data } = await API.get(`${DASHBOARDS_ROUTE}/evolution_rejected_samples${filters}`);

  return data;
}
