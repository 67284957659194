import React from "react";

import { Box, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";

import FruitsVegetablesChart from "components/Charts/Nivo/LifeHabits/FruitsVegetablesChart";
import DrinkChart from "components/Charts/Nivo/LifeHabits/DrinkChart";
import AnimalFatChart from "components/Charts/Nivo/LifeHabits/AnimalFatChart";
import PhysicActivityChart from "components/Charts/Nivo/LifeHabits/PhysicActivityChart";

import { queryBuilder } from "functions/queryBuilder";

function LifeHabitsDashboard() {
  const [filters, setFilters] = React.useState({});
  const textColor = useColorModeValue("gray.700", "white");

  React.useEffect(() => {
    const query = queryBuilder(filters);
  }, [filters])

  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox setFilters={setFilters} />
            </Box>
          </CardBody>
        </Card>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        templateRows={{ sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        <Card px="0px" pb="0px" minHeight="400px" maxHeight="400px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Hábitos de Vida
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <LifeHabitsChart />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
        templateRows={{ sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        {/* <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Consumo de Frutas e Verduras
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <FruitsVegetablesChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Consumo de Gordura Animal
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <AnimalFatChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Consumo de Álcool
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <DrinkChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Atividade Física
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <PhysicActivityChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Internação Hospitalar
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <HospitalizationChart />
            </Box>
          </CardBody>
        </Card> */}
        {/* <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Fumantes
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <SmokeChart />
            </Box>
          </CardBody>
        </Card> */}
        {/* <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Uso de Medicamentos Controlados
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <MedicineChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Internação Hospitalar
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <HospitalizationRiskChart />
            </Box>
          </CardBody>
        </Card> */}
      </Grid>
      </Grid>
    </>
  );
}

export default LifeHabitsDashboard;
