import {usePagination, useTable} from "react-table";
import {
    Badge,
    Button,
    Flex, NumberDecrementStepper, NumberIncrementStepper,
    NumberInput, NumberInputField, NumberInputStepper,
    Select,
    Skeleton,
    Stack,
    Table,
    Tbody,
    Icon as ChakraIcon,
    Td,
    Text,
    Th,
    Thead,
    Tr, useColorMode
} from "@chakra-ui/react";

import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import dateFormat from "../../functions/dateFormat";
import states from "../../variables/states";
import cities from "../../variables/cities";
import statusTranslate from "../../functions/statusTranslate";
import {GrFormNext, GrFormPrevious} from "react-icons/gr";
import React, {useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import Icon from "../FontAwesomeIcons/Icon";

const getBadge = (value) => {
    let color;
    const lowerValue = value ? value.toLowerCase() : value;
    switch (lowerValue) {
        case 'aberto':
        case 'open':
            color = 'yellow';
            break;
        case 'em andamento':
            color = 'blue';
            break;
        case 'finalizado':
        case 'finished':
        case 'Finished':
        case 'dispatched':
            color = 'green';
            break;
        case 'cancelado':
        case 'cancelled':
            color = 'red';
            break;
        case 'baixa':
            color = 'green';
            break;
        case 'normal':
            color = 'yellow';
            break;
        case 'alta':
            color = 'orange';
            break;
        case 'urgente':
            color = 'red';
            break;
        default:
            color = 'gray';
            break;
    }

    return color;
}
const getStatusName = (status) => {
    switch (status) {
        case 'Open':
            return 'Aberto'
        case 'Closed':
            return 'Fechado'
        case 'Cancelled':
            return 'Cancelado'
        case 'Lost':
            return 'Perdido'
        case 'Dispatched':
            return 'Enviado'
        default:
            return status;
    }
}
const TableActions = ({id, route, removeAction = null, noView = false, noEdit = false, noDelete = false}) => {
    const { colorMode } = useColorMode();
    const color = (colorMode === 'light') ? "#7180961'" : "#718096"
    const hoverColor = "healthlab.800"

    return (
        <>
        {
            !noView &&
            <Text fontWeight="bold" fontSize="sm" mr={4} color={color} _hover={{ color: hoverColor }}>
                <NavLink to={`${route}/${id}`}>
                    <Icon icon="view" pointer />
                </NavLink>
            </Text>
        }
        {
            !noEdit &&
            <Text fontWeight="bold" fontSize="sm" mr={4} color={color} _hover={{ color: hoverColor }}>
            <NavLink to={`${route}/${id}/editar`}>
                <Icon icon="edit" pointer />
            </NavLink>
            </Text>
        }
        {
            !noDelete && 
            <Text fontWeight="bold" fontSize="sm" color={color} _hover={{ color: hoverColor }} onClick={() => removeAction(id)} >
            <Icon icon="delete" pointer  />
            </Text>
        }
        </>
    )
}

const AsyncTable = ({
                        columns,
                        data,
                        fetchData,
                        isLoading = false,
                        pageCount: controlledPageCount,
                        total,
                        baseRoute,
                        size,
                        noView = false, 
                        noEdit = false, 
                        noDelete = false
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: size }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination

            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
        },
        usePagination
    )


    React.useEffect(() => {
        fetchData({ pageIndex: pageIndex + 1, pageSize })
    }, [pageIndex, pageSize])

    const createPages = () => {
        let arrPageCount = [];
        let startPage = (pageIndex + 1) - 2;
        startPage = startPage <= 0 ? 1 : startPage;
        while (arrPageCount.length < 4 && startPage <= pageCount) {
            arrPageCount.push(startPage);
            startPage ++;
        }

        return arrPageCount;
    };

    return (
        <>
            <Flex
                direction="column"
                w="100%"
                overflowX={{ sm: "scroll", lg: "hidden" }}
            >
                <Stack
                    direction="row"
                    spacing="12px"
                    align="center"
                    my="24px"
                    px="22px"
                >
                    <Select
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                        color="gray.500"
                        size="sm"
                        borderRadius="12px"
                        maxW="75px"
                        cursor="pointer"
                    >
                        <option>5</option>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                    </Select>
                    <Text fontSize="xs" color="gray.400" fontWeight="normal">
                        linhas por páginas
                    </Text>
                </Stack>
                <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
                    <Thead>
                    {headerGroups.map((headerGroup, index) => (
                        <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <Th
                                    {...column.getHeaderProps(column)}
                                    key={index}
                                >
                                    <Flex
                                        justify="space-between"
                                        align="center"
                                        fontSize={{ sm: "10px", lg: "12px" }}
                                        color="gray.400"
                                    >
                                        {column.render("Header")}
                                        <ChakraIcon
                                            w={{ sm: "10px", md: "14px" }}
                                            h={{ sm: "10px", md: "14px" }}
                                            color={columns.isSorted ? "gray.500" : "gray.400"}
                                            float="right"
                                            as={
                                                column.isSorted
                                                    ? column.isSortedDesc
                                                        ? TiArrowSortedDown
                                                        : TiArrowSortedUp
                                                    : TiArrowUnsorted
                                            }
                                        />
                                    </Flex>
                        </Th>
                    ))}
                            <Th hidden={noView && noEdit && noDelete}>
                                <Flex
                                    justify="flex-end"
                                    align="right"
                                    w={{ sm: "100px", md: "100px" }}
                                    fontSize={{ sm: "10px", lg: "12px" }}
                                    color="gray.400"
                                >
                                    Ações
                                </Flex>
                            </Th>
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {
                            page.map((row, index) => {
                                prepareRow(row);

                                return (
                                    <Tr {...row.getRowProps()} key={index}>
                                        {row.cells.map((cell, index) => {
                                            // console.log(cell);
                                            if(['created_at', 'updated_at', 'birth_date'].includes(cell.column.id))
                                                row.cells[index].row.values[cell.column.id] = dateFormat(cell.value)
                                            if(cell.column.id == 'state_id' && !!cell.value){
                                                const state = states.find(state => state.id == cell.value)
                                                row.cells[index].row.values[cell.column.id] = state?.initials || cell.value
                                            }
                                            if(cell.column.id == 'city_id' && !!cell.value){
                                                const city = cities.find(city => city.id == cell.value)
                                                row.cells[index].row.values[cell.column.id] = city?.name || cell.value
                                            }
                                            if(cell.column.id == 'status' && !!cell.value){
                                                const status = statusTranslate[cell.value]
                                                row.cells[index].row.values[cell.column.id] = status || cell.value
                                            }

                                            return (
                                                <Td
                                                    {...cell.getCellProps()}
                                                    fontSize={{ sm: "14px" }}
                                                    key={index}
                                                >
                                                    <Skeleton height={"20px"} isLoaded={!isLoading}>
                                                        {
                                                            cell.column.id == 'priority' || cell.column.id == 'status' ?
                                                                <Badge variant='subtle' colorScheme={getBadge(cell.value)}>
                                                                    { getStatusName(cell.render("Cell")) }
                                                                </Badge>
                                                                :
                                                                cell.column.isLink ?
                                                                    <Link to={`${baseRoute}/${row.original.id}`}>{cell.render("Cell")}</Link>
                                                                :
                                                                cell.render("Cell")
                                                        }
                                                    </Skeleton>
                                                </Td>
                                            );
                                        })}
                                        {
                                        !(noView && noEdit && noDelete) &&
                                        <Td>
                                            <Flex
                                                justify="flex-end"
                                                align="right"
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                w={{ sm: "100px", md: "100px" }}
                                                color="gray.400"
                                            >
                                                { !isLoading && <TableActions id={row.original.id} route={baseRoute}/> }
                                            </Flex>
                                        </Td>
                                        }
                                    </Tr>
                                );
                            })
                        }

                    </Tbody>
                </Table>
                {/*
                    Pagination can be built however you'd like.
                    This is just a very basic UI implementation:
                 */}
                <Flex
                    direction={{ sm: "column", md: "row" }}
                    w="100%"
                    justify="space-between"
                    align="center"
                    px={{ md: "22px" }}>
                    <Text
                        fontSize="sm"
                        color="gray.500"
                        fontWeight="normal"
                        mb={{ sm: "24px", md: "0px" }}
                    >
                        Mostrando {pageSize * pageIndex + 1} à {" "}
                        {(pageSize * pageIndex) + data.length }{" "}
                        do total de {total} resultados
                    </Text>
                    <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
                        <Button
                            variant="no-hover"
                            onClick={() => previousPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="50%"
                            bg="#fff"
                            border="1px solid lightgray"
                            display={
                                 canPreviousPage ? "flex" : "none"
                            }
                            _hover={{
                                bg: "gray.200",
                                opacity: "0.7",
                                borderColor: "gray.500",
                            }}
                        >
                            <ChakraIcon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
                        </Button>
                        {createPages().map((pageNumber, index) => {
                                return (
                                    <Button
                                        variant="no-hover"
                                        transition="all .5s ease"
                                        onClick={() => gotoPage(pageNumber - 1)}
                                        w="40px"
                                        h="40px"
                                        borderRadius="160px"
                                        bg={pageNumber === pageIndex + 1 ? "healthlab.900" : "#fff"}
                                        border="1px solid lightgray"
                                        key={index}
                                    >
                                        <Text
                                            fontSize="sm"
                                            color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                                        >
                                            {pageNumber}
                                        </Text>
                                    </Button>
                                );
                            })}
                        <Button
                            variant="no-hover"
                            onClick={() => nextPage()}
                            transition="all .5s ease"
                            w="40px"
                            h="40px"
                            borderRadius="160px"
                            bg="#fff"
                            border="1px solid lightgray"
                            display={canNextPage ? "flex" : "none"}
                            _hover={{
                                bg: "gray.200",
                                opacity: "0.7",
                                borderColor: "gray.500",
                            }}
                        >
                            <ChakraIcon as={GrFormNext} w="16px" h="16px" color="gray.400" />
                        </Button>
                    </Stack>
                </Flex>
            </Flex>
        </>
    )
}

export default AsyncTable;
