import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import {defaultColors} from "variables/colors";
import Loading from "components/Loading/Loading";
import {useDemographicDash} from "hooks/demographicDash";
import {border, themeDark, tooltip} from "variables/nivo/default";
import {arc, arcLinkLabelsColor, legendsDark, legendsLight, margin, pieProps} from "variables/nivo/halfPie";

const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const GenderChart = () => {
    const { isLoadingCustomersGenderAmount: isLoading, dash_val: data } = useDemographicDash();

    const { colorMode } = useColorMode();

    if (!isLoading && !!data && !!data.gender_amount_by_partner) {
        return (
            <ResponsivePie
                data={data.gender_amount_by_partner}
                margin={margin}
                colors={defaultColors}
                theme={themeDark}
                {...border}
                {...arc}
                startAngle={-90}
                endAngle={90}
                arcLinkLabelsColor={arcLinkLabelsColor}
                arcLabelsTextColor={"#fff"}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.gender_amount_by_partner), datum.color)}
                {...pieProps}
            />
        )
    }

    return <Loading/>;
};

export default GenderChart
