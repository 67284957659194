import { API } from './api'


export async function GET_NOTIFICATIONS() {
	const { data } = await API.get(`notifications`);

	return data;
}

export async function MARK_READ(notificationID) {
	const { data } = await API.put(`notification/read/${notificationID}`);

	return data;
}

export async function DOWNLOAD_NOTIFICATION(notificationID) {
	const { data } = await API.get(`notification/download/${notificationID}`);

	return data.link;
}
