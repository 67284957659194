import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import { useChangePagination, useClients } from "../../hooks/clients";
import AsyncTable from "../../components/Tables/AsyncTable";
import ClientsTable from "components/Tables/Clients/ClientsTable";

const columns = [
  { Header: "Nome", accessor: "name" },
  { Header: "CPF", accessor: "cpf" },
  { Header: "Unidade", accessor: "kits[0].order[0].partner_place.name" },
  { Header: "Telefone", accessor: "cellphone" },
  { Header: "Cadastrado em", accessor: "created_at" },
  { Header: "E-mail", accessor: "email" },
  { Header: "Nascimento", accessor: "birth_date" },
];

const Clients = () => {
    const textColor = useColorModeValue("gray.700", "white");
    const [page, setPage] = useState({
        pageIndex: 1,
        pageSize: 15
    })
    // const { clients } = useClients()
    // const { isLoading } = useChangePagination(page)

    return (<Grid templateColumns={{sm: "1fr", md: "1fr"}} templateRows={{sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)"}}
                  gap="12px" pt={{sm: "125px", lg: "160px"}}>
        <Card px="0px" pb="24px" minHeight="320px">
            <CardHeader mb="34px" px="22px">
                <Box w="100%">
                    <Flex justify="space-between" align="center" fontSize={{sm: "10px", lg: "12px"}} color="gray.400">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Clientes
                        </Text>
                    </Flex>
                </Box>
            </CardHeader>
            <CardBody h="100%">
                <Box w="100%" h="100%">
                    <ClientsTable />
                    {/* {!clients.data ?
                        <Flex justifyContent="center" alignItems="center" w="100%" h="100%" pl="22px" pr="22px"> <Text> Não foram
                            encontrados usuários cadastrados </Text> </Flex>
                        :
                        <AsyncTable data={clients.data}
                                    pageCount={clients.last_page}
                                    total={clients.total}
                                    loading={!isLoading}
                                    columns={columns}
                                    size={clients.per_page}
                                    baseRoute={"cliente"}
                                    fetchData={setPage}/> */}
                </Box>
            </CardBody>
        </Card>
    </Grid>)
}

export default Clients;
