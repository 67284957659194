import React from "react";
import {ResponsivePie} from "@nivo/pie";

import {useDemographicDash} from "hooks/demographicDash";

import {useColorMode} from "@chakra-ui/react";
import {border, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {defaultColorsPlus} from "variables/colors";
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from "variables/nivo/pie";
import Loading from "components/Loading/Loading";

const total = (data) => {
	let total = 0;
	data.map(item => {
		total += item.value;
	})

	return total;
}

const CenteredMetric = ({dataWithArc, centerX, centerY}) => {
	const {colorMode} = useColorMode();
	const color = colorMode === "light" ? "#000" : "#FFF"
	let total = 0
	dataWithArc.forEach(datum => {
		total += datum.value
	})

	return (
		<text
			x={centerX}
			y={centerY}
			textAnchor="middle"
			dominantBaseline="central"
			style={{
				fontSize: "52px",
				fontWeight: 600,
				color: "rgb(255,255,255)",
				fill: color
			}}
		>
			{total}
		</text>
	)
}

const KitTotalChart = () => {
	const {isLoadingKitTotal: isLoading, dash_val: data} = useDemographicDash();

	const {colorMode} = useColorMode();

	if (isLoading)
		return <Loading/>

	return (
		<ResponsivePie
			data={data.kit_total}
			margin={margin}
			colors={defaultColorsPlus}
			theme={colorMode === "light" ? themeLight : themeDark}
			{...border}
			{...arc}
			arcLinkLabelsColor={arcLinkLabelsColor}
			arcLabelsTextColor={arcLabelsTextColor}
			legends={colorMode === "light" ? legendsLight : legendsDark}
			tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.kit_total), datum.color)}
			{...pieProps}
			innerRadius={0.7}
			layers={["arcs", "arcLabels", "arcLinkLabels", "legends", CenteredMetric]}
		/>
	)
}

export default KitTotalChart