import AuthProvider from './authProvider'
import { QueryProvider } from './queryClient'

const AppProvider = ({ children }) => {
  return (
    <QueryProvider>
      <AuthProvider>
        {children}
      </AuthProvider>
    </QueryProvider>
  )
}

export default AppProvider
