

import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import Dropzone, { useDropzone } from "react-dropzone";

import { useTicket, useUpdateTicket } from 'hooks/tickets'

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Skeleton,
  Grid,
  Input,
  Textarea,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import priorities from "variables/priorities";

const schema = yup.object().shape({
    priority: yup
      .string()
      .required("O campo Prioridade é obrigatório"),
    source: yup
			.string()
			.required("O campo é obrigatório"),
    causes: yup
      .string()
      .required("O campo é obrigatório"),
    abstract: yup
      .string()
      .required("O campo é obrigatório"),
})

const EditTicket = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useTicket(id);

  const methods = useForm();
  const toast = useToast()
  const history = useHistory();

  const [files, setFiles] = React.useState([]);
	
  const { getRootProps, getInputProps } = useDropzone();
  
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({
      mode: "onBlur",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });

  const { mutateAsync: ticket, isLoading: isLoadingUpdate  } = useUpdateTicket(id, {
    onSuccess: () => {
      toast({
        title: 'Ticket atualizado com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/suporte/${id}`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const handleDrop = (acceptedFiles) => {
    setValue('files', acceptedFiles)
    setFiles(acceptedFiles)
  }

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w="50%">
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Editar Ticket #{id}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(ticket))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    templateRows={{ md: "repeat(1, 1fr)" }}
                    gap="24px"
                  >
                  <FormControl isInvalid={errors.priority}>
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Proridade
                    </FormLabel>
                    {
                      isLoading ?
                      <Skeleton height={"40px"} />
                      :
                      <Select
                        {...register('priority')} 
                        defaultValue={data.priority || null}
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder="Selecione uma opção..."
                        color="gray.400"
                      >
                        {
                          priorities.map(priority => {
                            return (
                              <option id={priority.value} value={priority.value}>{priority.name}</option>
                            )
                          })
                        }
                      </Select>
                    }
                    {errors.priority && <FormErrorMessage>{errors.priority.message}</FormErrorMessage>}
                  </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    templateRows={{ md: "repeat(1, 1fr)" }}
                    gap="24px"
                    mt="24px"
                  >
                    <FormControl isInvalid={errors.source}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Onde encontrou o erro?
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('source')} 
                          defaultValue={data?.content?.source || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.source && <FormErrorMessage>{errors.source.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.causes}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        O que o erro causa?
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('causes')} 
                          defaultValue={data?.content?.causes || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.causes && <FormErrorMessage>{errors.causes.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.abstract}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Resuma o erro encontrado
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Textarea
                          {...register('abstract')} 
                          defaultValue={data?.content?.abstract || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.abstract && <FormErrorMessage>{errors.abstract.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Anexo
                      </FormLabel>
                      <Flex
                        align="center"
                        justify="center"
                        border="1px dashed #E2E8F0"
                        borderRadius="15px"
                        w="100%"
                        minH="130px"
                        cursor="pointer"
                        {...getRootProps({ className: "dropzone" })}
                      >
                        <Dropzone onDrop={handleDrop}  >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              { files.length === 0 ? <p>Arraste ou selecione um arquivo</p> : <p>Arquivo selecionados: {files.length}</p>}
                            </div>
                          )}
                        </Dropzone>
                      </Flex>
                    </FormControl>
                  </Grid>
                  <Flex mt="24px" align={"center"} justify={"flex-end"}>
                    <NavLink to="/sistema/suporte">
                      <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                        Cancelar
                      </Text>
                    </NavLink>
                    <Button
                      isLoading={isLoadingUpdate}
                      type='submit'
                      variant="no-hover"
                      bg={buttonBg}
                      alignSelf="flex-end"
                      w="100px"
                      h="35px"
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        Atualizar
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default EditTicket;
