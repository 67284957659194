import { AuthProvider as Provider } from 'hooks/auth'
import {NotificationProvider} from "../hooks/notifications";

const AuthProvider = ({ children }) => {
  return (
      <Provider>
          <NotificationProvider>
            {children}
          </NotificationProvider>
      </Provider>
  )
}

export default AuthProvider
