const arc = {
	enableArcLinkLabels: false,
	arcLinkLabelsSkipAngle: 10,
	arcLinkLabelsTextColor: "#FFF",
	arcLinkLabelsThickness: 4,
	arcLabelsSkipAngle: 10,
}

const arcLinkLabelsColor = { from: 'color' }

const arcLabelsTextColor = { 
	from: 'color',	
	modifiers: [
		[
			'brighter',
			8
		]
	]
}

const margin = {
	top: 30, 
  right: 40, 
  bottom: 40, 
  left: 140
}

const legendsDark = [
	{
		anchor: 'left',
		direction: 'column',
		justify: false,
		translateX: -120,
		translateY: 0,
		itemsSpacing: 15,
		itemWidth: 50,
		itemHeight: 18,
		itemTextColor: '#FFF',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle',
	}
]

const legendsLight = [
	{
		anchor: 'left',
		direction: 'column',
		justify: false,
		translateX: -120,
		translateY: 0,
		itemsSpacing: 15,
		itemWidth: 50,
		itemHeight: 18,
		itemTextColor: '#000',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle',
	}
]

const radialProps = {
  padding: 0.4,
  padAngle: 0,
  cornerRadius: 0,
  radialAxisStart: { tickSize: 5, tickPadding: 5, tickRotation: 0 },
  circularAxisOuter: { tickSize: 5, tickPadding: 12, tickRotation: 0 },
  borderWidth: 0
}

export {
	arc,
	arcLinkLabelsColor,
	arcLabelsTextColor,
	margin,
	legendsDark,
	legendsLight,
	radialProps
}