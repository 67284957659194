import {createContext, useContext, useReducer} from "react";
import {useFilter} from "./dashFilter";
import {useQuery} from "react-query";
import { KIT_TOTAL } from "services/dashboards/demographic";
import { GET_ALL_EPIS, GET_ALL_FACTORS, GET_ALL_RISKS } from "../services/dashboards/epidemiological";

const ACTION_TYPES = {
    SET_VALUES_DEFAULT: "SET_VALUES_DEFAULT",
    UPDATE_FILTERS: "UPDATE_FILTERS"
};

const reducers = (state, action) => {
    switch (action.type) {
        case  ACTION_TYPES.UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.data
            }
        }
        case ACTION_TYPES.SET_VALUES_DEFAULT: {
            const newValue = {
                ...state.dash_val,
                ...action.data
            };
            return {
                ...state,
                dash_val: {
                    ...state.dash_val,
                    ...action.data
                }
            }
        }
        default: {
            return state
        }
    }
};

const EpidemicDashStateContext = createContext();
const EpidemicDashDispatchContext = createContext();
const useEpidemicDash = () => {
    const state = useContext(EpidemicDashStateContext);
    if (!state) throw new Error("Não há provider");
    return state;
};

const EpidemicDashProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducers, {filters: "", dash_val: {}});
    const {filters} = useFilter();
    const [
        {isFetching: isLoadingKitTotal},
        {isFetching: isLoadingGetAllEpis},
        {isFetching: isLoadingGetAllRisks},
        {isFetching: isLoadingGetAllFactors},
    ] = queryMultiple({
        onSuccess: (data) => {
            dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data});
        }
    }, filters);

    return (
        <EpidemicDashStateContext.Provider value={{isLoadingKitTotal, isLoadingGetAllEpis, isLoadingGetAllRisks, isLoadingGetAllFactors, ...state}}>
            <EpidemicDashDispatchContext.Provider value={dispatch}>
                {children}
            </EpidemicDashDispatchContext.Provider>
        </EpidemicDashStateContext.Provider>
    )
};
const queryMultiple = (setValueChart, filters = "", debug = false) => {
    const kitTotal = useQuery(["kit_total", filters], () => KIT_TOTAL(filters), setValueChart);
    const get_all_epis = useQuery(["get_all_epis", filters], () => GET_ALL_EPIS(filters), setValueChart);
    const get_all_risks = useQuery(["get_all_risks", filters], () => GET_ALL_RISKS(filters), setValueChart);
    const get_all_factors = useQuery(["get_all_factors", filters], () => GET_ALL_FACTORS(filters), setValueChart);

    return [kitTotal, get_all_epis, get_all_risks, get_all_factors];
};

export {
    EpidemicDashProvider,
    useEpidemicDash,
}
