import React from 'react'

import { Box, Flex, Spinner, Text } from '@chakra-ui/react'

const LoadingScreen = () => {
  return (
    <Box width="100%" height="100vh">
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center" fontSize="30px" >
        <Text fontSize="30px">
          <Spinner size="xl" w="80px" h="80px" />
        </Text>
      </Flex>
    </Box>
  )
}

export default LoadingScreen