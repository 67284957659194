
import React from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useLogin } from 'hooks/auth';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import greenBg from "assets/img/backgroundcard.png";
import { useVerify } from 'hooks/auth';
import Loading from 'components/Loading/Loading';
import { useRecoverPassword } from 'hooks/auth';

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Insira sua nova senha")
    .matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Sua senha deve conter pelos menos 8 caracteres, um maiúsculo, um número e um caracter especial"
    ),
  password_confirmation: yup
    .string()
    .required("Confirme sua senha")
    .oneOf([yup.ref('password'), null], "Senhas não coincidem."),
})

const RecoverPassword = ({email, hash}) => {
  const methods = useForm();
	const history = useHistory()

  const payload = { email, hash }

  const { isLoading: isLoadingVerify, data: dataVerify } = useVerify(payload);
  
  const { register, handleSubmit, formState: { errors }, setError, setValue } = useForm({
      mode: "onChange",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    setValue('email', email);
    setValue('hash', hash)
  }, [email, hash])

  const [sent, setSent] = React.useState(false);
  
  const { isLoading, mutateAsync: data } = useRecoverPassword({
    onSuccess: () => setSent(true),
    onError: () => setError("login", { message: "Não foi possível trocar sua senha." })
  })

  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ sm: "0px", md: "50px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={greenBg}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
					Health ID
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px" 
						mr={{ sm: "20px" }}
						ml={{ sm: "20px" }}>
			<FormProvider {...methods}>
        {
          sent ?
          <Flex
            direction="column"
            w={{md: "445px", sm: "100%"}}
            background="transparent"
            borderRadius="15px"
            p="40px"
            bg={bgColor}
            boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
          >
            <Text mb="0.5rem">
              Sua senha foi alterada com sucesso! Clique no botão abaixo para retornar à pagina de login.
            </Text>
            <NavLink to="/login">
            <Button
              bg="healthlab.900"
              fontSize="sm"
              color="white"
              fontWeight="bold"
              w="100%"
              h="45"
              _hover={{
                bg: "healthlab.800",
              }}
              _active={{
                bg: "healthlab.800",
              }}
            >
              Voltar
            </Button>
            </NavLink>
          </Flex>
          :
          <form onSubmit={(handleSubmit(data))}>
              <Flex
                direction="column"
                w={{md: "445px", sm: "100%"}}
                background="transparent"
                borderRadius="15px"
                p="40px"
                bg={bgColor}
                boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
              >
          {
            isLoadingVerify ?
              <Loading />
            :
            <>
              {
                !dataVerify.resp ?
                <Text mb="0.5rem">
                  O link utlizado para recuperação de senha expirou ou é inválido.
                </Text>
                :
                <>
                  <Text mb="0.5rem">
                    Insira sua nova senha para finalizar o processo de recuperação.
                  </Text>
                    <FormControl isInvalid={errors.password} mb="24px">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Nova Senha
                      </FormLabel>
                      <Input
                        {...register('password')} 
                        fontSize="sm"
                        ms="4px"
                        borderRadius="15px"
                        type="password"
                        mb="5px"
                        size="lg"
                      />
                      {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.password_confirmation} mb="24px">
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Confirmar Nova Senha
                      </FormLabel>
                      <Input
                        {...register('password_confirmation')} 
                        fontSize="sm"
                        ms="4px"
                        borderRadius="15px"
                        type="password"
                        mb="5px"
                        size="lg"
                      />
                      {errors.password_confirmation && <FormErrorMessage>{errors.password_confirmation.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.password} mb="24px">
                      <Button
                        isLoading={isLoading}
                        type="submit"
                        bg="healthlab.900"
                        fontSize="sm"
                        color="white"
                        fontWeight="bold"
                        w="100%"
                        h="45"
                        _hover={{
                          bg: "healthlab.800",
                        }}
                        _active={{
                          bg: "healthlab.800",
                        }}
                      >
                        Enviar
                      </Button>
                      { errors.login && <Text color="red.300" mt="0.5rem">{errors.login.message}</Text> }
                    </FormControl>
                </>
              }
              
            </>
          }
          </Flex>
				</form>
        }
			</FormProvider>
      </Flex>
    </Flex>
  );
}

export default RecoverPassword;
