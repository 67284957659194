import {API} from "services/api";

const DASHBOARDS_ROUTE = "/dashboard";

export async function KIT_TOTAL(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/kit_total${filters}`);
    return data;
}

export async function CUSTOMERS_GENDER_AMOUNT(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/gender_amount_by_partner${filters}`);
    return data;
}

export async function CUSTOMERS_ETHINICITY_AMOUNT(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/ethnicities_amount_by_partner${filters}`);
    return data;
}

export async function CUSTOMERS_AVERAGE_AGE(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/average_age_by_partner${filters}`);
    return data;
}

export async function CUSTOMERS_AGE_GROUP(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/age_group_by_partner${filters}`);
    return data;
}

export async function CUSTOMERS_BODY_MASS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/imc_amount_by_partner${filters}`);
    return data;
}

export async function CUSTOMERS_LIFE_HABITS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/imc_amount_by_partner${filters}`);
    return data;
}

export async function GET_SMOKER(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_smoker${filters}`);
    return data;
}

export async function GET_CONTROLLED_MEDICATION(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_controlled_medication${filters}`);
    return data;
}

export async function GET_HOSPITALIZATION(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_hospitalization${filters}`);
    return data;
}

export async function GET_EAT_GREENS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_eat_greens${filters}`);
    return data;
}

export async function GET_EAT_MEAT(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_eat_meat${filters}`);
    return data;
}

export async function GET_DRINK(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_drink${filters}`);
    return data;
}

export async function GET_ACTIVITY(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_activity${filters}`);
    return data;
}
