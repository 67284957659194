import {Button, Flex, FormControl, FormLabel, Text, useColorModeValue} from "@chakra-ui/react";
import React, {useState} from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import FileResizer from "react-image-file-resizer";
const CustomImg = styled.img`
`
const resizeImage =  (file) =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            300,
            100,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "file"
        );
})

export default ({ callback, isLoading, url = null }) => {
    const [img, setImg] = useState(url)
    const {handleSubmit, register} = useForm();
    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");

    const mountFormData = async (data) => {
        const signature = await resizeImage(data.signature[0]);
        const formData = new FormData();
        formData.append("signature", signature);
        callback(formData);
    }
    const resizeSignature =  async (event) => {
        const signature = await resizeImage(event.target.files[0]);
        setImg(URL.createObjectURL(signature))
    }
    return (
            <form style={{width: '100%'}} onSubmit={handleSubmit(mountFormData)}>
                <FormControl>
                    <FormLabel color={textColor} fontWeight="bold" fontSize="xs">
                        Anexar Assinatura com Carimbo
                    </FormLabel>
                    <Flex align="center" justify="center" border="1px dashed #E2E8F0" w="100%"
                          borderRadius="15px" minH="130px" cursor="pointer">
                        { img ?
                            (<CustomImg src={img} alt="" className="preview-signature"/>)
                            :
                            <input
                                accept="image/jpg,image/jpeg,image/png"
                                {...register('signature', {
                                    required: true,
                                    onChange: resizeSignature
                                })}
                                type="file"
                            />
                        }
                    </Flex>
                </FormControl>
                <Flex mt="24px" align={"center"} justify={"flex-end"}>

                    {img &&
                            <Text mr="20px"
                                  onClick={() => url === img ? setImg(null) : setImg(url)}
                              fontSize="xs"
                              color={textColor}
                              fontWeight="bold"
                              >
                            Remover
                        </Text>
                }

                    <Button isLoading={isLoading} type='submit' variant="no-hover" bg={buttonBg}
                            alignSelf="flex-end" w="100px" h="35px">
                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                            {url ? "Atualizar" : "Salvar"}
                        </Text>
                    </Button>
                </Flex>
            </form>
    );
}
