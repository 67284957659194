import React from "react";

import { Box, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";
// import RisksChart from "components/Charts/Nivo/Risks/RisksChart";
// import CardiovascularRiskChart from "components/Charts/Nivo/Risks/CardiovascularRiskChart";
// import AVCRiskChart from "components/Charts/Nivo/Risks/AVCRiskChart";
// import HeartDiseaseRiskChart from "components/Charts/Nivo/Risks/HeartDiseaseRiskChart";
// import MetabolicRiskChart from "components/Charts/Nivo/Risks/MetabolicRiskChart";
// import DyslipidemiaRiskChart from "components/Charts/Nivo/Risks/DyslipidemiaRiskChart";
// import PreliminaryFactorsChart from "components/Charts/Nivo/Risks/PreliminaryFactorsChart";

import { queryBuilder } from "functions/queryBuilder";

function RisksDashboard() {
  const [filters, setFilters] = React.useState({});
  const textColor = useColorModeValue("gray.700", "white");

  React.useEffect(() => {
    const query = queryBuilder(filters);
  }, [filters])

  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox setFilters={setFilters} />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      {/* <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Riscos
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box  w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <RisksChart />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Fatore Preliminares
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box  w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <PreliminaryFactorsChart />
            </Box>
          </CardBody>
        </Card>
      </Grid> */}
      {/* <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Risco Cardiovascular
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box  w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <CardiovascularRiskChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Risco de Internação
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <HospitalizationRiskChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              AVC
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <AVCRiskChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Doenças do Coração
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <HeartDiseaseRiskChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Dislipidemia
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <DyslipidemiaRiskChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Síndrome Metabólica
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <MetabolicRiskChart />
            </Box>
          </CardBody>
        </Card>
      </Grid> */}
    </>
  );
}

export default RisksDashboard;
