import React, {createContext, useContext, useMemo, useReducer} from "react"
import {useMutation, useQuery} from "react-query"
import {isEmpty} from "lodash"

import {
    DELETE_CLIENT,
    GET_CLIENT,
    GET_CLIENTS,
    POST_CLIENT,
    UPDATE_CLIENT,
    UPDATE_CLIENT_CARE_PLAN
} from "services/clients"

const ACTION_TYPES = {
    LIST: "LIST_CLIENTS", VIEW: "VIEW_CLIENT", UPDATE_CARE_PLAN: "UPDATE_CARE_PLAN"
}

const clientsReducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.LIST: {
            return {
                ...state, clients: action.data
            }
        }
        case ACTION_TYPES.VIEW: {
            return {
                ...state, client: action.data
            }
        }
        case ACTION_TYPES.UPDATE_CARE_PLAN: {
            return {
                ...state, client: {
                    ...state.client,
                    care_plan: action.data
                }
            }
        }
        default: {
            return state
        }
    }
}

const ClientsStateContext = createContext()
const ClientsDispatchContext = createContext()

const ClientsProvider = ({children}) => {
    const [state, dispatch] = useReducer(clientsReducer, {clients: {}, client: null})

    const { isLoading, isFetching } = useQuery(['clients'], () => GET_CLIENTS(1, 10), {
            onSuccess: (data) => {
                if (isEmpty(data)) {
                    return dispatch({ type: ACTION_TYPES.LIST, data })
                }
                dispatch({ type: ACTION_TYPES.LIST, data })
            },
    })

    return (
    <ClientsStateContext.Provider value={{ isLoading, isFetching, ...state}}>
        <ClientsDispatchContext.Provider value={dispatch}>{children}</ClientsDispatchContext.Provider>
    </ClientsStateContext.Provider>)
}

const useClients = () => {
    const context = useContext(ClientsStateContext)

    if (!context) throw new Error("useClients must be used within an ClientsProvider")

    return context
}

const useChangePagination = (page) => {
    const dispatch = useDispatch()
    return useQuery(["clients", page],  () => GET_CLIENTS(page), {
        onSuccess: (data) => {
            if (!isEmpty(data)) {
                dispatch({type: ACTION_TYPES.LIST, data})
            }
        },
    })
}

const useDispatch = () => {
    const dispatch = useContext(ClientsDispatchContext)

    if (dispatch === undefined) throw new Error("useDispatch must be used within a ClientsProvider")

    return dispatch
}

const useClient = (id, options = {}) => {
    const dispatch = useDispatch()

    return useQuery(["client"], () => GET_CLIENT(id), {
        ...options, onSuccess: (data) => {
            dispatch({type: ACTION_TYPES.VIEW, data})
        },
    })
}

const useClientCarePlanUpdate = (id, options = {}) => {
    const dispatch = useDispatch()
    const {onSuccess} = options

    return useMutation((data) => UPDATE_CLIENT_CARE_PLAN(id, data), {
        ...options, onSuccess: (data) => {
            if (typeof onSuccess === 'function')
                onSuccess(data)
            dispatch({type: ACTION_TYPES.UPDATE_CARE_PLAN, data})
        },
    })
}
export {
    ClientsProvider, useClients, useClient, useChangePagination, useClientCarePlanUpdate
}
