import React from "react";
import Loading from "components/Loading/Loading";
import {useExamDash} from "hooks/examDash";
import {useColorMode} from "@chakra-ui/react";
import {ResponsiveLine} from "@nivo/line";
import {lineColors} from "variables/colors";
import {legendsDark, legendsLight, lineProps, margin, padding} from "variables/nivo/line";
import {axisBottom, axisLeft, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";

const CheckUpKitsHba1c = () => {
	const {isLoadingGetCheckUpKitsHba1c: isLoading, dash_val: data} = useExamDash();
	const {colorMode} = useColorMode();

	if (isLoading || !data?.get_check_up_kits_hba1c) {
		return <Loading/>;
	}

	return (
		<ResponsiveLine
			data={data?.get_check_up_kits_hba1c}
			margin={margin}
			padding={padding}
			theme={colorMode === 'light' ? themeLight : themeDark}
			axisBottom={axisBottom}
			axisLeft={axisLeft}
			colors={lineColors}
			labelTextColor={labelTextColor}
			tooltip={({point}) => tooltip(`${point.serieId} - ${point.data.y}`, point.data.yStacked, null, point.color)}
			legends={colorMode === 'light' ? legendsLight : legendsDark}
			{...lineProps}
		/>
	)
}

export default CheckUpKitsHba1c