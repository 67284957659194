
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useLogin } from 'hooks/auth';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import basic from "assets/img/basic-auth.png";
import greenBg from "assets/img/backgroundcard.png";
import React from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

const schema = yup.object().shape({
    email: yup
      .string()
      .required("Insira seu e-mail"),
    password: yup
			.string()
			.required("Insira sua senha"),
})

const Login = () => {
  const methods = useForm();
	const history = useHistory()
  
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
      mode: "onBlur",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });
  
  const { isLoading, mutateAsync: data } = useLogin({
    onSuccess: () => history.push('/sistema'),
    onError: () => setError("login", { message: "Não foi possível realizar login com as credenciais informadas." })
  })
  
  const titleColor = useColorModeValue("healthlab.900", "healthlab.700");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ sm: "0px", md: "50px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={greenBg}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
					Health ID
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px" 
						mr={{ sm: "20px" }}
						ml={{ sm: "20px" }}>
			<FormProvider {...methods}>
        <form onSubmit={(handleSubmit(data))}>
					<Flex
						direction="column"
						w={{md: "445px", sm: "100%"}}
						background="transparent"
						borderRadius="15px"
						p="40px"
						bg={bgColor}
						boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
					>
						<FormControl isInvalid={errors.email} mb="24px">
							<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
								E-mail
							</FormLabel>
							<Input
								{...register('email')} 
								fontSize="sm"
								ms="4px"
								borderRadius="15px"
								type="text"
								placeholder="E-mail"
								mb="5px"
								size="lg"
							/>
							{errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.password} mb="24px">
							<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
								Senha
							</FormLabel>
							<Input
								{...register('password')} 
								fontSize="sm"
								ms="4px"
								borderRadius="15px"
								type="password"
								placeholder="Senha"
								size="lg"
							/>
							{errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.password} mb="24px">
							<FormControl display="flex" alignItems="center" mb="24px">
								<Switch id="remember-login" colorScheme="healthlab" me="10px" />
								<FormLabel htmlFor="remember-login" mb="0" fontWeight="normal">
									Lembrar-me
								</FormLabel>
							</FormControl>
							<Button
								isLoading={isLoading}
								type="submit"
								bg="healthlab.900"
								fontSize="sm"
								color="white"
								fontWeight="bold"
								w="100%"
								h="45"
								_hover={{
									bg: "healthlab.800",
								}}
								_active={{
									bg: "healthlab.800",
								}}
							>
								Entrar
							</Button>
							{ errors.login && <Text color="red.300" mt="0.5rem">{errors.login.message}</Text> }
						</FormControl>
						<Flex
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							maxW="100%"
							mt="0px"
						>
							<Text color={textColor} fontWeight="medium">
								<NavLink
									color={titleColor}
									as="span"
									ms="5px"
									to="/login/esqueci"
									fontWeight="bold"
								>
									Esqueci minha senha
								</NavLink>
							</Text>
						</Flex>
					</Flex>
				</form>
			</FormProvider>
      </Flex>
    </Flex>
  );
}

export default Login;
