import React from "react";
import Loading from "components/Loading/Loading";
import {ResponsivePie} from "@nivo/pie";
import {useExamDash} from "hooks/examDash";
import {useColorMode} from "@chakra-ui/react";
import {border, chartColors, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {
	arc,
	arcLabelsTextColor,
	arcLinkLabelsColor,
	legendsDark,
	legendsLight,
	margin,
	pieProps
} from "variables/nivo/pie";

const test = [
	{
		id: "nenhuma",
		label: "Nenhuma vez por semana",
		value: Math.floor(Math.random() * (100)),
	},
];

const total = (data) => {
	let total = 0;
	if (!!data) {
		data.map(item => {total += item.value;});
	}

	return total;
}

const CheckUpKitsTotal = () => {
	const {isLoadingGetCheckUpKitsTotal: isLoading, dash_val: data} = useExamDash();
	const {colorMode} = useColorMode();

	if (isLoading) {
		return <Loading/>;
	}

	return (
		<ResponsivePie
			data={data?.get_check_up_kits_total || test}
			margin={margin}
			colors={chartColors}
			theme={themeDark}
			{...border}
			{...arc}
			arcLinkLabelsColor={arcLinkLabelsColor}
			arcLabelsTextColor={arcLabelsTextColor}
			legends={colorMode === 'light' ? legendsLight : legendsDark}
			tooltip={({datum}) => tooltip(datum.label, datum.value, total(data?.get_check_up_kits_total || test), datum.color)}
			{...pieProps}
			innerRadius={0.7}
		/>
	)
}

export default CheckUpKitsTotal