import { useUsers } from 'hooks/users'

import { Flex, Text } from "@chakra-ui/react";

import BasicTable from '../BasicTable'

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "Unidade",
    accessor: "units[0].name",
  },
  // {
  //   Header: "Perfil",
  //   accessor: "type",
  // },
  {
    Header: "Cadastrado em",
    accessor: "created_at",
  },
]

const UsersTable = () => {
  const { isLoading, isFetching, users, setToDelete, setDeleteModalOpen } = useUsers()

  const deleteUser = (id) => {
    setToDelete(id),
    setDeleteModalOpen(true)
  }

  if(isLoading || isFetching)
		return (
      <>
        { 
          <BasicTable 
            isLoading={true} 
            tableData={[{},{},{},{},{},{},{},{}]} 
            columnsData={columns} 
            route="usuarios" 
            removeAction={deleteUser} 
          /> 
        }
      </>
		)

  if(users.length === 0)
    return (
      <Flex
        justifyContent="center"  
        alignItems="center"
        w="100%"
        h="100%"
        pl="22px"
        pr="22px"
      >
        <Text>
          Não foram encontrados usuários cadastrados
        </Text>
      </Flex>
    )

  return (
    <BasicTable 
      tableData={users} 
      columnsData={columns} 
      route="usuarios" 
      removeAction={deleteUser} 
    />
  )
}

export default UsersTable
