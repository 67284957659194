import React from 'react'

import { Box, Flex, Spinner } from '@chakra-ui/react'

const Loading = () => {
	return (
		<Box w={"100%"} h={"100%"}>
			<Flex 
				w={"100%"} h={"100%"}
				justify="center"
				align="center">
				<Spinner size='xl'/>
			</Flex>
		</Box>
	)
}

export default Loading