import { Box, Flex, Grid, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";

const CustomerKits = ({ kits = [], handleKitClick, getDate }) => {
  const importAll = (context) => {
    let images = {};
    context.keys().map((item, i) => {
      images[
        item.replace("./", "").replace(/\.(png|jpe?g|svg)$/, "")
      ] = context(item);
    });
    return images;
  };

  const hoverColor = useColorModeValue("#67676740", "#FEFEFE40");

  const images = importAll(
    require.context("assets/img/kits/", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <Box width="100%">
      <Grid
        templateColumns={{ sm: "1fr", lg: "repeat(1, 1fr)",}}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="24px"
        w={"100%"}
        maxH="500px"
        overflowY="auto"
      >
        {kits.map((kit) => {
          if (kit.status != "finished") return;

          return (
            <Flex
              cursor="pointer"
              borderRadius={10}
              padding={5}
              transition=".5s ease"
              _hover={{
                ".img-kit": {
                  boxSize: "125px",
                },
                backgroundColor: hoverColor,
              }}
              onClick={() => handleKitClick(kit)}
            >
              {kit?.kit_type_id && images[kit.kit_type_id] ? (
                <Image
                  className="img-kit"
                  src={images[kit.kit_type_id]["default"]}
                  boxSize="110px"
                  transition=".5s ease"
                />
              ) : null}

              <Box
                className="box-group"
                width="100%"
                p={4}
                transition=".5s ease"
              >
                <Flex width="100%" flexDirection="column" gap={2}>
                  <Box width="100%">
                    <VStack align="stretch" spacing="0.1">
                      <Text fontSize="20px">{kit?.kit_type?.name}</Text>
                    </VStack>
                  </Box>
                  <Flex width="100%" justifyContent="space-between">
                    <VStack align="stretch" spacing="0.1">
                      <Text textAlign="left" fontSize="16px">
                        Número
                      </Text>
                      <Text fontSize="20px">{kit.number}</Text>
                    </VStack>
                    <VStack align="stretch" spacing="0.1">
                      <Text textAlign="left" fontSize="16px">
                        Data de aferição
                      </Text>
                      <Text fontSize="20px">
                        {kit?.confirmed_at ? getDate(kit.confirmed_at) : null}
                      </Text>
                    </VStack>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CustomerKits;
