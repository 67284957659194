import {createContext, useContext, useReducer} from "react";
import {useMutation, useQuery} from "react-query";
import Pusher from "pusher-js";
import {useAuth} from "./auth";
import { GET_NOTIFICATIONS } from "services/notifications";
import { isEmpty } from "lodash";
import { useToast } from "@chakra-ui/react";

const ACTION_TYPE = {
    MESSAGE_RECEIVED: "MESSAGE_RECEIVED",
    LIST_MESSAGES: "LIST_MESSAGES"
}

const reducers = (state, action) => {
    switch (action.type) {
        case ACTION_TYPE.MESSAGE_RECEIVED: {
            return {
                notifications: [...[action.data], ...state.notifications]
            }
        }
        case ACTION_TYPE.LIST_MESSAGES: {
            return {
                notifications: action.data
            }
        }
    }
    return state;
}

const NotificationStateContext = createContext()
const NotificationDispatchContext = createContext()

const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, {notifications: []});
    const { user } = useAuth()
    
    const { isLoading, isFetching } = useQuery(['notifications'], () => GET_NOTIFICATIONS(), {
        onSuccess: (data) => {
            if (!isEmpty(data)) {
                return dispatch({ type: ACTION_TYPE.LIST_MESSAGES, data: data.map(r => ({...r.data, created_at: r.created_at, id: r.id }) ) })
            }
        },
    })

    openPusherSocket(user, dispatch);

    return (
        <NotificationStateContext.Provider value={{isLoading, ...state}}>
            <NotificationDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationDispatchContext.Provider>
        </NotificationStateContext.Provider>
    );
}

const openPusherSocket = (user, dispatch) => {
    const toast = useToast()
    if ((process.env.REACT_APP_PUSHER_KEY && process.env.REACT_APP_PUSHER_CLUSTER) && user.id && !window.listenNotifications) {
        console.log(process.env.REACT_APP_PUSHER_KEY, {cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            authEndpoint: process.env.REACT_APP_SOCKET_URL,
            encrypted: true,
            auth: { headers: { "Authorization": 'Bearer ' + window.localStorage.getItem('token') || '' }}});
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {cluster: process.env.REACT_APP_PUSHER_CLUSTER,
            authEndpoint: process.env.REACT_APP_SOCKET_URL,
            encrypted: true,
            auth: { headers: { "Authorization": 'Bearer ' + window.localStorage.getItem('token') || '' }}});

        pusher.subscribe(`private-App.Models.User.${user.id}`)
            .bind("Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", 
            (data) => {
                // toast({
                //     title: 'Arquivo recebido com sucesso.',
                //     status: 'success',
                //     duration: 5000,
                //     isClosable: true,
                //   })
                dispatch({type: ACTION_TYPE.MESSAGE_RECEIVED, data: {...data, created_at: new Date}})
            })
            window.listenNotifications = true;
    }
}
const useDispatch = () => {
    const dispatch = useContext(NotificationDispatchContext)
    if (!dispatch) {
        throw new Error("necessario o provider de Chat")
    }
    return dispatch
}
const useNotifications = () => {
    const context = useContext(NotificationStateContext)
    if (!context) {
        throw new Error("necessario o provider de NotificationProvider")
    }
    return context
}
export {
    NotificationProvider,
    useNotifications
}
