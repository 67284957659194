import React from 'react'
import { ResponsivePie } from '@nivo/pie'

import { useColorMode } from "@chakra-ui/react";
import { themeDark, border, chartColors, tooltip } from 'variables/nivo/default'
import { arc, arcLinkLabelsColor, arcLabelsTextColor, margin, legendsDark, legendsLight, pieProps } from 'variables/nivo/pie'

const data = [
	{
		id: "nenhuma",
    label: "Nenhuma Porção",
    value: Math.floor(Math.random() * (100 - 0) + 0),
	},
	{
		id: "uma",
    label: "Uma Porção",
    value: Math.floor(Math.random() * (100 - 0) + 0),
	},
	{
		id: "duas",
    label: "Duas Porções",
    value: Math.floor(Math.random() * (100 - 0) + 0),
	},
	{
		id: "tres_quatro",
    label: "Três ou Quatro Porções",
    value: Math.floor(Math.random() * (100 - 0) + 0),
	},
	{
		id: "cinco",
    label: "Cinco ou mais Porções",
    value: Math.floor(Math.random() * (100 - 0) + 0),
	},
]

const total = () => {
	let total = 0;
	data.map(item => {
		total += item.value;
	})

	return total;
}

const FruitsVegetablesChart = () => {
 const { colorMode } = useColorMode();

  return (
    <ResponsivePie
			data={data}
			margin={margin}
			colors={chartColors}
			theme={themeDark}
			{...border}
			{...arc}
			arcLinkLabelsColor={arcLinkLabelsColor}
			arcLabelsTextColor={arcLabelsTextColor}
			legends={colorMode === 'light' ? legendsLight : legendsDark}
			tooltip={({datum}) => tooltip(datum.label, datum.value, total(), datum.color)}
			{...pieProps}
      innerRadius={0.7}
    />
  )
}

export default FruitsVegetablesChart