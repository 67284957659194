import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

import { useLabDash } from 'hooks/laboratoriesDash';
import { useColorMode } from "@chakra-ui/react";

import Loading from 'components/Loading/Loading';

import { themeDark, themeLight, axisTop, axisLeft, labelTextColor, tooltip } from 'variables/nivo/default'
import { defaultColors } from 'variables/colors'
import { padding } from 'variables/nivo/bar'

const colorBy = (data) => {
	switch(data.indexValue) {
		case "Insuficientes": return "#216D40";
		case "Não identificadas": return "#2B4F6B";
		case "Acondicionada de forma inadequada": return "#988B2E";
		default: return "#216D40";
	}
}

const RejectedSamplesChart = () => {
  const { isLoadingRejectedSamples: isLoading, dash_val: data } = useLabDash();

	const { colorMode } = useColorMode();

  if(isLoading || !data.rejected_samples)
    return <Loading />

  return (
		<ResponsiveBar
				data={data.rejected_samples}
				keys={["quant"]}
				indexBy="name"
				layout="horizontal"
				margin={{top: 20, right: 20, bottom: 10, left: 220}}
				padding={padding}
				colors={ defaultColors }
				colorBy={colorBy}
				labelSkipWidth={ 11 }
				theme={colorMode === 'light' ? themeLight : themeDark}
				axisBottom={null}
				axisTop={axisTop}
				axisLeft={axisLeft}
				labelTextColor={labelTextColor}
				tooltip={({indexValue, value}) => tooltip(indexValue, value)}
		/>
  )
}

export default RejectedSamplesChart