import { API } from './api'

const ORDERS_ROUTE = "/orders";

export async function GET_ORDERS() {
	const { data } = await API.get(`${ORDERS_ROUTE}`);

	return data;
}

export async function GET_ORDER(id) {
	const { data } = await API.get(`${ORDERS_ROUTE}/${id}`);

	return data;
}

export async function POST_ORDER(payload) {
	const { data } = await API.post(`${ORDERS_ROUTE}`, payload);

	return data;
}

export async function UPDATE_ORDER(id, payload) {
	const { data } = await API.put(`${ORDERS_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_ORDER(id) {
	const { data } = await API.delete(`${ORDERS_ROUTE}/${id}`);

	return data;
}