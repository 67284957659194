import React, {useEffect, useState} from "react";

import { Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import { useDemographicDash } from "hooks/demographicDash";
import { useUpdateFilters } from "hooks/dashFilter";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";

import KitTotalChart from "components/Charts/Nivo/Demographic/KitTotalChart";
import GenderChart from "components/Charts/Nivo/Demographic/GenderChart";
import EthinicityChart from "components/Charts/Nivo/Demographic/EthinicityChart";
import CustomersAgeChart from "components/Charts/Nivo/Demographic/CustomersAgeChart";
import BodyMassChart from "components/Charts/Nivo/Demographic/BodyMassChart";
import LifeHabitsChart from "components/Charts/Nivo/Demographic/LifeHabitsChart";
import SmokeChart from "components/Charts/Nivo/Demographic/SmokeChart";
import MedicineChart from "components/Charts/Nivo/Demographic/MedicineChart";
import HospitalizationChart from "components/Charts/Nivo/Demographic/HospitalizationChart";

import { queryBuilder } from "functions/queryBuilder";
import Loading from "components/Loading/Loading";

function DemographicDashboard() {
    const [filters, setFilters] = useState("");
    const {isLoadingCustomersAverageAge: isLoading, dash_val: data} = useDemographicDash();

    const textColor = useColorModeValue("gray.700", "white");
    const {mutateAsync: updateFilters} = useUpdateFilters();

    useEffect(() => {
        const query = queryBuilder(filters);
        updateFilters(query)
    }, [filters]);

    return (
        <>
            <Grid
                templateColumns={{sm: "1fr", md: "1fr"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt={{sm: "125px", lg: "160px"}}
            >
                <Card px="0px" pb="0px" minHeight="100px">
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Filtros
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <FiltersBox
                                setFilters={setFilters}
                                regionsFilter
                                statesFilter
                                citiesFilter
                                kitsFilter
                                unitsFilter
                                dateFilter
                            />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <Grid
                templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt="12px"
            >
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Total
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <KitTotalChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Sexo Biológico
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <GenderChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Etnia
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <EthinicityChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Idade Média
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <Flex w="100%" h="100%" justifyContent="center" alignItems="center" fontSize={50} pb="22px">
                                { isLoading ? <Loading /> : data.average_age_by_partner || 0 }
                            </Flex>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <Grid
                templateColumns={{sm: "1fr", md: "1fr"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt="12px"
            >
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Faixa Etária
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <CustomersAgeChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="320px" height="400px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            IMC (Índice de massa corporal)
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <BodyMassChart/>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <Grid
                templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
                templateRows={{sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt="12px"
            >
                <Card px="0px" pb="0px" minHeight="400px" maxHeight="400px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Hábitos de Vida
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <LifeHabitsChart/>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <Grid
                templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                templateRows={{sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt="12px"
            >
                <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Fumantes
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <SmokeChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="275px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Uso de Medicamentos Controlados
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <MedicineChart/>
                        </Box>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Internação
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%" minW={{lg: "auto", sm: 700}}>
                            <HospitalizationChart />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
        </>
    );
}

export default DemographicDashboard;
