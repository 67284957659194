import {useQuery} from "react-query";
import {createContext, useContext, useReducer} from "react";
import {
    CUSTOMERS_AGE_GROUP,
    CUSTOMERS_AVERAGE_AGE,
    CUSTOMERS_BODY_MASS,
    CUSTOMERS_ETHINICITY_AMOUNT,
    CUSTOMERS_GENDER_AMOUNT,
    CUSTOMERS_LIFE_HABITS,
    GET_ACTIVITY,
    GET_CONTROLLED_MEDICATION,
    GET_DRINK,
    GET_EAT_GREENS,
    GET_EAT_MEAT,
    GET_HOSPITALIZATION,
    GET_SMOKER,
    KIT_TOTAL
} from "services/dashboards/demographic";

import {useFilter} from "./dashFilter"

const ACTION_TYPES = {
    SET_VALUES_DEFAULT: "SET_VALUES_DEFAULT",
    UPDATE_FILTERS: "UPDATE_FILTERS"
};

const reducers = (state, action) => {
    switch (action.type) {
        case  ACTION_TYPES.UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.data
            }
        }
        case ACTION_TYPES.SET_VALUES_DEFAULT: {
            const newValue = {
                ...state.dash_val,
                ...action.data
            };
            return {
                ...state,
                dash_val: {
                    ...state.dash_val,
                    ...action.data
                }
            }
        }
        default: {
            return state
        }
    }
};

const DemographicDashStateContext = createContext();
const DemographicDashDispatchContext = createContext();

const DemographicDashProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducers, {filters: "", dash_val: {}});
    const {filters} = useFilter();

    const [
        {isFetching: isLoadingKitTotal},
        {isFetching: isLoadingCustomersGenderAmount},
        {isFetching: isLoadingCustomersEthinicityAmount},
        {isFetching: isLoadingCustomersAverageAge},
        {isFetching: isLoadingCustomersAgeGroup},
        {isFetching: isLoadingCustomersBodyMass},
        {isFetching: isLoadingCustomersLifeHabits},
        {isFetching: isLoadingGetSmoker},
        {isFetching: isLoadingGetControlledMedication},
        {isFetching: isLoadingGetHospitalization},
        {isFetching: isLoadingGetEatGreens},
        {isFetching: isLoadingGetEatMeat},
        {isFetching: isLoadingGetDrink},
        {isFetching: isLoadingGetActivity},
    ] = queryMultiple({
        onSuccess: (data) => {
            dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data});
        }
    }, filters);

    return (
        <DemographicDashStateContext.Provider value={{
            isLoadingKitTotal,
            isLoadingCustomersGenderAmount,
            isLoadingCustomersEthinicityAmount,
            isLoadingCustomersAverageAge,
            isLoadingCustomersAgeGroup,
            isLoadingCustomersBodyMass,
            isLoadingCustomersLifeHabits,
            isLoadingGetSmoker,
            isLoadingGetControlledMedication,
            isLoadingGetHospitalization,
            isLoadingGetEatGreens,
            isLoadingGetEatMeat,
            isLoadingGetDrink,
            isLoadingGetActivity,
            ...state
        }}>
            <DemographicDashDispatchContext.Provider
                value={dispatch}>{children}</DemographicDashDispatchContext.Provider>
        </DemographicDashStateContext.Provider>
    )
};

const queryMultiple = (setValueChart, filters = "", debug = false) => {
    const kitTotal = useQuery(["kit_total", filters], () => KIT_TOTAL(filters), setValueChart);
    const customersGenderAmount = useQuery(["customers_gender_amount", filters], () => CUSTOMERS_GENDER_AMOUNT(filters), setValueChart);
    const customersEthinicityAmount = useQuery(["customers_ethinicity_amount", filters], () => CUSTOMERS_ETHINICITY_AMOUNT(filters), setValueChart);
    const customersAverageAge = useQuery(["customers_average_age", filters], () => CUSTOMERS_AVERAGE_AGE(filters), setValueChart);
    const customersAgeGroup = useQuery(["customers_age_group", filters], () => CUSTOMERS_AGE_GROUP(filters), setValueChart);
    const customersBodyMass = useQuery(["customers_body_mass", filters], () => CUSTOMERS_BODY_MASS(filters), setValueChart);
    const customersLifeHabits = useQuery(["customers_life_habits", filters], () => CUSTOMERS_LIFE_HABITS(filters), setValueChart);
    const getSmoker = useQuery(["get_smoker", filters], () => GET_SMOKER(filters), setValueChart);
    const getControlledMedication = useQuery(["get_controlled_medication", filters], () => GET_CONTROLLED_MEDICATION(filters), setValueChart);
    const getHospitalization = useQuery(["get_hospitalization", filters], () => GET_HOSPITALIZATION(filters), setValueChart);
    const getEatGreens = useQuery(["get_eat_greens", filters], () => GET_EAT_GREENS(filters), setValueChart);
    const getEatMeat = useQuery(["get_eat_meat", filters], () => GET_EAT_MEAT(filters), setValueChart);
    const getDrink = useQuery(["get_drink", filters], () => GET_DRINK(filters), setValueChart);
    const getActivity = useQuery(["get_activity", filters], () => GET_ACTIVITY(filters), setValueChart);

    return [
        kitTotal,
        customersGenderAmount,
        customersEthinicityAmount,
        customersAverageAge,
        customersAgeGroup,
        customersBodyMass,
        customersLifeHabits,
        getSmoker,
        getControlledMedication,
        getHospitalization,
        getEatGreens,
        getEatMeat,
        getDrink,
        getActivity,
    ];
};

const useDemographicDash = () => {
    const context = useContext(DemographicDashStateContext);

    if (!context)
        throw new Error("useDemographicDash must be used within an DemographicDashProvider");

    return context
};

export {
    DemographicDashProvider,
    useDemographicDash,
}
