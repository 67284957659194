import { CompaniesDashProvider } from "../hooks/companiesDash";
import { FilterProvider } from "../hooks/dashFilter";
import { UnitsProvider } from 'hooks/units'

const CompaniesFilteredProvider = ({children}) => {
	return (
    <UnitsProvider>
			<FilterProvider>
				<CompaniesDashProvider>
					{ children }
				</CompaniesDashProvider>
			</FilterProvider>
		</UnitsProvider>
	)
}

export default CompaniesFilteredProvider;
