import { API } from './api'


export async function NEW_GENERAL_REPORT(filters = {}) {
	const { data } = await API.post(`report`, { tag: "relatorio_riscos",
        filters: filters 
    });

	return data;
}
