import { UnitsProvider } from "hooks/units";
import { FilterProvider } from "hooks/dashFilter";
import { LaboratoriesDashProvider } from "hooks/laboratoriesDash";

const LaboratoriesFilteredProvider = ({children}) => {
    return (
        <UnitsProvider>
            <FilterProvider>
                <LaboratoriesDashProvider>
                    { children }
                </LaboratoriesDashProvider>
            </FilterProvider>
        </UnitsProvider>
    )
}

export default LaboratoriesFilteredProvider;
