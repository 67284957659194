import { format } from 'date-fns'

const dateFormat = (value) => {
  if(!value) return value;
  
  try {
    let formatDate = format(new Date(value), 'dd/MM/yyyy')
    return formatDate;
  } catch(e) {
    return value
  }
}

export default dateFormat