import React, { useEffect, useState } from "react";

import { Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

import { useLabDash } from "hooks/laboratoriesDash";
import { useUpdateFilters } from "hooks/dashFilter";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";

import RejectedSamplesChart from "components/Charts/Nivo/Laboratories/RejectedSamplesChart";
import RejectedSamplesEvolutionChart from "components/Charts/Nivo/Laboratories/RejectedSamplesEvolutionChart";

import Loading from "components/Loading/Loading";

import { queryBuilder } from "functions/queryBuilder";

function LaboratoriesDashboard() {
  const [filters, setFilters] = useState('');
  const { isLoadingSamplesReceived, isLoadingSamplePendingAnalysis, isLoadingSamplesWithConclusiveResults, dash_val: data } = useLabDash()
  
  const textColor = useColorModeValue("gray.700", "white");
  const {mutateAsync: updateFilters } = useUpdateFilters()
  useEffect(() => {
    const query = queryBuilder(filters);
    updateFilters(query)
  }, [filters])
  return (
    <>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr" }}
          templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
          gap="12px"
          pt={{ sm: "125px", lg: "160px" }}
        >
            <Card px="0px" pb="0px" minHeight="100px" >
              <CardHeader mb="34px" px="22px">
                <Text color={textColor} fontSize="lg" fontWeight="bold">
                  Filtros
                </Text>
              </CardHeader>
              <CardBody h="100%">
                <Box w="100%" h="100%">
                  <FiltersBox 
                    setFilters={setFilters}
                    regionsFilter
                    statesFilter
                    citiesFilter
                    kitsFilter
                    unitsFilter
                    dateFilter
                  />
                </Box>
              </CardBody>
            </Card>
        </Grid>
        <Grid
          templateColumns={{ sm: "1fr", md: "repeat(3, 1fr)" }}
          templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
          gap="12px"
          pt="12px"
        >
          <Card px="0px" pb="0px" minHeight="180px" maxHeight="200px" overflowX={{sm: "auto", lg: "unset"}}>
            <CardHeader mb="34px" px="22px">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Amostras recebidas
              </Text>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <Flex w="100%" h="100%" justifyContent="center" alignItems="center" fontSize={50} pb="22px">
                  { isLoadingSamplesReceived || !data.samples_received ? <Loading /> : data.samples_received?.total || 0 }
                </Flex>    
              </Box>
            </CardBody>
          </Card>
          <Card px="0px" pb="0px" minHeight="180px" maxHeight="200px" overflowX={{sm: "auto", lg: "unset"}}>
            <CardHeader mb="34px" px="22px">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Amostras pendentes de análise
              </Text>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <Flex w="100%" h="100%" justifyContent="center" alignItems="center" fontSize={50} pb="22px">
                { isLoadingSamplePendingAnalysis || !data.sample_pending_analysis ? <Loading /> : data.sample_pending_analysis?.total || 0 }
                </Flex>    
              </Box>
            </CardBody>
          </Card>
          <Card px="0px" pb="0px" minHeight="180px" maxHeight="200px" overflowX={{sm: "auto", lg: "unset"}}>
            <CardHeader mb="34px" px="22px">
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                Amostras com resultados conclusivos
              </Text>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <Flex w="100%" h="100%" justifyContent="center" alignItems="center" fontSize={50} pb="22px">
                { isLoadingSamplesWithConclusiveResults || !data.samples_with_conclusive_results ? <Loading /> : data.samples_with_conclusive_results?.total || 0 }
                </Flex>    
              </Box>
            </CardBody>
          </Card>
        </Grid>
        <Grid
          templateColumns={{ sm: "1fr", md: "1fr" }}
          templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
          gap="12px"
          pt="12px"
        >
        <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Amostras Rejeitadas
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <RejectedSamplesChart minW={{lg: 'auto', sm: 700}} />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="320px" height="500px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Evolução de Amostras Rejeitadas
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <RejectedSamplesEvolutionChart minW={{lg: 'auto', sm: 700}} />
            </Box>
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}

export default LaboratoriesDashboard;
