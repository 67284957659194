const priorities = [
  {
    name: "Baixa",
    value: "baixa"
  },
  {
    name: "Normal",
    value: "normal"
  },
  {
    name: "Alta",
    value: "alta"
  },
  {
    name: "Urgente",
    value: "urgente"
  },
];

export default priorities;