import {UnitsProvider} from "hooks/units";
import {FilterProvider} from "hooks/dashFilter";
import {ExamDashProvider} from "hooks/examDash";

const LaboratoriesFilteredProvider = ({children}) => {
	return (
		<UnitsProvider>
			<FilterProvider>
				<ExamDashProvider>
					{children}
				</ExamDashProvider>
			</FilterProvider>
		</UnitsProvider>
	)
}

export default LaboratoriesFilteredProvider;
