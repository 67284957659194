import React, {useEffect} from "react";

import {Box, Grid, Text, useColorModeValue} from "@chakra-ui/react";

import {useExamDash} from "hooks/examDash";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CheckUpKitsTotal from "components/Charts/Nivo/Exam/CheckUpKitsTotal";
import FiltersBox from "components/Filters/FiltersBox";
import {useUpdateFilters} from "../../hooks/dashFilter";
import {queryBuilder} from "../../functions/queryBuilder";
import CheckUpKitsHDL from "../../components/Charts/Nivo/Exam/CheckUpKitsHDL";
import CheckUpKitsLDL from "../../components/Charts/Nivo/Exam/CheckUpKitsLDL";
import CheckUpKitsHba1c from "../../components/Charts/Nivo/Exam/CheckUpKitsHba1c";
import CheckUpKitsGlicose from "../../components/Charts/Nivo/Exam/CheckUpKitsGlicose";
import CheckUpKitsTriglicerideos from "../../components/Charts/Nivo/Exam/CheckUpKitsTriglicerideos";
import CheckUpKitsColesterolTotal from "../../components/Charts/Nivo/Exam/CheckUpKitsColesterolTotal";
import CheckUpKitsTFG from "../../components/Charts/Nivo/Exam/CheckUpKitsTFG";

function ExamDashboard() {
	const [filters, setFilters] = React.useState([]);
	const {isLoadingFilterData: isLoading, dash_val: data} = useExamDash();

	const textColor = useColorModeValue("gray.700", "white");
	const {mutateAsync: updateFilters} = useUpdateFilters();

	useEffect(() => {
		const query = queryBuilder(filters);
		updateFilters(query)
	}, [filters]);

	return (
		<>
			<Grid
				templateColumns={{sm: "1fr", md: "1fr"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt={{sm: "125px", lg: "160px"}}
			>
				<Card px="0px" pb="0px" minHeight="100px">
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Filtros
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<FiltersBox
								setFilters={setFilters}
								regionsFilter
								statesFilter
								citiesFilter
								unitsFilter
								kitsFilter
							/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Contador
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsTotal/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Colesterol HDL
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsHDL/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Colesterol LDL
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsLDL/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Hemoglobina Glicada (HbA1c)
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsHba1c/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Glicose
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsGlicose/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Triglicerídeos
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsTriglicerideos/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Colesterol Total
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsColesterolTotal/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
			<Grid
				templateColumns={{sm: "1fr", md: "repeat(1, 1fr)"}}
				templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
				gap="12px"
				pt="12px"
			>
				<Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
					<CardHeader mb="34px" px="22px">
						<Text color={textColor} fontSize="lg" fontWeight="bold">
							Creatinina
						</Text>
					</CardHeader>
					<CardBody h="100%">
						<Box w="100%" h="100%">
							<CheckUpKitsTFG/>
						</Box>
					</CardBody>
				</Card>
			</Grid>
		</>
	);
}

export default ExamDashboard;
