import {API} from "services/api";

const DASHBOARDS_ROUTE = "/dashboard/exam";

export async function GET_CHECK_UP_KITS_TOTAL(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-total${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_HDL(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-hdl${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_LDL(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-ldl${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_HBA1C(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-hba1c${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_GLICOSE(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-glicose${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_TRIGLICERIDEOS(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-triglicerideos${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_COLESTEROL_TOTAL(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-colesterol-total${filters}`);
	return data;
}

export async function GET_CHECK_UP_KITS_TFG(filters = "") {
	const {data} = await API.get(`${DASHBOARDS_ROUTE}/get-check-up-kits-tfg${filters}`);
	return data;
}