import { Box, Flex, Grid, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import {useColorMode} from "@chakra-ui/react";

const CustomerKitRisks = ({ risks }) => {
  const {colorMode} = useColorMode();
  const textColor = (colorMode == 'light') ? 'black' : 'white';

  console.log(risks);

  return (
    <Flex w="100%" direction="column" mb="20px">
        <Text
        color={textColor}
        fontSize="lg"
        fontWeight="bold"
        mb="10px"
        >
          Riscos Aferidos
        </Text>
        <VStack align="stretch" spacing="2.5">
          {
            risks.map(risk => {
              if (
                risk.risk.name == "Risco de desenvolver Osteoporose" ||
                risk.risk.name == "Risco de Lesão Muscular"
              )
                return;
              let labelColor = "#757575";
              switch (risk.ticket.label) {
                case "Baixo":
                case "Não detectado":
                  labelColor = "#3E993C";
                  break;
                case "Médio baixo":
                case "Médio":
                  labelColor = "#E0A51B";
                  break;
                case "Médio alto":
                  labelColor = "#D97325";
                  break;
                case "Alto":
                case "Detectado":
                  labelColor = "#CB4C33";
                  break;
                default:
                  break;
              }
              return (
                <VStack align="stretch" spacing="0.1">
                  <Flex>
                    <Text fontSize="20px" mr="2">{risk.risk.name} - </Text>
                    <Text fontSize="20px" color={labelColor}> {risk.ticket.label}</Text>
                  </Flex>
                </VStack>
              )
            })
          }
        </VStack>
    </Flex>
  );
};

export default CustomerKitRisks;
