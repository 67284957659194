import { ReversesProvider } from 'hooks/reverses'
import UnitProvider from "./unitProvider";

const ReverseProvider = ({ children }) => {
	return (
		<UnitProvider>
			<ReversesProvider>{children}</ReversesProvider>
		</UnitProvider>
	)
}

export default ReverseProvider
