import React from 'react'
import { ResponsiveLine } from '@nivo/line'

import { useLabDash } from 'hooks/laboratoriesDash';
import { useColorMode } from "@chakra-ui/react";

import Loading from 'components/Loading/Loading';

import { margin, padding, legendsLight, legendsDark, lineProps } from 'variables/nivo/line'
import { themeDark, themeLight, axisBottom, axisLeft, labelTextColor, tooltip } from 'variables/nivo/default'
import { defaultColors } from 'variables/colors';

const RejectedSamplesEvolutionChart = () => {
  const { isLoadingEvolutionRejectedSamples: isLoading, dash_val: data } = useLabDash();

	const { colorMode } = useColorMode();

  if(isLoading || !data?.evolution_rejected_samples)
    return <Loading />

  return (
    <ResponsiveLine
      data={data?.evolution_rejected_samples}
      margin={margin}
      padding={padding}
      theme={colorMode === 'light' ? themeLight : themeDark}
      axisBottom={axisBottom}
      axisLeft={axisLeft}
      colors={ defaultColors }
      labelTextColor={labelTextColor}
      tooltip={({point}) => tooltip(`${point.serieId} - ${point.data.x}`, point.data.yStacked, null, point.color)}
      legends={colorMode === 'light' ? legendsLight : legendsDark}
      {...lineProps}
    />
  )
}

export default RejectedSamplesEvolutionChart