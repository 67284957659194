import {useQuery} from "react-query";
import {useFilter} from "hooks/dashFilter";
import {createContext, useContext, useReducer} from "react";
import {KIT_EVALUATIONS, KIT_STATUS, KIT_STOCK,} from "services/dashboards/companies";

const ACTION_TYPES = {
    SET_VALUES_DEFAULT: "SET_VALUES_DEFAULT",
    UPDATE_FILTERS: "UPDATE_FILTERS"
};

const reducers = (state, action) => {
    switch (action.type) {
        case  ACTION_TYPES.UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.data
            }
        }
        case ACTION_TYPES.SET_VALUES_DEFAULT: {
            const newValue = {
                ...state.dash_val,
                ...action.data
            };
            return {
                ...state,
                dash_val: {
                    ...state.dash_val,
                    ...action.data
                }
            }
        }
        default: {
            return state
        }
    }
};

const CompaniesDashStateContext = createContext();
const CompaniesDashDispatchContext = createContext();

const CompaniesDashProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducers, {filters: "", dash_val: {}});
    const {filters} = useFilter();

    const [
        {isFetching: isLoadingKitStock},
        {isFetching: isLoadingKitEvaluations},
        {isFetching: isLoadingKitStatus},
    ] = queryMultiple({
        onSuccess: (data) => {
            dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data});
        }
    }, filters);

    return (
        <CompaniesDashStateContext.Provider value={{
            isLoadingKitStock,
            isLoadingKitEvaluations,
            isLoadingKitStatus,
            ...state
        }}>
            <CompaniesDashDispatchContext.Provider value={dispatch}>{children}</CompaniesDashDispatchContext.Provider>
        </CompaniesDashStateContext.Provider>
    )
};

const queryMultiple = (setValueChart, filters = "", debug = false) => {
    const kitStock = useQuery(["kit_stock", filters], () => KIT_STOCK(filters), setValueChart);
    const kitEvaluations = useQuery(["kit_evaluations", filters], () => KIT_EVALUATIONS(filters), setValueChart);
    const kitStatus = useQuery(["kit_status", filters], () => KIT_STATUS(filters), setValueChart);

    return [kitStock, kitEvaluations, kitStatus];
};

const useCompanyDash = () => {
    const context = useContext(CompaniesDashStateContext);

    if (!context)
        throw new Error("useCompanyDash must be used within an CompaniesDashProvider");

    return context
};

export {
    CompaniesDashProvider,
    useCompanyDash,
}
