import React from 'react'
import { createContext, useContext, useReducer, useCallback } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { GET_KIT_STOCK, GET_KIT_EVALUATIONS, GET_KIT_STATUS } from 'services/dashboards'

const ACTION_TYPES = {
  VIEW: 'VIEW',
}

const dashboardReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.VIEW: {
      return {
        ...state,
        data: action.data,
      }
    }
    default: {
      return state
    }
  }
}

const DashboardsStateContext = createContext()
const DashboardDispatchContext = createContext()

const DashboardsProvider = ({ children }) => {
  const [filters, setFilters] = React.useState('');
  const [state, dispatch] = useReducer(dashboardReducer, {
    data: {},
  })

  React.useEffect(() => {
  }, [filters])

  return (
    <DashboardsStateContext.Provider value={{ filters, setFilters, data: state.data }}>
      <DashboardDispatchContext.Provider value={dispatch}>{children}</DashboardDispatchContext.Provider>
    </DashboardsStateContext.Provider>
  )
}

const useDashboards = () => {
  const context = useContext(DashboardsStateContext)

  if (!context) {
    throw new Error('useDashboards must be used within an DashboardsProvider')
  }

  return context
}

const useDispatch = () => {
  const dispatch = useContext(DashboardDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a DashboardsProvider')
  }
  return dispatch
}

const useDashboard = (id, options = {}) => {
	const dispatch = useDispatch()

  // AQUI VAI O FETCH return useQuery(['data'], () => GET_DATA(id), {...
	return useQuery(['data'], () => (id), {
			...options,
      enabled: true,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useKitStock = (filters, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['kit_stock'], () => GET_KIT_STOCK(filters), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useKitEvaluations = (options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['kit_evaluations'], () => GET_KIT_EVALUATIONS(), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useKitStatus = (options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['kit_status'], () => GET_KIT_STATUS(), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

export { 
  DashboardsProvider, 
  useDashboards, 
  useDashboard,
  useKitStock,
  useKitEvaluations,
  useKitStatus
}
