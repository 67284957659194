const margin = { 
  top: 40, 
  right: 30, 
  bottom: 50, 
  left: 60 
}

const padding = 0.3;

const legendsDark = [
	{
		anchor: 'top',
		direction: 'row',
		justify: false,
		translateX: 0,
		translateY: -30,
		itemsSpacing: 150,
		itemWidth: 100,
		itemHeight: 18,
		itemTextColor: '#FFF',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle',
	}
]

const legendsLight = [
	{
		anchor: 'top',
		direction: 'row',
		justify: false,
		translateX: 0,
		translateY: -30,
		itemsSpacing: 150,
		itemWidth: 100,
		itemHeight: 18,
		itemTextColor: '#000',
		itemDirection: 'left-to-right',
		itemOpacity: 1,
		symbolSize: 18,
		symbolShape: 'circle',
	}
]

const lineProps = {
  xScale:{ type: 'point' },
  yScale:{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: false,
      reverse: false
  },
  pointSize: 10,
  pointColor: { from: 'color', modifiers: [] },
  pointBorderWidth: 2,
  pointBorderColor: { from: 'serieColor' },
  pointLabelYOffset: -12,
  useMesh: true
}

export {
	margin,
	padding,
  legendsDark,
  legendsLight,
  lineProps
}