import { API } from './api'

const UNITS_ROUTE = "/unit";

export async function GET_UNITS() {
	const { data } = await API.get(`${UNITS_ROUTE}`);

	return data;
}

export async function GET_UNITS_WITH_KITS() {
	const { data } = await API.get(`/units-kits`);

	return data;
}

export async function GET_UNIT(id) {
	const { data } = await API.get(`${UNITS_ROUTE}/${id}`);

	return data;
}

export async function POST_UNIT(payload) {
	const { data } = await API.post(`${UNITS_ROUTE}`, payload);

	return data;
}

export async function UPDATE_UNIT(id, payload) {
	const { data } = await API.put(`${UNITS_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_UNIT(id) {
	const { data } = await API.delete(`${UNITS_ROUTE}/${id}`);

	return data;
}
