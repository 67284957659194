import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
    GET_TICKETS,
    GET_TICKET,
    POST_TICKET,
    UPDATE_TICKET,
    DELETE_TICKET
} from 'services/tickets'

const ACTION_TYPES = {
    LIST: 'LIST_TICKETS',
    VIEW: 'VIEW_TICKET',
    ADD: 'ADD_TICKET',
    UPDATE: 'UPDATE_TICKET',
    REMOVE: 'REMOVE_TICKET',
}

const ticketsReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				tickets: action.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				tickets: [ ...state.tickets, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				ticket: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				tickets: state.tickets.length > 0 ? state.tickets.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				tickets: state.tickets.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const TicketsStateContext = createContext()
const TicketsDispatchContext = createContext()

const TicketsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ticketsReducer, { tickets: [] })

		const [toDelete, setToDelete] = React.useState(null)
		const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  
    const { isLoading, isFetching } = useQuery(['tickets'], () => GET_TICKETS(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
  
    return (
			<TicketsStateContext.Provider value={{ isLoading, isFetching, ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen }}>
					<TicketsDispatchContext.Provider value={dispatch}>{children}</TicketsDispatchContext.Provider>
			</TicketsStateContext.Provider>
    )
}

const useTickets = () => {
	const context = useContext(TicketsStateContext)

	if (!context)
		throw new Error('useTickets must be used within an TicketsProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(TicketsDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a TicketsProvider')

	return dispatch
}

const useCreateTicket = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_TICKET(data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useTicket = (id, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['ticket'], () => GET_TICKET(id), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useUpdateTicket = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => UPDATE_TICKET(id, data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteTicket = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_TICKET(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	TicketsProvider,
	useTickets,
	useCreateTicket,
	useTicket,
	useUpdateTicket,
	useDeleteTicket
}
  