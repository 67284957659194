/*!

=========================================================
* Purity UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard-pro
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Link,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { HSeparator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useState } from "react";
import GitHubButton from "react-github-btn";
import { FaFacebook, FaTwitter } from "react-icons/fa";

export default function Configurator(props) {
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);

  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}>
        <DrawerContent >
          <DrawerHeader pt='24px' px='30px'>
            <DrawerCloseButton />
            <Text fontSize='xl' fontWeight='bold' mt='16px'>
              Preferências
            </Text>
            <HSeparator />
          </DrawerHeader>
          <DrawerBody w='340px' ps='24px' pe='40px'>
            <Flex flexDirection='column'>
              <Box>
                <Text fontSize='md' fontWeight='600' mb="6px">
                  Tipo de menu
                </Text>
                <Flex>
                  <Button
                    w='50%'
                    p='8px 32px'
                    me='8px'
                    colorScheme='healthlab'
                    borderColor='healthlab.300'
                    color='healthlab.300'
                    variant='outline'
                    fontSize='xs'
                    onClick={props.onTransparent}>
                    Transparente
                  </Button>
                  <Button
                    type='submit'
                    bg='healthlab.900'
                    w='50%'
                    p='8px 32px'
                    mb={5}
                    _hover='healthlab.900'
                    color='white'
                    fontSize='xs'
                    onClick={props.onOpaque}>
                    Opaco
                  </Button>
                </Flex>
              </Box>
              {/* <Box
                display={fixedDisplay}
                justifyContent='space-between '
                mb='16px'>
                <Text fontSize='md' fontWeight='600' mb='4px'>
                  Navbar Fixed
                </Text>
                <Switch
                  colorScheme='healthlab'
                  isChecked={switched}
                  onChange={(event) => {
                    if (switched === true) {
                      props.onSwitch(false);
                      setSwitched(false);
                    } else {
                      props.onSwitch(true);
                      setSwitched(true);
                    }
                  }}
                />
              </Box> */}
              <Flex
                justifyContent='space-between'
                alignItems='center'
                mb='24px'>
                <Text fontSize='md' fontWeight='600'>
                  Trocar tema
                </Text>
                <Button onClick={toggleColorMode}>
                  Tema {colorMode === "light" ? "escuro" : "claro"}
                </Button>
              </Flex>
              <HSeparator />
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.func,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
