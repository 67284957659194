import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import Loading from "components/Loading/Loading";
import {useDemographicDash} from "hooks/demographicDash";
import {border, themeDark, tooltip} from "variables/nivo/default";
import {
    arc,
    arcLabelsTextColor,
    arcLinkLabelsColor,
    legendsDark,
    legendsLight,
    margin,
    pieProps
} from "variables/nivo/halfPie";
import {risksSmokeSpecific} from "variables/colors";

const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const SmokeChart = () => {
    const { isLoadingGetSmoker: isLoading, dash_val: data } = useDemographicDash();

    const { colorMode } = useColorMode();

    if (!isLoading && !!data && !!data.get_smoker) {
        return (
            <ResponsivePie
                data={data.get_smoker}
                margin={margin}
                colors={risksSmokeSpecific}
                theme={themeDark}
                {...border}
                {...arc}
                arcLinkLabelsColor={arcLinkLabelsColor}
                arcLabelsTextColor={arcLabelsTextColor}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.get_smoker), datum.color)}
                {...pieProps}
                innerRadius={0.7}
            />
        )
    }
    return <Loading/>;
};

export default SmokeChart
