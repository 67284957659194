import { API } from './api'

const REVERSES_ROUTE = "/reverses";

export async function GET_REVERSES() {
	const { data } = await API.get(`${REVERSES_ROUTE}`);

	return data;
}

export async function GET_REVERSE(id) {
	const { data } = await API.get(`${REVERSES_ROUTE}/${id}`);

	return data;
}

export async function POST_REVERSE(payload) {
	const { data } = await API.post(`${REVERSES_ROUTE}`, payload);

	return data;
}

export async function UPDATE_REVERSE(id, payload) {
	const { data } = await API.put(`${REVERSES_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_REVERSE(id) {
	const { data } = await API.delete(`${REVERSES_ROUTE}/${id}`);

	return data;
}
