import {API} from "./api";

const CLIENTS_ROUTE = "/clients";

export async function GET_CLIENTS({pageIndex, pageSize}) {
    const {data} = await API.get(`${CLIENTS_ROUTE}`, {
        params: {
            pageIndex, pageSize
        }
    });

    return data;
}

export async function GET_CLIENT(id) {
    const {data} = await API.get(`${CLIENTS_ROUTE}/${id}`);

    return data;
}

export async function POST_CLIENT(payload) {
    const {data} = await API.post(`${CLIENTS_ROUTE}`, payload);

    return data;
}

export async function UPDATE_CLIENT(id, payload) {
    const {data} = await API.put(`${CLIENTS_ROUTE}/${id}`, payload);

    return data;
}

export async function UPDATE_CLIENT_CARE_PLAN(id, payload) {
    payload['customer_id'] = id
    const {data} = await API.post(`${CLIENTS_ROUTE}/care-plan`, payload);

    return data;
}

export async function GET_URL_DOC_CARE_PLAN(docId) {
    const {data} = await API.get(`${CLIENTS_ROUTE}/care-plan/doc/${docId}`);

    return data;
}
export async function DELETE_CLIENT(id) {
    const {data} = await API.delete(`${CLIENTS_ROUTE}/${id}`);

    return data;
}
