import axios from "axios";


const API_INSTANCE = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://localhost:8045/partner/api",
    // baseURL: "https://622a3aaebe12fc4538b5fcf8.mockapi.io/api",
});
API_INSTANCE.defaults.headers.common["Authorization"] = "Bearer " + window.localStorage.getItem("token") || " ";
API_INSTANCE.defaults.headers.common["Content-Type"] = "application/json";

API_INSTANCE.interceptors.request.use((config) => {
    config.headers["Authorization"] = "Bearer " + window.localStorage.getItem("token") || " ";
    config.headers["Content-Type"] = "application/json";
    return config;
})
export const API = API_INSTANCE;
