import { isEmpty } from 'lodash'
import { Route, Redirect, useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/auth'

import LoadingScreen from 'components/LoadingScreen/LoadingScreen'

const PrivateRoute = ({ component: Component, roles = [], ...props }) => {
  const { user, isLoading } = useAuth()
	const history = useHistory()

  const userRole = !isLoading ? user?.role || user?.data?.role || null : null;

  if (isLoading)
    return <LoadingScreen />

  if(roles && roles.length > 0 && !roles.includes(userRole)) 
    history.push(`/sistema/dashboard/default`);

    return (
      <Route
        {...props}
        render={({ location }) =>
          isEmpty(user) ? (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          ) : (
            <Component />
          )
        }
      />
    )

}

export default PrivateRoute
