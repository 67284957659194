import { createContext, useContext, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { LOGIN, LOGOUT, ME, FORGOT_PASSWORD, VERIFY_HASH, RECOVER_PASSWORD, UPDATE_PASSWORD, SYNC_SIGNATURE_USER } from 'services/auth'

const ACTION_TYPES = {
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT',
  SYNC_SIGNATURE_USER: "SYNC_SIGNATURE_USER"
}

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SIGN_IN: {
      return {
        ...state,
        user: action.data,
      }
    }
    case ACTION_TYPES.SIGN_OUT: {
      return {
        user: {},
      }
    }
    case ACTION_TYPES.SYNC_SIGNATURE_USER: {
      debugger;
      return {
        ...state,
        user: {
          ...state.user,
          signature_url: action.data.signature_url
        }
      }
    }
    default: {
      return state
    }
  }
}

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: {},
  })

  const { isLoading } = useQuery('me', ME, {
    retry: false,
    useErrorBoundary: false,
    onSuccess: (data) => {
      dispatch({ type: ACTION_TYPES.SIGN_IN, data })
    },
  })

  return (
    <AuthStateContext.Provider value={{ isLoading, user: state.user }}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

const useAuth = () => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
const useSyncSignature = (options = {}) => {
  const dispatch = useDispatch()
  const { onSuccess } = options

  return useMutation((data) => SYNC_SIGNATURE_USER(data), {
    ...options,
    onSuccess: (data) => {
      onSuccess(data)
      dispatch({ type: ACTION_TYPES.SYNC_SIGNATURE_USER, data })
    },
  })
}
const useDispatch = () => {
  const dispatch = useContext(AuthDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useDispatch must be used within a AuthProvider')
  }
  return dispatch
}

const useLogin = (options = {}) => {
  const dispatch = useDispatch()

  const { onSuccess } = options

  return useMutation(LOGIN, {
    ...options,
    onSuccess: async (data) => {
      await dispatch({ type: ACTION_TYPES.SIGN_IN, data })

      if (onSuccess) {
        window.localStorage.setItem('token', data.token)
        onSuccess(data)
      }
    },
  })
}

const useLogout = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useCallback(async () => {
    await dispatch({ type: ACTION_TYPES.SIGN_OUT })
    queryClient.resetQueries()
    window.location.pathname = '/login'
  }, [queryClient, dispatch])
}

const useLogout_ = () => {
  window.localStorage.removeItem('token');
  window.location.pathname = '/login'
}

const useForgotPassword = (options = {}) => {
  const { onSuccess } = options

  return useMutation((data) => FORGOT_PASSWORD(data), {
    ...options,
    onSuccess: async (data) => {

      if (onSuccess) {
        onSuccess(data)
      }
    },
  })
}

const useVerify = (data, options = {}) => {
  return useQuery(['verify_hash'], () => VERIFY_HASH(data), {
    ...options,
    onSuccess: ({data}) => {
      return data
    },
    onError: ({data}) => {
      return data
    },
})
}

const useRecoverPassword = (options = {}) => {
  const { onSuccess } = options

  return useMutation((data) => RECOVER_PASSWORD(data), {
    ...options,
    onSuccess: async (data) => {

      if (onSuccess) {
        onSuccess(data)
      }
    },
  })
}

const useUpdatePassword = (options = {}) => {
	const { onSuccess, onError } = options

	return useMutation((data) => UPDATE_PASSWORD(data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
			},
			onError: ({response: error}) => {
				onError(error.data)
			},
	})
}

export { 
  AuthProvider, 
  useAuth, 
  useLogin, 
  useLogout,
  useLogout_,
  useForgotPassword,
  useVerify,
  useRecoverPassword,
  useUpdatePassword,
  useSyncSignature
}
