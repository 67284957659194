import React, {useEffect, useState} from "react"
import BasicTable from "../BasicTable"
import {useClients} from "hooks/clients"
import {Flex, Text} from "@chakra-ui/react";
import AsyncTable from "../AsyncTable";
import { useChangePagination } from "hooks/clients";


const columns = [
    {
        Header: "Nome", 
        accessor: "name",
        isLink: true,
    }, 
    {
        Header: "Unidade", 
        accessor: "kits[0].order[0].partner_place.name"
    }, 
    {
        Header: "Cadastrado em",
        accessor: "created_at"
    }
];

const ClientsTable = () => {
    const [page, setPage] = useState({
        pageIndex: 1,
        pageSize: 15
    })

    const { isLoading, isFetching } = useChangePagination(page)
    const { clients } = useClients()

    if (isLoading || isFetching) 
        return (
            <> 
                {
                    <BasicTable 
                        isLoading={true} 
                        tableData={[{}, {}, {}, {}, {}, {}, {}, {}]}
                        columnsData={columns} 
                        route="clients"
                        noView
                        noEdit
                        noDelete
                        />
                } 
            </>
        )

    if (clients.data.length === 0) 
        return (
            <Flex justifyContent="center" alignItems="center" w="100%" h="100%" pl="22px" pr="22px"> 
                <Text> Não foram encontrados clientes cadastrados </Text> 
            </Flex>
        )

    return (
        // <BasicTable 
        //     tableData={clients.data}
        //     columnsData={columns} 
        //     route="cliente"
        // />
        <AsyncTable data={clients.data}
            pageCount={clients.last_page}
            total={clients.total}
            loading={!isLoading}
            columns={columns}
            size={clients.per_page}
            baseRoute={"cliente"}
            fetchData={setPage}
            noView
            noEdit
            noDelete
        />
    )
}

export default ClientsTable
