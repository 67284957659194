import React from 'react';

import { Flex, Box, useColorModeValue, Text, Button } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from "components/Card/CardBody";
import CustomerKits from 'components/Customer/CustomerKits/CustomerKits';
import CustomerAssestment from 'components/Customer/CustomerAssestment/CustomerAssestment';
import CustomerKitRisks from 'components/Customer/CustomerKitRisks/CustomerKitRisks';
import CustomerKitResult from 'components/Customer/CustomerKitResult/CustomerKitResult';

const CheckUps = ({kits, getDate}) => {
    const [openAssestment, setOpenAssestment] = React.useState(false);
    const [assestment, setAssestment] = React.useState({});

    const handleKitClick = (kit) => {
        setOpenAssestment(true);
        let kitAssestment = {
            answers: kit.field_answers,
            metric: kit.metric,
            result: kit?.exam?.result
        }
        setAssestment(kitAssestment);
    }

    const handleKitClickClose = () => {
        setOpenAssestment(false);
    }

    const textColor = useColorModeValue("gray.700", "white");

    if(!openAssestment) {
        return (
            <Card px="0px" pb="0px" minHeight="100px" overflowY={{sm: "auto", lg: "unset"}}>
                <CardHeader px="22px" mb="22px">
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                    >
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                        Check-ups
                    </Text>
                    </Flex>
                </CardHeader>
                <CardBody h="100%" width="100%">
                    <Box w="100%" px="32px" pb="22px">
                        <CustomerKits
                            kits={kits}
                            handleKitClick={handleKitClick}
                            getDate={getDate}
                        />
                    </Box>
                </CardBody>
            </Card>
        )
    } else {
        return (
            <Card px="0px" pb="0px" minHeight="100px" maxH="572px" overflowY={{sm: "auto", lg: "auto"}}>
                <CardHeader px="22px" mb="22px">
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                        >
                            
                        </Text>
                        <Button
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                        onClick={() => handleKitClickClose()}
                        >
                            Voltar
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody h="100%" width="100%">
                    <Box w="100%" px="32px" pb="22px">
                        <CustomerKitRisks risks={assestment.metric} />
                        <CustomerAssestment answers={assestment.answers} />
                        <CustomerKitResult results={assestment.result}/>
                    </Box>
                </CardBody>
            </Card>
        )
    }

    
}

export default CheckUps