const msLightTheme = {
  control: (provided, { data, isDisabled, isFocused, isSelected }) => (console.log(), {
    ...provided,
    backgroundColor: '#FFF',
    borderColor: '#E2E8F0',
    borderRadius: '8px',
    color: '#FFF',
    'hover': {
      borderColor: '#CBD5E0',
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#FFF',
    color: '#FFF',
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => (
    console.log('>', provided, data, isDisabled, isFocused, isSelected), {
    ...provided,
    backgroundColor: isFocused || isSelected ? '#216D40' : '#FFF',
    color: isFocused || isSelected ? '#FFF' : '#000',
    cursor: 'pointer',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#216D40',
    color: '#FFF',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#FFF',
  }),
}

const msDarkTheme = {
  control: (provided, { data, isDisabled, isFocused, isSelected }) => (console.log(), {
    ...provided,
    backgroundColor: '#1f2733',
    borderColor: '#ffffff29',
    borderRadius: '8px',
    color: '#FFF',
    ':active': {
      ...provided[':active'],
      borderColor: '#69C49D'
    },
    ':focus': {
      ...provided[':focus'],
      borderColor: '#69C49D',
      boxShadow: '0 0 0 1px #69c49d',
    },
    ':hover': {
      ...provided[':hover'],
      borderColor: '#ffffff3d'
    }
  }),
  container: (provided, state) => ({
    ...provided,
    ':active': {
      ...provided[':active'],
      borderColor: '##69C49D'
    },
    ':focus': {
      ...provided[':focus'],
      borderColor: '##69C49D'
    },
    ':hover': {
      ...provided[':hover'],
      borderColor: '#ffffff3d'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#1A202C',
    color: '#FFF',
  }),
  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isFocused || isSelected ? '#216D40' : '#1A202C',
    color: '#FFF',
    cursor: 'pointer',
  }),
  input: (provided) => ({
    ...provided,
    color: '#FFF',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#216D40',
    color: '#FFF',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#FFF',
  }),
}

export { msLightTheme, msDarkTheme };