import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {padding} from "variables/nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import Loading from "components/Loading/Loading";
import {singleColorChart} from "variables/colors";
import {useDemographicDash} from "hooks/demographicDash";
import {axisLeft, axisTop, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";


const CustomersAgeChart = () => {
    const { isLoadingCustomersAgeGroup: isLoading, dash_val: data } = useDemographicDash();
    
    const { colorMode } = useColorMode();

    if (!isLoading && !!data && !!data.age_group_by_partner) {
        return (
            <ResponsiveBar
                data={data.age_group_by_partner}
                keys={["quant"]}
                indexBy="name"
                layout="horizontal"
                margin={{top: 20, right: 20, bottom: 10, left: 50}}
                padding={padding}
                colors={singleColorChart}
                labelSkipWidth={11}
                theme={colorMode === "light" ? themeLight : themeDark}
                axisBottom={null}
                axisTop={axisTop}
                axisLeft={axisLeft}
                labelTextColor={labelTextColor}
                tooltip={({indexValue, value}) => tooltip(indexValue, value)}
            />
        )
    }

    return <Loading/>;
};

export default CustomersAgeChart
