import React from "react";
import {ResponsiveRadialBar} from "@nivo/radial-bar";
import {useColorMode} from "@chakra-ui/react";
import {themeDark, themeLight, tooltip} from "variables/nivo/default";
import {legendsDark, legendsLight, margin, radialProps} from "variables/nivo/radial";
import {risksColors, risksColorsPlus} from "variables/colors";
import Loading from "../../../Loading/Loading";
import {useEpidemicDash} from "../../../../hooks/epidemicDash";

const setColor = (data) => {
    let response = false;
    data.map(el => {
        if(el.id == "Risco Internação") response = true;
    })
    return response;
}

const RisksChart = () => {
    const { isLoadingGetAllRisks: isLoading, dash_val: data } = useEpidemicDash();
    
    const { colorMode } = useColorMode();

    if (!!data && !!data.get_all_risks && !isLoading) {
        return (
            <ResponsiveRadialBar
                data={data.get_all_risks}
                margin={margin}
                colors={setColor(data.get_all_risks) ? risksColorsPlus : risksColors}
                theme={colorMode === "light" ? themeLight : themeDark}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({bar}) => tooltip(`${bar.groupId} - ${bar.category}`, `${bar.value}`, null, bar.color)}
                {...radialProps}
            />
        )
    }

    return <Loading/>;
};

export default RisksChart
