import {ClientsProvider} from "hooks/clients"
import UnitProvider from "./unitProvider";

const ClientProvider = ({children}) => {
    return (
        <UnitProvider>
            <ClientsProvider>
                {children}
            </ClientsProvider>
        </UnitProvider>
    )
}

export default ClientProvider
