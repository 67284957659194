import { useMutation, useQuery } from 'react-query'
import { createContext, useContext, useReducer } from "react"
import { 
     SAMPLES_RECEIVED,
     SAMPLE_PENDING_ANALYSIS,
     SAMPLES_WITH_CONCLUSIVE_RESULTS,
     REJECTED_SAMPLES,
     EVOLUTION_REJECTED_SAMPLES
    } from "services/dashboards/laboratories"
import { useFilter } from './dashFilter'

    const ACTION_TYPES = {
        SET_VALUES_DEFAULT: 'SET_VALUES_DEFAULT',
        UPDATE_FILTERS: "UPDATE_FILTERS"
    }
    
const reducers = (state, action) => {
	switch (action.type) {
        case  ACTION_TYPES.UPDATE_FILTERS: {
            return {
                ...state,
                filters: action.data
            }
        }
        case ACTION_TYPES.SET_VALUES_DEFAULT: {
            const newValue = {
                ...state.dash_val,
                ...action.data
            };
            console.log(newValue);
            return {
                ...state,
                dash_val: {
                    ...state.dash_val,
                    ...action.data
                }
            }
        }
        default: {
            return state
        }
    }
}


const LaboratoriesDashStateContext = createContext()
const LaboratoriesDashDispatchContext = createContext()


const LaboratoriesDashProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, { filters: "", dash_val: {}})
    const { filters } = useFilter()
    const [
        { isFetching: isLoadingSamplesReceived },
        { isFetching: isLoadingSamplePendingAnalysis },
        { isFetching: isLoadingSamplesWithConclusiveResults },
        { isFetching: isLoadingRejectedSamples },
        { isFetching: isLoadingEvolutionRejectedSamples },
    ] = queryMultiple({
        onSuccess: (data) => {
            dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data});
        }
    }, filters)

    return (
        <LaboratoriesDashStateContext.Provider value={{ 
            isLoadingSamplesReceived, 
            isLoadingSamplePendingAnalysis,
            isLoadingSamplesWithConclusiveResults,
            isLoadingRejectedSamples, 
            isLoadingEvolutionRejectedSamples, 
            ...state}}>
            <LaboratoriesDashDispatchContext.Provider value={dispatch}>{ children }</LaboratoriesDashDispatchContext.Provider>
        </LaboratoriesDashStateContext.Provider>
    )
}

const queryMultiple = (setValueChart, filters = "", debug = false) => {
    const samples_received = useQuery(["samples_received", filters], () => SAMPLES_RECEIVED(filters), setValueChart);
    const sample_pending_analysis = useQuery(["sample_pending_analysis", filters], () => SAMPLE_PENDING_ANALYSIS(filters), setValueChart);
    const samples_with_conclusive_results = useQuery(["samples_with_conclusive_results", filters], () => SAMPLES_WITH_CONCLUSIVE_RESULTS(filters), setValueChart);
    const rejected_samples = useQuery(["rejected_samples", filters], () => REJECTED_SAMPLES(filters), setValueChart);
    const evolution_rejected_samples = useQuery(["evolution_rejected_samples", filters], () => EVOLUTION_REJECTED_SAMPLES(filters), setValueChart);

    return [samples_received, sample_pending_analysis, samples_with_conclusive_results, rejected_samples, evolution_rejected_samples];
}

const useLabDash = () => {
    const context = useContext(LaboratoriesDashStateContext)
    
	if (!context)
		throw new Error('useLabDash must be used within an LaboratoriesDashProvider')

	return context
}

export {
    LaboratoriesDashProvider,
    useLabDash,
}
