import { API } from './api'

const AUTH_LOGIN_ROUTE = "/login";
const AUTH_LOGOUT_ROUTE = "/logout";
const AUTH_ROUTE = "/me";
const USERS_MEDIC_ROUTE = "/medic";

export async function LOGIN(payload) {
	const { data } = await API.post(`${AUTH_LOGIN_ROUTE}`, payload);

	return data;
}
export async function SYNC_SIGNATURE_USER(payload) {
	const { data } = await API.post(`${USERS_MEDIC_ROUTE}/sync-signature`, payload);
	return data;
}
export async function LOGOUT() {
	const { data } = await API.post(`${AUTH_LOGOUT_ROUTE}`);

	return data;
}

export async function ME() {
	const { data } = await API.get(`${AUTH_ROUTE}`);

	return data;
}

export async function FORGOT_PASSWORD(payload) {
	const { data } = await API.post(`${AUTH_LOGIN_ROUTE}/forgot`, payload);

	return data;
}

export async function VERIFY_HASH(payload) {
	const { data } = await API.post(`${AUTH_LOGIN_ROUTE}/verify`, payload);

	return data;
}

export async function RECOVER_PASSWORD(payload) {
	const { data } = await API.post(`${AUTH_LOGIN_ROUTE}/recover`, payload);

	return data;
}

export async function UPDATE_PASSWORD(payload) {
	const { data } = await API.put(`${AUTH_ROUTE}/password`, payload);

	return data;
}
