import { UsersProvider } from 'hooks/users'
import UnitProvider from "./unitProvider";

const UserProvider = ({ children }) => {
	return (
		<UnitProvider>
			<UsersProvider>{children}</UsersProvider>
		</UnitProvider>
	)
}

export default UserProvider
