import {useQuery} from "react-query";
import {createContext, useContext, useReducer} from "react";
import {
	GET_CHECK_UP_KITS_COLESTEROL_TOTAL,
	GET_CHECK_UP_KITS_GLICOSE,
	GET_CHECK_UP_KITS_HBA1C,
	GET_CHECK_UP_KITS_HDL,
	GET_CHECK_UP_KITS_LDL,
	GET_CHECK_UP_KITS_TFG,
	GET_CHECK_UP_KITS_TOTAL,
	GET_CHECK_UP_KITS_TRIGLICERIDEOS
} from "services/dashboards/exam";
import {useFilter} from "./dashFilter";

const ACTION_TYPES = {
	SET_VALUES_DEFAULT: "SET_VALUES_DEFAULT",
	UPDATE_FILTERS: "UPDATE_FILTERS"
};

const reducers = (state, action) => {
	switch (action.type) {
		case  ACTION_TYPES.UPDATE_FILTERS: {
			return {
				...state,
				filters: action.data
			}
		}
		case ACTION_TYPES.SET_VALUES_DEFAULT: {
			const newValue = {
				...state.dash_val,
				...action.data
			};
			return {
				...state,
				dash_val: {
					...state.dash_val,
					...action.data
				}
			}
		}
		default: {
			return state
		}
	}
};

const ExamDashStateContext = createContext();
const ExamDashDispatchContext = createContext();

const ExamDashProvider = ({children}) => {
	const [state, dispatch] = useReducer(reducers, {filters: "", dash_val: {}});
	const {filters} = useFilter();

	const [
		{isFetching: isLoadingGetCheckUpKitsTotal},
		{isFetching: isLoadingGetCheckUpKitsHdl},
		{isFetching: isLoadingGetCheckUpKitsLdl},
		{isFetching: isLoadingGetCheckUpKitsHba1c},
		{isFetching: isLoadingGetCheckUpKitsGlicose},
		{isFetching: isLoadingGetCheckUpKitsTriglicerideos},
		{isFetching: isLoadingGetCheckUpKitsColesterolTotal},
		{isFetching: isLoadingGetCheckUpKitsTFG},
	] = queryMultiple({
		onSuccess: (data) => {
			dispatch({type: ACTION_TYPES.SET_VALUES_DEFAULT, data: data});
		}
	}, filters);

	return (
		<ExamDashStateContext.Provider
			value={{
				isLoadingGetCheckUpKitsTotal,
				isLoadingGetCheckUpKitsHdl,
				isLoadingGetCheckUpKitsLdl,
				isLoadingGetCheckUpKitsHba1c,
				isLoadingGetCheckUpKitsGlicose,
				isLoadingGetCheckUpKitsTriglicerideos,
				isLoadingGetCheckUpKitsColesterolTotal,
				isLoadingGetCheckUpKitsTFG,
				...state
			}}>
			<ExamDashDispatchContext.Provider value={dispatch}>
				{children}
			</ExamDashDispatchContext.Provider>
		</ExamDashStateContext.Provider>
	)
};

const queryGetCheckUpKitsTotal = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsTotal = useQuery(["get_check_up_kits_total", filters], () => GET_CHECK_UP_KITS_TOTAL(filters), setValueChart);
	return [getCheckUpKitsTotal];
};

const queryGetCheckUpKitsHdl = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsHdl = useQuery(["get_check_up_kits_hdl", filters], () => GET_CHECK_UP_KITS_HDL(filters), setValueChart);
	return [getCheckUpKitsHdl];
};

const queryGetCheckUpKitsLdl = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsLdl = useQuery(["get_check_up_kits_ldl", filters], () => GET_CHECK_UP_KITS_LDL(filters), setValueChart);
	return [getCheckUpKitsLdl];
};

const queryGetCheckUpKitsHba1c = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsHba1c = useQuery(["get_check_up_kits_hba1c", filters], () => GET_CHECK_UP_KITS_HBA1C(filters), setValueChart);
	return [getCheckUpKitsHba1c];
};

const queryGetCheckUpKitsGlicose = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsGlicose = useQuery(["get_check_up_kits_glicose", filters], () => GET_CHECK_UP_KITS_GLICOSE(filters), setValueChart);
	return [getCheckUpKitsGlicose];
};

const queryGetCheckUpKitsTriglicerideos = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsTriglicerideos = useQuery(["get_check_up_kits_triglicerideos", filters], () => GET_CHECK_UP_KITS_TRIGLICERIDEOS(filters), setValueChart);
	return [getCheckUpKitsTriglicerideos];
};

const queryGetCheckUpKitsColesterolTotal = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsColesterolTotal = useQuery(["get_check_up_kits_colesterol_total", filters], () => GET_CHECK_UP_KITS_COLESTEROL_TOTAL(filters), setValueChart);
	return [getCheckUpKitsColesterolTotal];
};

const queryGetCheckUpKitsTFG = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsTFG = useQuery(["get_check_up_kits_tfg", filters], () => GET_CHECK_UP_KITS_TFG(filters), setValueChart);
	return [getCheckUpKitsTFG];
};

const queryMultiple = (setValueChart, filters = "", debug = false) => {
	const getCheckUpKitsTotal = useQuery(["get_check_up_kits_total", filters], () => GET_CHECK_UP_KITS_TOTAL(filters), setValueChart);
	const getCheckUpKitsHdl = useQuery(["get_check_up_kits_hdl", filters], () => GET_CHECK_UP_KITS_HDL(filters), setValueChart);
	const getCheckUpKitsLdl = useQuery(["get_check_up_kits_ldl", filters], () => GET_CHECK_UP_KITS_LDL(filters), setValueChart);
	const getCheckUpKitsHba1c = useQuery(["get_check_up_kits_hba1c", filters], () => GET_CHECK_UP_KITS_HBA1C(filters), setValueChart);
	const getCheckUpKitsGlicose = useQuery(["get_check_up_kits_glicose", filters], () => GET_CHECK_UP_KITS_GLICOSE(filters), setValueChart);
	const getCheckUpKitsTriglicerideos = useQuery(["get_check_up_kits_triglicerideos", filters], () => GET_CHECK_UP_KITS_TRIGLICERIDEOS(filters), setValueChart);
	const getCheckUpKitsColesterolTotal = useQuery(["get_check_up_kits_colesterol_total", filters], () => GET_CHECK_UP_KITS_COLESTEROL_TOTAL(filters), setValueChart);
	const getCheckUpKitsTFG = useQuery(["get_check_up_kits_tfg", filters], () => GET_CHECK_UP_KITS_TFG(filters), setValueChart);

	return [getCheckUpKitsTotal, getCheckUpKitsHdl, getCheckUpKitsLdl, getCheckUpKitsHba1c, getCheckUpKitsGlicose, getCheckUpKitsTriglicerideos, getCheckUpKitsColesterolTotal, getCheckUpKitsTFG];
};

const useExamDash = () => {
	const context = useContext(ExamDashStateContext);

	if (!context) {
		throw new Error("useExamDash must be used within an ExamDashProvider");
	}

	return context
};

export {
	ExamDashProvider,
	queryGetCheckUpKitsTotal,
	queryGetCheckUpKitsHdl,
	queryGetCheckUpKitsLdl,
	queryGetCheckUpKitsHba1c,
	queryGetCheckUpKitsGlicose,
	queryGetCheckUpKitsTriglicerideos,
	queryGetCheckUpKitsColesterolTotal,
	useExamDash,
}
