import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {axisBottom, axisLeft, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {padding} from "variables/nivo/bar";
import {singleColorChart} from "variables/colors";
import Loading from "../../../Loading/Loading";
import {useEpidemicDash} from "../../../../hooks/epidemicDash";

const FamiliyDiseasesChart = () => {
    const { isLoadingGetAllEpis: isLoading, dash_val: data } = useEpidemicDash();
    
    const { colorMode } = useColorMode();

    if (!!data && !!data.get_all_epis && !!data.get_all_epis.historic && !isLoading) {
        return (
            <ResponsiveBar
                data={data.get_all_epis.historic}
                keys={["quant"]}
                indexBy="name"
                layout="horizontal"
                margin={{top: 10, right: 20, bottom: 30, left: 140}}
                padding={padding}
                colors={singleColorChart}
                labelSkipWidth={11}
                theme={colorMode === "light" ? themeLight : themeDark}
                axisBottom={axisBottom}
                axisLeft={axisLeft}
                labelTextColor={labelTextColor}
                tooltip={({indexValue, value}) => tooltip(indexValue, value)}
            />
        )
    }

    return <Loading/>
};

export default FamiliyDiseasesChart
