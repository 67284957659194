import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import InputMask from 'react-input-mask';

import { useCreateUnit } from "hooks/units";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import states from "variables/states";
import cities from "variables/cities";

const schema = yup.object().shape({
    name: yup
      .string()
      .required("O campo Nome é obrigatório"),
    cnpj: yup
      .string()
      .required("O campo CPNJ é obrigatório"),
    phone: yup
      .string()
      .required("O campo Telefone é obrigatório"),
    zip: yup
      .string()
      .required("O campo CEP é obrigatório"),
    state: yup
      .string()
      .required("O campo Estado é obrigatório"),
    city: yup
      .string()
      .required("O campo Cidade é obrigatório"),
    address: yup
      .string()
      .required("O campo Endereço é obrigatório"),
    number: yup
      .string()
      .required("O campo Número é obrigatório"),
})

const CreateUnit = () => {
  const methods = useForm();
  const toast = useToast()
  const history = useHistory();

  const { register, handleSubmit, formState: { errors }, reset, getValues, watch } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const stateId = watch('state', 0);

  const { mutateAsync: data, isLoading } = useCreateUnit({
    onSuccess: () => {
      reset()
      toast({
        title: 'Unidade criada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/administracao/unidades`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Criar Unidade
              </Text>
              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                Preencha dos campos para realizar o cadastro
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(data))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                    templateRows={{ md: "repeat(2, 1fr)" }}
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.name}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Nome
                      </FormLabel>
                      <Input
                        {...register('name')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.cnpj}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CNPJ
                      </FormLabel>
                      <Input
                        {...register('cnpj')} 
                        as={InputMask}
                        mask="99.999.999/9999-99"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.cnpj && <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.phone}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Telefone
                      </FormLabel>
                      <Input
                        {...register('phone')} 
                        as={InputMask}
                        mask="(99) 99999-9999"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.zip}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CEP
                      </FormLabel>
                      <Input
                        {...register('zip')} 
                        as={InputMask}
                        mask="99999-999"
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.zip && <FormErrorMessage>{errors.zip.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.state}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Estado
                      </FormLabel>
                      <Select
                        {...register('state')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder="Selecione uma opção..."
                        color="gray.400"
                      >
                        {
                          states.map(state => {
                            return (
                              <option id={state.id} value={state.id}>{state.name}</option>
                            )
                          })
                        }
                      </Select>
                      {errors.state && <FormErrorMessage>{errors.state.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.city}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Cidade
                      </FormLabel>
                      <Select
                        {...register('city')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                        placeholder={stateId == 0 ? "Selecione um estado" : "Selecione uma opção..."}
                        color="gray.400"
                      >
                        {
                          cities.filter(city => city.state_id == stateId).map(city => {
                            return (
                              <option id={city.id} value={city.id}>{city.name}</option>
                            )
                          })
                        }
                      </Select>
                      {errors.city && <FormErrorMessage>{errors.city.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.address}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Endereço
                      </FormLabel>
                      <Input
                        {...register('address')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.address && <FormErrorMessage>{errors.address.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.number}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Número
                      </FormLabel>
                      <Input
                        {...register('number')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.number && <FormErrorMessage>{errors.number.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    templateRows={{ md: "repeat(2, 1fr)" }}
                    mt="24px"
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.complement}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Complemento
                      </FormLabel>
                      <Input
                        {...register('complement')} 
                        focusBorderColor={inputHover}
                        borderRadius="8px"
                        fontSize="md"
                      />
                      {errors.complement && <FormErrorMessage>{errors.complement.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  <Flex mt="24px" align={"center"} justify={"flex-end"}>
                    <NavLink to="/sistema/administracao/unidades">
                      <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                        Cancelar
                      </Text>
                    </NavLink>
                    <Button
                      isLoading={isLoading}
                      type='submit'
                      variant="no-hover"
                      bg={buttonBg}
                      alignSelf="flex-end"
                      w="100px"
                      h="35px"
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        Criar
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default CreateUnit;
