import React from "react";
import { useParams } from "react-router-dom";

// import { useCustomer } from "hooks/useCustomers";

import {
    Flex,
    Grid,
} from "@chakra-ui/react";

import {useClient, useClients, useClientCarePlanUpdate} from "../../../hooks/clients";
import RisksAndFactors from "components/Clients/RisksAndFactors";
import CarePlanForm from "../../../components/Clients/CarePlanForm";
import GeneralInfo from "components/Clients/GeneralInfo";
import CheckUps from "components/Clients/CheckUps";

export default () => {
    const { id } = useParams();
    const { client: customer } = useClients()
    const { isLoading } = useClient(id)
    const { isLoading: updatingCarePlan, mutateAsync: onUpdateCarePlan } = useClientCarePlanUpdate(id)

    const getDate = (date) => {
        const aditionalZero = (number) => {
            return number < 10 ? '0' + number : number;
        }
        const formattedDate = new Date(date);
        return `${aditionalZero(formattedDate.getDate())}/${aditionalZero(parseInt(formattedDate.getMonth()) + 1)}/${formattedDate.getFullYear()}`;
    }
    
    return (
        <Flex
            direction="column"
            minH="100vh"
            align="center"
            pt={{ sm: "120px", lg: "160px" }}
        >
            <Grid
                templateColumns={{ sm: "1fr", md: "repeat(1, 1fr 2fr)" }}
                templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
                gap="12px"
                w={"100%"}
                pt={{ sm: "12px", lg: "12px" }}
            >
                <GeneralInfo getDate={getDate} customer={customer} isLoading={isLoading}/>
                <CheckUps getDate={getDate} kits={customer?.kits}/>
                <RisksAndFactors risks={customer?.updated_metrics}/>
                <CarePlanForm clientId={customer?.id} 
                    carePlan={customer?.care_plan} 
                    onSubmit={onUpdateCarePlan} 
                    getDate={getDate} 
                    isLoading={updatingCarePlan}
                    documents={customer?.care_plan?.documents}
                />
            </Grid>
        </Flex>
    )
}
