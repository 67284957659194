import React from 'react'

import { useUsers, useDeleteUser } from 'hooks/users';

import { 
  Button,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  useToast 
} from '@chakra-ui/react'

const DeleteUser = () => {
  const { toDelete , deleteModalOpen, setDeleteModalOpen } = useUsers();
  const toast = useToast()

  const { isLoading, mutate: onRemove } = useDeleteUser(toDelete, {
    onSuccess: () => {
      toast({
        title: 'Usuário removido com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      setDeleteModalOpen(false)
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })
  return (
    <Modal
        isCentered
        onClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deletar Usuário #{toDelete}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Por favor, confirme a ação de exclusão.
          </ModalBody>
          <ModalFooter>
            <Button mr={3} variant='ghost' onClick={() => setDeleteModalOpen(false)}>Cancelar</Button>
            <Button 
              isLoading={isLoading}
              colorScheme='red' 
              onClick={() => onRemove()}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default DeleteUser