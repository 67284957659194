import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
    GET_USERS,
    GET_USER,
    POST_USER,
    UPDATE_USER,
    DELETE_USER
} from 'services/users'

const ACTION_TYPES = {
    LIST: 'LIST_USERS',
    VIEW: 'VIEW_USER',
    ADD: 'ADD_USER',
    UPDATE: 'UPDATE_USER',
    REMOVE: 'REMOVE_USER',
}

const usersReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				users: action.data.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				users: [ ...state.users, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				user: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				users: state?.users?.length > 0 ? state.users.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				users: state?.users?.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const UsersStateContext = createContext()
const UsersDispatchContext = createContext()

const UsersProvider = ({ children }) => {
    const [state, dispatch] = useReducer(usersReducer, { users: [] })

		const [toDelete, setToDelete] = React.useState(null)
		const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  
    const { isLoading, isFetching } = useQuery(['users'], () => GET_USERS(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
  
    return (
			<UsersStateContext.Provider value={{ isLoading, isFetching, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen, ...state }}>
					<UsersDispatchContext.Provider value={dispatch}>{children}</UsersDispatchContext.Provider>
			</UsersStateContext.Provider>
    )
}

const useUsers = () => {
	const context = useContext(UsersStateContext)

	if (!context)
		throw new Error('useUsers must be used within an UsersProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(UsersDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a UsersProvider')

	return dispatch
}

const useCreateUser = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_USER(data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useUser = (id, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['user'], () => GET_USER(id), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useUpdateUser = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => UPDATE_USER(id, data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteUser = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_USER(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	UsersProvider,
	useUsers,
	useCreateUser,
	useUser,
	useUpdateUser,
	useDeleteUser
}
