import { OrdersProvider } from 'hooks/orders'
import {UnitsProvider} from "../hooks/units";

const OrderProvider = ({ children }) => {
	return (
		<UnitsProvider>
			<OrdersProvider>{children}</OrdersProvider>
		</UnitsProvider>
	)
}

export default OrderProvider
