import { UnitsProvider } from 'hooks/units'
import { DashboardsProvider as Provider } from 'hooks/dashboards'

const DashboardProvider = ({ children }) => {
	return (
    <UnitsProvider>
			<Provider>
		 	 {children}
			</Provider>
    </UnitsProvider>
	)
}

export default DashboardProvider
