import React from "react";
import Loading from "components/Loading/Loading";
import {useColorMode} from "@chakra-ui/react";
import {ResponsivePie} from "@nivo/pie";
import {defaultColorsPlus} from "variables/colors";
import {useDemographicDash} from "hooks/demographicDash";
import {border, themeDark, tooltip} from "variables/nivo/default";
import {
    arc,
    arcLabelsTextColor,
    arcLinkLabelsColor,
    legendsDark,
    legendsLight,
    margin,
    pieProps
} from "variables/nivo/pie";


const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const EthinicityChart = () => {
    const { isLoadingCustomersEthinicityAmount: isLoading, dash_val: data } = useDemographicDash();

    const { colorMode } = useColorMode();

    if (!isLoading && !!data && !!data.ethnicities_amount_by_partner) {
        return (
            <ResponsivePie
                data={data.ethnicities_amount_by_partner}
                margin={margin}
                colors={defaultColorsPlus}
                theme={themeDark}
                {...border}
                {...arc}
                arcLinkLabelsColor={arcLinkLabelsColor}
                arcLabelsTextColor={arcLabelsTextColor}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.ethnicities_amount_by_partner), datum.color)}
                {...pieProps}
                innerRadius={0.7}
            />
        )
    }
    
    return <Loading/>;
};

export default EthinicityChart
