import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
	GET_UNITS,
	GET_UNIT,
	POST_UNIT,
	UPDATE_UNIT,
	DELETE_UNIT, GET_UNITS_WITH_KITS
} from 'services/units'

const ACTION_TYPES = {
    LIST: 'LIST_UNITS',
	LIST_KIT_TYPES: 'LIST_KIT_TYPES',
    VIEW: 'VIEW_UNIT',
    ADD: 'ADD_UNIT',
    UPDATE: 'UPDATE_UNIT',
    REMOVE: 'REMOVE_UNIT',
}

const unitsReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				units: action.data
			}
		}
		case ACTION_TYPES.LIST_KIT_TYPES: {
			return {
				...state,
				type_kits: action.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				units: [ ...state.units, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				unit: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				units: state.units.length > 0 ? state.units.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				units: state.units.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const UnitsStateContext = createContext()
const UnitsDispatchContext = createContext()

const UnitsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(unitsReducer, { units: [], type_kits: [] })

		const [toDelete, setToDelete] = React.useState(null)
		const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  
    const { isLoading, isFetching } = useQuery(['units'], () => GET_UNITS_WITH_KITS(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					dispatch({ type: ACTION_TYPES.LIST, data: data.units })
					dispatch({ type: ACTION_TYPES.LIST_KIT_TYPES, data: data.type_kits })
					return;
				}
				dispatch({ type: ACTION_TYPES.LIST, data: data.units })
				dispatch({ type: ACTION_TYPES.LIST_KIT_TYPES, data: data.type_kits })
			},
    })
  
    return (
			<UnitsStateContext.Provider value={{ isLoading, isFetching, ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen }}>
					<UnitsDispatchContext.Provider value={dispatch}>{children}</UnitsDispatchContext.Provider>
			</UnitsStateContext.Provider>
    )
}

const useUnits = () => {
	const context = useContext(UnitsStateContext)

	if (!context)
		throw new Error('useUnits must be used within an UnitsProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(UnitsDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a UnitsProvider')

	return dispatch
}

const useCreateUnit = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_UNIT(data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useUnit = (id, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['unit'], () => GET_UNIT(id), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useUpdateUnit = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => UPDATE_UNIT(id, data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteUnit = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_UNIT(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	UnitsProvider,
	useUnits,
	useCreateUnit,
	useUnit,
	useUpdateUnit,
	useDeleteUnit
}
