import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {Box, Button, Flex, Grid, Text, useColorModeValue} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import UsersTable from 'components/Tables/Users/UsersTable';
import DeleteUser from './DeleteUser/DeleteUser';

const Users = () => {
    const location = useLocation()
    const history = useHistory()

    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
    const buttonColor = useColorModeValue("white", "white");

    const handleClick = () => {
        history.push(`${location.pathname}/criar`);
    }

    return (<Grid
        templateColumns={{sm: "1fr", md: "1fr"}}
        templateRows={{sm: "repeat(8, 1fr)", md: "repeat(1, 1fr)"}}
        gap="12px"
        pt={{sm: "125px", lg: "160px"}}
    >
        <Card px="0px" pb="24px" minHeight="320px">
            <CardHeader mb="34px" px="22px">
                <Box w="100%">
                    <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{sm: "10px", lg: "12px"}}
                        color="gray.400"
                    >
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Usuários
                        </Text>
                        <Button
                            bg={buttonBg}
                            variant="no-hover"
                            color={buttonColor}
                            onClick={() => handleClick()}>
                            Criar Usuário
                        </Button>
                    </Flex>
                </Box>
            </CardHeader>
            <CardBody h="100%">
                <Box w="100%" h="100%">
                    <UsersTable/>
                    <DeleteUser/>
                </Box>
            </CardBody>
        </Card>
    </Grid>)
}

export default Users