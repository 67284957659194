import React from "react";

import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";


const CustomerAssestment = ({answers}) => {
  const { colorMode } = useColorMode();

  const textColor = (colorMode == 'light') ? 'black' : 'white';
  const bgColor = (colorMode == 'light') ? 'gray.200' : 'gray.500';

  return (
    <>
      <Flex w="100%" direction="column">
        <Text
        color={textColor}
        fontSize="lg"
        fontWeight="bold"
        mb="10px"
        >
          Formulário
        </Text>
        <TableContainer width="100%">
          <Table variant='simple' size='md' minW="100%" width="100%" overflowX="unset">
            <Thead>
            </Thead>
            <Tbody width="100%">
              {
                answers.map(answer => {
                  if(answer.field.title == 'imc'){
                    return (
                      <>
                        <Tr bg={bgColor}>
                          <Td>Altura</Td>
                        </Tr>
                        <Tr>
                          <Td>{answer.response.heigth}m</Td>
                        </Tr>
                        <Tr bg={bgColor}>
                          <Td>Peso</Td>
                        </Tr>
                        <Tr>
                          <Td>{answer.response.weigth}kg</Td>
                        </Tr>
                      </>
                    )
                  } else if(answer.field.title == "Data e hora da coleta de sangue"){
                    return (
                      <>
                      <Tr bg={bgColor} w="100%">
                        <Td>{answer.field.title}</Td>
                      </Tr>
                      <Tr>
                        <Td>{answer.response.date} {answer.response.time}</Td>
                      </Tr>
                      </>
                    )
                  }
                  else {
                    return (
                      <>
                        <Tr bg={bgColor}>
                          <Td >{answer.field.title}</Td>
                        </Tr>
                        <Tr>
                          <Td>{answer?.option?.label ? answer.option.label : answer.response}</Td>
                        </Tr>
                      </>
                    )
                  }
                })
              }
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </>
  )
}

export default CustomerAssestment