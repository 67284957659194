const singleColorChart = "#2B8F5E";

const riskDoubleColors = [
	"#22b357",
	"#CB4333"
];

const risksColors = [
	"#22b357",
	"#E0A51B",
	"#CB4333"
];

const risksColorsPlus = [
	"#3E993C",
	"#22b357",
	"#E0A51B",
	"#d97325",
	"#cb4c33"
];

const hospitalizationColorsPlus = [
	"#22b357",
	"#cb4c33",
	"#3E993C",
	"#E0A51B"
];

const risksSmokeSpecific = [
	"#E0A51B",
	"#CB4333",
	"#22b357",
];

const yesNoColors = [
	"#22b357",
	"#CB4333"
];

const noYesColors = [
	"#CB4333",
	"#22b357"
];

const defaultColors = [
	"#3E993C",
	"#15ADA7",
	"#4255C2"
];

const defaultColorsPlus = [
	"#3E993C",
	"#22b357",
	"#15ADA7",
	"#4255C2",
	"#8940bc",
	"#c04ac0",
	"#df74b4"
];

const lineColors = [
	"#0000FF",
	"#FFFF00",
	"#00FF00",
];

export {
	singleColorChart,
	riskDoubleColors,
	risksColors,
	risksColorsPlus,
	risksSmokeSpecific,
	defaultColors,
	defaultColorsPlus,
	yesNoColors,
	noYesColors,
	hospitalizationColorsPlus,
	lineColors,
}
