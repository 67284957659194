const margin = { 
	top: 10, 
	right: 20, 
	bottom: 35, 
	left: 40
}

const padding = 0.3;

export {
	margin,
	padding
}