import {createContext, useContext, useReducer} from "react"
import { useMutation } from "react-query";
import { LaboratoriesDashProvider } from "./laboratoriesDash";
const ACTION_TYPES = {
    UPDATE_FILTER: "UPDATE_FILTER"
}
const reducers = (state, action) => {
    switch(action.type) {
        case ACTION_TYPES.UPDATE_FILTER: {
            return {
                filters: action.data
            }
        }
        default: {
            return state;
        }
    }
}


const FilterStateContext = createContext()
const FilterDispatchContext = createContext()


const FilterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducers, { filters: ""})
    return (
        <FilterStateContext.Provider value={state}>
            <FilterDispatchContext.Provider value={dispatch}>
                { children }
            </FilterDispatchContext.Provider>
        </FilterStateContext.Provider>
    )
}
const useDispatch = () => {
    const dispatch = useContext(FilterDispatchContext)
    if (!dispatch) {
        throw new Error('useFilter must be used within an FilterProvider')
    }

    return dispatch
}
const useFilter = () => {
    const context = useContext(FilterStateContext)

    if (!context)
        throw new Error('useFilter must be used within an FilterProvider')

    return context;
}

const useUpdateFilters = () => {
    const dispatch = useDispatch()

    return useMutation((filters) => dispatch({type: ACTION_TYPES.UPDATE_FILTER, data: filters}))
}
export {
    FilterProvider,
    useFilter,
    useUpdateFilters
}
