import React, { useMemo } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import InputMask from 'react-input-mask';

import { useUnit, useUpdateUnit } from "hooks/units";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Skeleton,
  Grid,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import states from "variables/states";
import cities from "variables/cities";

const schema = yup.object().shape({
    name: yup
      .string()
      .required("O campo Nome é obrigatório"),
    cnpj: yup
      .string()
      .required("O campo CPNJ é obrigatório"),
    phone: yup
      .string()
      .required("O campo Telefone é obrigatório"),
    zip: yup
      .string()
      .required("O campo CEP é obrigatório"),
    state_id: yup
      .string()
      .required("O campo Estado é obrigatório"),
    city_id: yup
      .string()
      .required("O campo Cidade é obrigatório"),
    address: yup
      .string()
      .required("O campo Endereço é obrigatório"),
    number: yup
      .string()
      .required("O campo Número é obrigatório"),
})

const EditUnit = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useUnit(id);
  const history = useHistory();

  const methods = useForm();
  const toast = useToast()

  const { register, handleSubmit, formState: { errors }, getValues, watch, setValue } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
  });

  const stateId = watch('state_id', null);

  const citiesFil = useMemo(() => {
    console.log(data, stateId);
    if(!data) return cities;
    return stateId == null ? cities.filter(city => city.state_id == data.state_id) : cities.filter(city => city.state_id == stateId)
  }, [data, stateId])
  
  const { mutateAsync: unit, isLoading: isLoadingUpdate } = useUpdateUnit(id, {
    onSuccess: () => {
      toast({
        title: 'Unidade atualizada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/administracao/unidades/${id}`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Editar Unidade #{id}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(unit))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                    templateRows={{ md: "repeat(2, 1fr)" }}
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.name}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Nome
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('name')} 
                          defaultValue={data?.name || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.cnpj}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CNPJ
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('cnpj')} 
                          as={InputMask}
                          mask="99.999.999/9999-99"
                          defaultValue={data?.cnpj || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.cnpj && <FormErrorMessage>{errors.cnpj.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.phone}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Telefone
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('phone')} 
                          as={InputMask}
                          mask="(99) 99999-9999"
                          defaultValue={data?.phone || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.phone && <FormErrorMessage>{errors.phone.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.zip}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        CEP
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('zip')} 
                          as={InputMask}
                          mask="99999-999"
                          defaultValue={data?.zip_code || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.zip && <FormErrorMessage>{errors.zip.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.state}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Estado
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Select
                          {...register('state_id')} 
                          defaultValue={data?.state_id || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                          placeholder="Selecione uma opção..."
                          color="gray.400"
                        >
                          {
                            states.map(state => {
                              return (
                                <option id={state.id} value={state.id}>{state.name}</option>
                              )
                            })
                          }
                        </Select>
                      }
                      {errors.state && <FormErrorMessage>{errors.state.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.city}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Cidade
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Select
                          {...register('city_id')} 
                          defaultValue={data?.city_id || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                          placeholder="Selecione uma opção..."
                          color="gray.400"
                        >
                          {
                            citiesFil.map(city => {
                              return (
                                <option id={city.id} value={city.id}>{city.name}</option>
                              )
                            })
                          }
                        </Select>
                      }
                      {errors.city && <FormErrorMessage>{errors.city.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.address}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Endereço
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('address')} 
                          defaultValue={data?.address || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.address && <FormErrorMessage>{errors.address.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.number}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Número
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('number')} 
                          defaultValue={data?.number || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.number && <FormErrorMessage>{errors.number.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    templateRows={{ md: "repeat(1, 1fr)" }}
                    mt="24px"
                    gap="24px"
                  >
                    <FormControl isInvalid={errors.complement}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Complemento
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('complement')} 
                          defaultValue={data?.complement || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.complement && <FormErrorMessage>{errors.complement.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                    <Flex mt="24px" align={"center"} justify={"flex-end"}>
                      <NavLink to="/sistema/administracao/unidades">
                        <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                          Cancelar
                        </Text>
                      </NavLink>
                      <Button
                        isLoading={isLoadingUpdate}
                        type='submit'
                        variant="no-hover"
                        bg={buttonBg}
                        alignSelf="flex-end"
                        w="100px"
                        h="35px"
                      >
                        <Text fontSize="xs" color="#fff" fontWeight="bold">
                          Atualizar
                        </Text>
                      </Button>
                    </Flex>
                </Flex>
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default EditUnit;
