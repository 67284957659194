import React from "react";
import {Box, Flex, Grid, Text, useColorModeValue, useToast,} from "@chakra-ui/react";
// Assets
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import EditPassword from "components/Profile/EditPassword/EditPassword";
import {useAuth} from "hooks/auth";
import {useSyncSignature} from "../../hooks/auth";
import DropSignature from "../../components/Profile/DropSignature";

const Profile = () => {
    const data = useAuth();
    const toast = useToast();
    const {mutateAsync: userUpdate, isLoading: isLoadingUpdate} = useSyncSignature( {
        onSuccess: () => {
            toast({
                title: 'Usuário atualizado com sucesso.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        },
        onError: () => {
            toast({
                title: 'Ocorreu um erro.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        },
    });
    const user = !!data.user.name ? data.user : data.user.data;

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );
    const borderProfileColor = useColorModeValue("white", "rgba(255, 255, 255, 0.31)");
    const emailColor = useColorModeValue("gray.400", "gray.300");

    return (
        <Flex direction="column">
            <Box mb={{sm: "70px", md: "10px", xl: "10px"}} borderRadius="15px" px="0px" display="flex"
                 flexDirection="column" justifyContent="center" align="center">
                <Box w="100%" h="300px" borderRadius="25px" bgPosition="50%" bgRepeat="no-repeat" position="relative"
                     display="flex" justifyContent="center">
                    <Flex direction={{sm: "column", md: "row"}} mx="1.5rem" maxH="330px" w={{sm: "90%", xl: "95%"}}
                          justifyContent={{sm: "center", md: "space-between"}} align="center" position="absolute"
                          backdropFilter="saturate(200%) blur(50px)" boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
                          border="2px solid" borderColor={borderProfileColor} bg={bgProfile} p="24px"
                          borderRadius="20px"
                          transform={{sm: "translateY(60%)", md: "translateY(120%)", lg: "translateY(120%)"}}>
                        <Flex align="center" mb={{sm: "10px", md: "0px"}} direction={{sm: "column", md: "row"}}
                              w={{sm: "100%"}} textAlign={{sm: "center", md: "start"}}>
                            <Flex direction="column" maxWidth="100%" my={{sm: "14px"}}>
                                <Text fontSize={{sm: "lg", lg: "xl"}} color={textColor} fontWeight="bold"
                                      ms={{sm: "8px", md: "0px"}}>
                                    {user.name ?? null}
                                </Text>
                                <Text fontSize={{sm: "sm", md: "md"}} color={emailColor} fontWeight="semibold">
                                    {user.partner.name ?? null}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
            <Grid templateColumns={{sm: "1fr", xl: "repeat(3, 1fr)"}} gap="22px">
                <Card>
                    <CardHeader mb="40px">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Editar dados
                        </Text>
                    </CardHeader>
                    <CardBody>
                        <DropSignature callback={userUpdate} signature={user.signature_url_s3} url={user.signature_url_s3} isLoading={isLoadingUpdate}/>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader mb="40px">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                            Alterar Senha
                        </Text>
                    </CardHeader>
                    <CardBody>
                        <EditPassword/>
                    </CardBody>
                </Card>
                <Card px="0px" pb="0px">
                    <CardHeader mb="34px" px="22px">
                        <Text fontSize="lg" color={textColor} fontWeight="bold">
                        </Text>
                    </CardHeader>
                    <CardBody px="5px">
                        <Flex direction="column" w="100%">
                        </Flex>
                    </CardBody>
                </Card>
            </Grid>
        </Flex>
    );
}

export default Profile;
