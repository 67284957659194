import React from 'react'
import { ResponsiveBar } from '@nivo/bar'

import { useCompanyDash } from 'hooks/companiesDash';
import { useColorMode } from "@chakra-ui/react";

import Loading from 'components/Loading/Loading';

import { themeDark, themeLight, axisBottom, axisLeft, labelTextColor, tooltip } from 'variables/nivo/default'
import { margin } from 'variables/nivo/bar'
import { defaultColors } from 'variables/colors';
import {useUnits} from "../../../../hooks/units";

const KitStatusChart = () => {
  const { isLoadingKitStock: isLoading, dash_val: data } = useCompanyDash();
	const { type_kits } = useUnits()

	const { colorMode } = useColorMode();

  if(isLoading || !data.kit_status)
    return <Loading />

  return (
		<ResponsiveBar
				data={data.kit_status || []}
				keys={type_kits.map((i) => i.name)}
				indexBy="status"
				margin={margin}
				groupMode="stacked"
				padding={0.7}
				colors={ defaultColors }
				labelSkipHeight={ 11 }
				theme={colorMode === 'light' ? themeLight : themeDark}
				axisBottom={axisBottom}
				axisLeft={axisLeft}
				labelTextColor={labelTextColor}
				tooltip={({label, value}) => tooltip(label, value)}
		/>
  )
}

export default KitStatusChart
