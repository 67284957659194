import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { useUnit } from "hooks/units";
import BasicTable from '../../../components/Tables/BasicTable';

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

const ViewUnit = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useUnit(id);

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w={{ md: "100%", lg: "50%" }}>
          <CardHeader mb="40px">
            <Flex direction="row" w="100%" justify="space-between">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Unidade #{id}
              </Text>
              <NavLink to="/sistema/administracao/unidades">
                <Button >
                  Voltar
                </Button>
              </NavLink>
            </Flex>
          </CardHeader>
          <CardBody>
              <Flex direction="column" w="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                  templateRows={{ md: "repeat(2, 1fr)" }}
                  gap="24px"
                >
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Nome
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.name || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CNPJ
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.cnpj || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Telefone
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.phone || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      CEP
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.zip_code || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Estado
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading && data.state}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.state || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Cidade
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading && data.city}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.city || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Endereço
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading && data.address}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.address || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Número
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading && data.number}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.number || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                </Grid>
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                  templateRows={{ md: "repeat(1, 1fr)" }}
                  mt="24px"
                  gap="24px"
                >
                  <FormControl >
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Complemento
                    </FormLabel>
                    <Skeleton height={"25px"} isLoaded={!isLoading && data.complement}>
                      <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        {data?.complement || null}
                      </Text>
                    </Skeleton>
                  </FormControl>
                </Grid>
              </Flex>
          </CardBody>
        </Card>
      </Flex>
  )
}

export default ViewUnit