

import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useCreateReverse } from "hooks/reverses";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {useUnits} from "../../../hooks/units";

const schema = yup.object().shape({
    unit: yup
      .string()
      .required("O campo Unidade é obrigatório"),
    quantity: yup
      .string()
      .matches(/\d+/g, "Insira um valor numérico")
      .required("O campo é obrigatório"),
})

const CreateReverse = () => {
  const methods = useForm();
  const toast = useToast();
  const { units } = useUnits();
  const history = useHistory();

  const { register, handleSubmit, formState: { errors } } = useForm({
      mode: "onBlur",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });

  const { mutateAsync: data, isLoading } = useCreateReverse({
    onSuccess: () => {
      toast({
        title: 'Solicitação criada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/solicitacoes/coletas`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w="50%">
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Cria Solicitação
              </Text>
              <Text color="gray.400" fontWeight="normal" fontSize="sm">
                Preencha dos campos para realizar a criação
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
          <FormProvider {...methods}>
            <form style={{width: '100%'}} onSubmit={(handleSubmit(data))}>
              <Flex direction="column" w="100%">
                <Grid
                  templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                  templateRows={{ md: "repeat(2, 1fr)" }}
                  gap="24px"
                >
                  <FormControl isInvalid={errors.unit}>
                    <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                      Unidade
                    </FormLabel>
                    <Select
                      {...register('unit')} 
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                      placeholder="Selecione uma opção..."
                      color="gray.400"
                    >
                      {units.map(i => (
                          <option value={i.id}>{i.name}</option>
                      ))}
                    </Select>
                    {errors.unit && <FormErrorMessage>{errors.unit.message}</FormErrorMessage>}
                  </FormControl>
                  <FormControl isInvalid={errors.quantity}>
                    <FormLabel
                      color={textColor}
                      fontWeight="bold"
                      fontSize="xs"
                    >
                      Quantas amostra de sangue para coletar ? 
                    </FormLabel>
                    <Input
                      {...register('quantity')} 
                      focusBorderColor={inputHover}
                      borderRadius="8px"
                      fontSize="md"
                    />
                    {errors.quantity && <FormErrorMessage>{errors.quantity.message}</FormErrorMessage>}
                  </FormControl>
                </Grid>
                <Flex mt="24px" align={"center"} justify={"flex-end"}>
                  <NavLink to="/sistema/solicitacoes/coletas">
                    <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                      Cancelar
                    </Text>
                  </NavLink>
                  <Button
                    isLoading={isLoading}
                    type='submit'
                    variant="no-hover"
                    bg={buttonBg}
                    alignSelf="flex-end"
                    w="100px"
                    h="35px"
                  >
                    <Text fontSize="xs" color="#fff" fontWeight="bold">
                      Criar
                    </Text>
                  </Button>
                </Flex>
              </Flex>
                
            </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default CreateReverse;
