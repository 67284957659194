import { useOrders } from 'hooks/orders'

import { Flex, Text } from "@chakra-ui/react";

import BasicTable from '../BasicTable'
import Loading from 'components/Loading/Loading'

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Nome da Unidade",
    accessor: "partner_place.name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Cadastrado em",
    accessor: "created_at",
  },
]

const KitOrdersTable = () => {
  const { isLoading, isFetching, orders } = useOrders()

  if(isLoading || isFetching)
    return (
      <>
        { 
          <BasicTable 
            isLoading={true} 
            tableData={[{},{},{},{},{},{},{},{}]} 
            columnsData={columns} 
            route="kits" 
          /> 
        }
      </>
    )

  if(orders.length === 0)
    return (
      <Flex
        justifyContent="center"  
        alignItems="center"
        w="100%"
        h="100%"
        pl="22px"
        pr="22px"
      >
        <Text>
          Não foram encontradas solicitações cadastradas
        </Text>
      </Flex>
    )

  return (
    <>
      { orders.length > 0 && <BasicTable tableData={orders} columnsData={columns} route="kits" /> }
    </>
  )
}

export default KitOrdersTable
