import React, { useState, useEffect } from "react";

import { Box, Grid, Text, useColorModeValue, Tooltip } from "@chakra-ui/react";

import { useUpdateFilters } from "hooks/dashFilter";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";
import Icon from "components/FontAwesomeIcons/Icon";

import KitStockChart from "components/Charts/Nivo/Companies/KitStockChart";
import KitEvaluationsChart from "components/Charts/Nivo/Companies/KitEvaluationsChart";
import KitStatusChart from "components/Charts/Nivo/Companies/KitStatusChart";

import { queryBuilder } from "functions/queryBuilder";

function CompaniesDashboard() {
  const [filters, setFilters] = useState('');
  const textColor = useColorModeValue("gray.700", "white");
  const { mutateAsync: updateFilters } = useUpdateFilters()

  useEffect(() => {
    const query = queryBuilder(filters);
    updateFilters(query)
  }, [filters])

  return (
    <>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "125px", lg: "160px" }}
      >
        <Card px="0px" pb="0px" minHeight="100px">
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Filtros
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              <FiltersBox 
                setFilters={setFilters}
                regionsFilter
                statesFilter
                citiesFilter
                kitsFilter
                unitsFilter
                dateFilter
              />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt={{ sm: "12px", lg: "12px" }}
      >
        <Card px="0px" pb="0px" minHeight="320px" height="300px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold" >
              Estoque Total 
            </Text>
              <Tooltip hasArrow label='O estoque desconsidera o filtro de datas (início e fim) acima sempre a posição atual.' bg='gray.300' color='black'>
                <span style={{display: 'flex', alignItems: 'center', marginLeft: 10}}>
                  <Icon icon="info" />
                </span>
              </Tooltip>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <KitStockChart />
            </Box>
          </CardBody>
        </Card>
        <Card px="0px" pb="0px" minHeight="320px" height="300px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Formulários Preenchidos
            </Text>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <KitEvaluationsChart />
            </Box>
          </CardBody>
        </Card>
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", md: "1fr" }}
        templateRows={{ sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)" }}
        gap="12px"
        pt="12px"
      >
        <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
          <CardHeader mb="34px" px="22px">
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Movimentação
            </Text>
          </CardHeader>
          <CardBody h="100%" >
            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
              <KitStatusChart />
            </Box>
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}

export default CompaniesDashboard;
