import { useTickets } from 'hooks/tickets'

import { Flex, Text } from "@chakra-ui/react";

import BasicTable from '../BasicTable'
import Loading from 'components/Loading/Loading'

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Prioridade",
    accessor: "priority",
  },
  {
    Header: "Onde encontrou o erro?",
    accessor: "content.source",
  },
  {
    Header: "O que o erro causa?",
    accessor: "content.causes",
  },
  {
    Header: "Aberto em",
    accessor: "created_at",
  },
]

const TicketsTable = () => {
  const { isLoading, isFetching, tickets } = useTickets()

  if(isLoading || isFetching)
    return (
      <>
        { 
          <BasicTable 
            isLoading={true} 
            tableData={[{},{},{},{},{},{},{},{}]} 
            columnsData={columns} 
            route="suporte" 
          /> 
        }
      </>
    )

  if(tickets.length === 0)
    return (
      <Flex
        justifyContent="center"  
        alignItems="center"
        w="100%"
        h="100%"
        pl="22px"
        pr="22px"
      >
        <Text>
          Não foram encontrados tickets cadastrados
        </Text>
      </Flex>
    )

  return (
    <>
      { tickets.length > 0 && <BasicTable tableData={tickets} columnsData={columns} route="suporte" /> }
    </>
  )
}

export default TicketsTable