import React from 'react'
import { ResponsivePie } from '@nivo/pie'

import { useCompanyDash } from 'hooks/companiesDash';
import { useColorMode } from "@chakra-ui/react";

import Loading from 'components/Loading/Loading';

import { themeDark, themeLight, border, tooltip } from 'variables/nivo/default'
import { defaultColors } from 'variables/colors'
import { arc, arcLinkLabelsColor, arcLabelsTextColor, margin, legendsDark, legendsLight, pieProps } from 'variables/nivo/halfPie'

const total = (data) => {
	let total = 0;
	data.map(item => {
		total += item.value;
	})
	return total;
}

const KitEvaluationsChart = () => {
  const { isLoadingKitStock: isLoading, dash_val: data } = useCompanyDash();

	const { colorMode } = useColorMode();

  if(isLoading || !data.kit_evaluations)
    return <Loading />

  return (
    <ResponsivePie
      data={data.kit_evaluations || []}
      margin={margin}
      colors={ defaultColors}
      theme={colorMode === 'light' ? themeLight : themeDark}
      {...border}
      {...arc}
      startAngle={-90}
      endAngle={90}
      arcLinkLabelsColor={arcLinkLabelsColor}
      arcLabelsTextColor={arcLabelsTextColor}
      legends={colorMode === 'light' ? legendsLight : legendsDark}
      tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.kit_evaluations || []), datum.color)}
			{...pieProps}
    />
  )
}

export default KitEvaluationsChart