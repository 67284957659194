import React from "react";

import {
    Avatar,
    Box,
    Flex,
    HStack,
    Image,
    Skeleton,
    Text,
    useColorMode,
    VStack,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

const handleUnits = (kits) => {
    let units = [];
    kits.map(k => k.order.map(o => {
        if(o.partner_place){
            units.push(o.partner_place.name)
        }
    }))
    console.log([...new Set(units)]);
    return [...new Set(units)];
}

const GeneralInfo = ({customer, isLoading, getDate}) => {
    const { colorMode } = useColorMode();
    const textColor = (colorMode == 'light') ? 'black' : 'white';
    const [error, setError] = React.useState(null);

    const handleBrokenImg = () => {
        return setError(true);
    }

    const formatCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }

    const units = customer?.kits?.length > 0 ? handleUnits(customer.kits) : []

    return (
        <Card px="0px" pb="22px" minHeight="100px" maxHeight="700px" overflowY={{ sm: "auto", lg: "unset" }}>
            <CardHeader px="22px">
                <Text color={textColor} fontSize="lg" fontWeight="bold" marginBottom={0}>
                </Text>
            </CardHeader>
            <CardBody h="100%" px="22px">
                <Box w="100%" h="100%" minW={{ lg: 'auto', sm: 700 }} overflowY="auto">
                    <Flex
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        flexDirection="column"
                    >
                        <HStack alignItems="flex-start" width="100%">
                            { !error && customer?.profile_picture ? <Image borderRadius="10px" boxSize='150px' mr="4" objectFit="cover" src={customer?.profile_picture} onError={handleBrokenImg} /> : <Avatar minW="150px" minH="150px" color="white" borderRadius="10px" mr="4" name={customer?.name}></Avatar>}
                            <VStack align='stretch' spacing="4" width="100%">
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Nome</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px">
                                        <Text textAlign="left" fontSize="18px">{customer?.name}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">CPF</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px" >
                                        <Text textAlign="left" fontSize="18px">{ customer?.cpf ? formatCPF(customer.cpf) : null}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Email</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px" >
                                        <Text textAlign="left" fontSize="18px">{customer?.email}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Telefone</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px" >
                                        <Text textAlign="left" fontSize="18px">{customer?.cellphone}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Cadastrado em</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px" >
                                        <Text textAlign="left" fontSize="18px">{customer?.created_at ? getDate(customer.created_at) : null}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Data de Nascimento</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px" >
                                        <Text textAlign="left" fontSize="18px">{customer?.birth_date ? getDate(customer.birth_date) : null}</Text>
                                    </Skeleton>
                                </VStack>
                                <VStack align='stretch' spacing="1">
                                    <Text textAlign="left" fontSize="14px">Unidade(s)</Text>
                                    <Skeleton isLoaded={!isLoading} height="27px">
                                        {
                                            units.length > 0 ?
                                                units.map(unit => {
                                                    return (
                                                        <VStack align='stretch' spacing="1" mb="2">
                                                            <Text textAlign="left" fontSize="18px">{unit}</Text>
                                                        </VStack>
                                                    )
                                                })
                                                :
                                                <></>
                                        }
                                    </Skeleton>
                                </VStack>
                            </VStack>
                        </HStack>
                    </Flex>
                </Box>
            </CardBody>
        </Card>
    )
}

export default GeneralInfo