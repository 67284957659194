import React from 'react'

import { useUnits, useDeleteUnit } from 'hooks/units';

import { 
  Button,
  Modal, 
  ModalBody, 
  ModalCloseButton, 
  ModalContent, 
  ModalFooter, 
  ModalHeader, 
  ModalOverlay, 
  useToast 
} from '@chakra-ui/react'

const DeleteUnit = () => {
  const { toDelete , deleteModalOpen, setDeleteModalOpen } = useUnits();
  const toast = useToast()

  const { isLoading, mutate: onRemove } = useDeleteUnit(toDelete, {
    onSuccess: () => {
      toast({
        title: 'Unidade removida com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      setDeleteModalOpen(false)
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })
  return (
    <Modal
        isCentered
        onClose={() => setDeleteModalOpen(false)}
        isOpen={deleteModalOpen}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Deletar Unidade #{toDelete}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Por favor, confirme a ação de exclusão.
          </ModalBody>
          <ModalFooter>
            <Button mr={3} variant='ghost' onClick={() => setDeleteModalOpen(false)}>Cancelar</Button>
            <Button 
              isLoading={isLoading}
              colorScheme='red' 
              onClick={() => onRemove()}
            >
              Deletar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}

export default DeleteUnit