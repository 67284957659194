import React from 'react'

import { Box, Flex } from "@chakra-ui/react";

import image from "assets/img/backgroundcard.png";

const BackgroundCard = () => {
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "50vh", md: "250px" }}
        w={{ sm: "calc(100% - 0px)", md: "calc(100% - 30px)", lg: "calc(100% - 40px)" }}
        borderRadius={{ sm: "0px", md: "50px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={image}
        bgSize="cover"
        mx={{ md: "auto" }}
        ml={{ sm: "0px", md: "10px" }}
        mr="0px"
        mt={{ md: "14px" }}
      ></Box>
    </Flex>
  )
}

export default BackgroundCard