import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useUpdatePassword } from "hooks/auth";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
  useColorModeValue,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import Icon from "components/FontAwesomeIcons/Icon";

const schema = yup.object().shape({
  current_password: yup
    .string()
    .required("O campo Senha Atual é obrigatório"),
  password: yup
    .string()
    .required("O campo Nova Senha é obrigatório")
    .matches(/(?=.*[A-Za-z])(?=.*[0-9])/g, "Insira um senha forte, contendo no mínimo uma letra e um número"),
  password_confirmation: yup
    .string()
    .required("O campo Confirmar Senha é obrigatório")
    .test('passwords-match', 'Confirme a senha nova', function(value) {
      return this.parent.password === value
    }),
})

const EditPassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false)

  const methods = useForm();
  const toast = useToast()

  const { control, register, reset, handleSubmit, formState: { errors } } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { mutateAsync: user, isLoading: isLoadingUpdate  } = useUpdatePassword({
    onSuccess: () => {
      toast({
        title: 'Senha atualizada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      reset()
    },
    onError: ({errors: error}) => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      }),
      errors.current_password = { message: error.current_password.join() };
    },
  })

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <FormProvider {...methods}>
      <form style={{width: '100%'}} onSubmit={(handleSubmit(user))}>
        <Flex direction="column" w="100%">
          <Grid
            templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
            gap="24px"
          >
            <FormControl isInvalid={errors.current_password}>
              <FormLabel
                color={textColor}
                fontWeight="bold"
                fontSize="xs"
              >
                Senha Atual
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  {...register('current_password')} 
                  type={showCurrentPassword ? 'text' : 'password'}
                  focusBorderColor={inputHover}
                  borderRadius="8px"
                  fontSize="md"
                />
                <InputRightElement width='4.5rem'>
                  <Button h='1.75rem' size='sm' onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                    { showCurrentPassword ? <Icon icon="eyeClose" /> : <Icon icon="view" /> }
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errors.current_password && <FormErrorMessage>{errors.current_password.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors.password}>
              <FormLabel
                color={textColor}
                fontWeight="bold"
                fontSize="xs"
              >
                Nova Senha
              </FormLabel>
                <InputGroup size='md'>
                  <Input
                    {...register('password')} 
                    type={showNewPassword ? 'text' : 'password'}
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => setShowNewPassword(!showNewPassword)}>
                      { showNewPassword ? <Icon icon="eyeClose" /> : <Icon icon="view" /> }
                    </Button>
                  </InputRightElement>
                </InputGroup>
              {errors.password && <FormErrorMessage>{errors.password.message}</FormErrorMessage>}
            </FormControl>
            <FormControl isInvalid={errors.password_confirmation}>
              <FormLabel
                color={textColor}
                fontWeight="bold"
                fontSize="xs"
              >
                Confirmar Nova Senha
              </FormLabel>
                <InputGroup size='md'>
                  <Input
                    {...register('password_confirmation')} 
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    focusBorderColor={inputHover}
                    borderRadius="8px"
                    fontSize="md"
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}>
                      { showConfirmNewPassword ? <Icon icon="eyeClose" /> : <Icon icon="view" /> }
                    </Button>
                  </InputRightElement>
                </InputGroup>
              {errors.password_confirmation && <FormErrorMessage>{errors.password_confirmation.message}</FormErrorMessage>}
            </FormControl>
          </Grid>
          <Flex mt="24px" align={"center"} justify={"flex-end"}>
            <Button
              isLoading={isLoadingUpdate}
              type='submit'
              variant="no-hover"
              bg={buttonBg}
              alignSelf="flex-end"
              w="100px"
              h="35px"
            >
              <Text fontSize="xs" color="#fff" fontWeight="bold">
                Alterar
              </Text>
            </Button>
          </Flex>
        </Flex>
      </form>
    </FormProvider>
  );
}

export default EditPassword;
