import { API } from './api'

const TICKETS_ROUTE = "/tickets";

export async function GET_TICKETS() {
	const { data } = await API.get(`${TICKETS_ROUTE}`);

	return data;
}

export async function GET_TICKET(id) {
	const { data } = await API.get(`${TICKETS_ROUTE}/${id}`);

	return data;
}

export async function POST_TICKET(payload) {
	const { data } = await API.post(`${TICKETS_ROUTE}`, payload);

	return data;
}

export async function UPDATE_TICKET(id, payload) {
	const { data } = await API.put(`${TICKETS_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_TICKET(id) {
	const { data } = await API.delete(`${TICKETS_ROUTE}/${id}`);

	return data;
}