import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
    GET_REVERSES,
    GET_REVERSE,
    POST_REVERSE,
    UPDATE_REVERSE,
    DELETE_REVERSE
} from 'services/reverses'

const ACTION_TYPES = {
    LIST: 'LIST_REVERSES',
    VIEW: 'VIEW_REVERSE',
    ADD: 'ADD_REVERSE',
    UPDATE: 'UPDATE_REVERSE',
    REMOVE: 'REMOVE_REVERSE',
}

const reversesReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				reverses: action.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				reverses: [ ...state.reverses, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				reverse: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				reverses: state.reverses.length > 0 ? state.reverses.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				reverses: state.reverses.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const ReversesStateContext = createContext()
const ReversesDispatchContext = createContext()

const ReversesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reversesReducer, { reverses: [] })

		const [toDelete, setToDelete] = React.useState(null)
		const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  
    const { isLoading, isFetching } = useQuery(['reverses'], () => GET_REVERSES(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
  
    return (
			<ReversesStateContext.Provider value={{ isLoading, isFetching, ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen }}>
					<ReversesDispatchContext.Provider value={dispatch}>{children}</ReversesDispatchContext.Provider>
			</ReversesStateContext.Provider>
    )
}

const useReverses = () => {
	const context = useContext(ReversesStateContext)

	if (!context)
		throw new Error('useReverses must be used within an ReversesProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(ReversesDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a ReversesProvider')

	return dispatch
}

const useCreateReverse = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_REVERSE(data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useReverse = (id, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['reverse', id], () => GET_REVERSE(id), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useUpdateReverse = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => UPDATE_REVERSE(id, data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteReverse = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_REVERSE(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	ReversesProvider,
	useReverses,
	useCreateReverse,
	useReverse,
	useUpdateReverse,
	useDeleteReverse
}
