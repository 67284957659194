import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import {border, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {
    arc,
    arcLabelsTextColor,
    arcLinkLabelsColor,
    legendsDark,
    legendsLight,
    margin,
    pieProps
} from "variables/nivo/halfPie";

import {riskDoubleColors} from "variables/colors";
import Loading from "../../../Loading/Loading";
import {useEpidemicDash} from "../../../../hooks/epidemicDash";

const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const DiabetesChart = () => {
    const { isLoadingGetAllEpis: isLoading, dash_val: data } = useEpidemicDash();
    
    const { colorMode } = useColorMode();

    if (!!data && !!data.get_all_epis && !!data.get_all_epis.diabetes && !isLoading) {
        return (
            <ResponsivePie
                data={data.get_all_epis.diabetes}
                margin={margin}
                colors={riskDoubleColors}
                theme={colorMode === "light" ? themeLight : themeDark}
                {...border}
                {...arc}
                arcLinkLabelsColor={arcLinkLabelsColor}
                arcLabelsTextColor={arcLabelsTextColor}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.get_all_epis.diabetes), datum.color)}
                {...pieProps}
                innerRadius={0.7}
            />
        )
    }

    return <Loading/>
};

export default DiabetesChart
