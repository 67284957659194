import React from 'react'

import { Flex, Text } from "@chakra-ui/react";

import { useUnits } from 'hooks/units'

import BasicTable from '../BasicTable'
import Loading from 'components/Loading/Loading'

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Nome",
    accessor: "name",
  },
  {
    Header: "CNPJ",
    accessor: "cnpj",
  },
  {
    Header: "Telefone",
    accessor: "phone",
  },
  {
    Header: "UF",
    accessor: "state_id",
  },
  {
    Header: "Cidade",
    accessor: "city_id",
  },
]

const UnitsTable = () => {
  const { isLoading, isFetching, units, setToDelete, setDeleteModalOpen } = useUnits()

  const deleteUser = (id) => {
    setToDelete(id),
    setDeleteModalOpen(true)
  }

  if(isLoading || isFetching)
    return (
      <>
        { 
          <BasicTable 
            isLoading={true} 
            tableData={[{},{},{},{},{},{},{},{}]} 
            columnsData={columns} 
            route="unidades" 
            removeAction={deleteUser} 
          /> 
        }
      </>
    )

  if(units.length === 0)
    return (
      <Flex
        justifyContent="center"  
        alignItems="center"
        w="100%"
        h="100%"
        pl="22px"
        pr="22px"
      >
        <Text>
          Não foram encontradas unidades cadastradas
        </Text>
      </Flex>
    )

  return (
    <BasicTable 
      tableData={units} 
      columnsData={columns} 
      route="unidades"
      removeAction={deleteUser} 
    />
		
  )
}

export default UnitsTable