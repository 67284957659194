import { Box, Flex, Grid, Image, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import {useColorMode} from "@chakra-ui/react";

const CustomerKitResult = ({ results }) => {
  const {colorMode} = useColorMode();
  const textColor = (colorMode == 'light') ? 'black' : 'white';
  console.log(results);

  return (
    <Flex w="100%" direction="column" mb="20px">
        <Text
        color={textColor}
        fontSize="lg"
        fontWeight="bold"
        mb="10px"
        >
          Resultado Exame
        </Text>
        <VStack fontSize="20px" align="stretch" spacing="2.5">
          <Text >Colesterol Total: {results.colesterol_total}</Text>
          <Text>Colesterol HDL: {results.hdl}</Text>
          <Text>Colesterol LDL: {results.ldl}</Text>
          <Text>Glicose: {results.glicose}</Text>
          <Text>Hemoglobina HBA1C: {results.hba1c}</Text>
        </VStack>
    </Flex>
  );
};

export default CustomerKitResult;
