import React from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
	faPenToSquare,
	faTrashCan,
	faFilter,
	faAngleDown,
	faAngleUp,
	faSignOut,
	faCircleInfo,
	faEyeSlash,
	faArrowLeft
} from '@fortawesome/free-solid-svg-icons'
import { 
	faEye
} from '@fortawesome/free-regular-svg-icons'

import styles from './Icon.module.css'

const Icon = ({ icon, margin = 0, color = "7180961", pointer = false}) => {
	const icons = {
		view: faEye,
		edit: faPenToSquare,
		delete: faTrashCan,
		filter: faFilter,
		expand: faAngleDown,
		shrink: faAngleUp,
		logout: faSignOut,
		info: faCircleInfo,
		eyeClose: faEyeSlash,
		back: faArrowLeft
	};

	return (
		<div className={styles.icon} style={{marginRight: margin + 'px', cursor: pointer && 'pointer'}}>
			<FontAwesomeIcon icon={icons[icon]} />
		</div>
	)
}

export default Icon