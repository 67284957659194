import Card from "../Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { Text, Flex, Box, VStack, useColorModeValue } from "@chakra-ui/react";
import dateFormat from "functions/dateFormat";

export default ({ risks }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card>
      <CardHeader mb="22px">
        <Flex justifyContent="center" alignItems="center">
          <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="3px"
              >
              Riscos e Fatores
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <VStack overflowY="auto" alignItems="flex-start" px="22px">
          {risks &&
            risks.map((risk) => {
              if (
                risk.risk_name == "Risco de desenvolver Osteoporose" ||
                risk.risk_name == "Risco de Lesão Muscular"
              )
                return;

              let labelColor = "#757575";
              switch (risk.label) {
                case "Não detectado":
                case "Baixo":
                  labelColor = "#3E993C";
                  break;
                case "Médio baixo":
                case "Médio":
                  labelColor = "#E0A51B";
                  break;
                case "Médio alto":
                  labelColor = "#D97325";
                  break;
                case "Alto":
                case "Detectado":
                  labelColor = "#CB4C33";
                  break;
                default:
                  break;
              }

              return (
                <Box>
                  <Flex>
                    <Text>{risk.risk_name} - &nbsp;</Text>
                    <Text color={labelColor}>{risk.label}</Text>
                  </Flex>
                  <Text fontSize="12px" color="#757575">
                    {risk?.metrification_date ? dateFormat(risk.metrification_date) : null}
                  </Text>
                </Box>
              );
            })}
        </VStack>
      </CardBody>
    </Card>
  );
};
