import React, {useEffect} from "react";

import {Box, Grid, Flex, Text, useColorModeValue} from "@chakra-ui/react";

import { useUpdateFilters } from "hooks/dashFilter";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import FiltersBox from "components/Filters/FiltersBox";

import KitTotalChart from "components/Charts/Nivo/Epidemiological/KitTotalChart";
import DiseasesChart from "components/Charts/Nivo/Epidemiological/DiseasesChart";
import DiabetesChart from "components/Charts/Nivo/Epidemiological/DiabetesChart";
import HypertensionChart from "components/Charts/Nivo/Epidemiological/HypertensionChart";
import HeartDieseaseChart from "components/Charts/Nivo/Epidemiological/HeartDieseaseChart";
import FamiliyDiseasesChart from "components/Charts/Nivo/Epidemiological/FamiliyDiseasesChart";
import RisksChart from "components/Charts/Nivo/Epidemiological/RisksChart";
import PreliminaryFactorsChart from "components/Charts/Nivo/Epidemiological/PreliminaryFactorsChart";

import { queryBuilder } from "functions/queryBuilder";

function EpidemiologicalDashboard() {
    const [filters, setFilters] = React.useState([]);
    const textColor = useColorModeValue("gray.700", "white");
    const {mutateAsync: updateFilters} = useUpdateFilters();

    const kitSelected = filters.find(s => s.includes('kit_id='));

    useEffect(() => {
        const query = queryBuilder(filters);
        updateFilters(query)
    }, [filters]);

    return (
        <>
            <Grid
                templateColumns={{sm: "1fr", md: "1fr"}}
                templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                gap="12px"
                pt={{sm: "125px", lg: "160px"}}
            >
                <Card px="0px" pb="0px" minHeight="100px">
                    <CardHeader mb="34px" px="22px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">
                            Filtros
                        </Text>
                    </CardHeader>
                    <CardBody h="100%">
                        <Box w="100%" h="100%">
                            <FiltersBox
                                setFilters={setFilters}
                                regionsFilter
                                statesFilter
                                citiesFilter
                                kitsFilter
                                unitsFilter
                                dateFilter
                                reportDownload
                            />
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
            <Box hidden={kitSelected}>
                <Grid
                    templateColumns={{sm: "1fr", md: "1fr"}}
                    templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                    gap="12px"
                    pt="12px"
                >
                    <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                        <CardBody h="100%">
                            <Box w="100%" h="100%">
                            <Flex
                                justifyContent="center"  
                                alignItems="center"
                                w="100%"
                                h="100%"
                                pl="22px"
                                pr="22px"
                            >
                                <Flex>
                                    Selecione o <Text margin={"0 5px"} fontWeight={"bold"}>tipo</Text> de Check-up no filtro superior, para exibir os seus indicadores
                                </Flex>
                            </Flex>
                            </Box>
                        </CardBody>
                    </Card>
                </Grid>
            </Box>
            <Box hidden={!kitSelected}>
                <Grid
                    templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                    templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                    gap="12px"
                    pt="12px"
                >
                    <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                        <CardHeader mb="34px" px="22px">
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                Total
                            </Text>
                        </CardHeader>
                        <CardBody h="100%">
                            <Box w="100%" h="100%">
                                <KitTotalChart/>
                            </Box>
                        </CardBody>
                    </Card>
                    <Card px="0px" pb="0px" minHeight="320px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
                        <CardHeader mb="34px" px="22px">
                            <Text color={textColor} fontSize="lg" fontWeight="bold">
                                Diabetes
                            </Text>
                        </CardHeader>
                        <CardBody h="100%">
                            <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                <DiabetesChart />
                            </Box>
                        </CardBody>
                    </Card>
                        <Card px="0px" pb="0px" minHeight="320px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Hipertensão
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <HypertensionChart />
                                </Box>
                            </CardBody>
                        </Card>
                        <Card px="0px" pb="0px" minHeight="320px" maxHeight="320px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Doenças do Coração
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <HeartDieseaseChart />
                                </Box>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(3, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt="12px"
                    >
                    </Grid>
                    <Grid
                        templateColumns={{sm: "1fr", md: "1fr"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt="12px"
                    >
                        <Card px="0px" pb="0px" minHeight="320px" maxHeight="350px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Doenças Autorreferidas
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <DiseasesChart />
                                </Box>
                            </CardBody>
                        </Card>
                        <Card px="0px" pb="0px" minHeight="320px" height="400px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Histórico Familiar de Doenças Crônicas
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <FamiliyDiseasesChart />
                                </Box>
                            </CardBody>
                        </Card>
                    </Grid>
                    <Box w="100%" mt="24px" mb="20px">
                        <Text color={textColor} fontSize="lg" fontWeight="bold">Medicina preditiva</Text>
                    </Box>
                    <Grid
                        templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                        templateRows={{sm: "repeat(1, 1fr)", md: "repeat(1, 1fr)"}}
                        gap="12px"
                        pt="12px"
                    >
                        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Riscos
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <RisksChart />
                                </Box>
                            </CardBody>
                        </Card>
                        <Card px="0px" pb="0px" minHeight="500px" maxHeight="500px" overflowX={{sm: "auto", lg: "unset"}}>
                            <CardHeader mb="34px" px="22px">
                                <Text color={textColor} fontSize="lg" fontWeight="bold">
                                    Fatores Preliminares
                                </Text>
                            </CardHeader>
                            <CardBody h="100%">
                                <Box w="100%" h="100%" minW={{lg: 'auto', sm: 700}}>
                                    <PreliminaryFactorsChart/>
                                </Box>
                            </CardBody>
                        </Card>
                    </Grid>
            </Box>
        </>
    );
}

export default EpidemiologicalDashboard;
