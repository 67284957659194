import React from 'react'
import { createContext, useContext, useReducer } from 'react'
import { useQuery, useMutation } from 'react-query'
import { isEmpty } from 'lodash'

import {
    GET_ORDERS,
    GET_ORDER,
    POST_ORDER,
    UPDATE_ORDER,
    DELETE_ORDER
} from 'services/orders'

const ACTION_TYPES = {
    LIST: 'LIST_ORDERS',
    VIEW: 'VIEW_ORDER',
    ADD: 'ADD_ORDER',
    UPDATE: 'UPDATE_ORDER',
    REMOVE: 'REMOVE_ORDER',
}

const ordersReducer = (state, action) => {
	switch (action.type) {
		case ACTION_TYPES.LIST: {
			return { 
				...state, 
				orders: action.data
			}
		}
		case ACTION_TYPES.ADD: {
			return { 
				...state, 
				orders: [ ...state.orders, action.data ] 
			}
		}
		case ACTION_TYPES.VIEW: {
			return { 
				...state, 
				order: action.data
			}
		}
		case ACTION_TYPES.UPDATE: {
			return {
				...state,
				orders: state.orders.length > 0 ? state.orders.map((item) => (item.id === action.data.id ? action.data : item)) : [],
			}
		}
		case ACTION_TYPES.REMOVE: {
			return { 
				...state, 
				orders: state.orders.filter(({ id }) => id !== action.id) 
			}
		}
		default: {
			return state
		}
	}
}

const OrdersStateContext = createContext()
const OrdersDispatchContext = createContext()

const OrdersProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ordersReducer, { orders: [] })

		const [toDelete, setToDelete] = React.useState(null)
		const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  
    const { isLoading, isFetching } = useQuery(['orders'], () => GET_ORDERS(), {
			onSuccess: (data) => {
				if (isEmpty(data)) {
					return dispatch({ type: ACTION_TYPES.LIST, data })
				}
				dispatch({ type: ACTION_TYPES.LIST, data })
			},
    })
  
    return (
			<OrdersStateContext.Provider value={{ isLoading, isFetching, ...state, toDelete, setToDelete, deleteModalOpen, setDeleteModalOpen }}>
					<OrdersDispatchContext.Provider value={dispatch}>{children}</OrdersDispatchContext.Provider>
			</OrdersStateContext.Provider>
    )
}

const useOrders = () => {
	const context = useContext(OrdersStateContext)

	if (!context)
		throw new Error('useOrders must be used within an OrdersProvider')

	return context
}
  
const useDispatch = () => {
	const dispatch = useContext(OrdersDispatchContext)

	if (dispatch === undefined)
			throw new Error('useDispatch must be used within a OrdersProvider')

	return dispatch
}

const useCreateOrder = (options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => POST_ORDER(data), {
			...options,
			onSuccess: (data) => {
					onSuccess(data)
					dispatch({ type: ACTION_TYPES.ADD, data })
			},
	})
}

const useOrder = (id, options = {}) => {
	const dispatch = useDispatch()

	return useQuery(['order'], () => GET_ORDER(id), {
			...options,
			onSuccess: (data) => {
				dispatch({ type: ACTION_TYPES.VIEW, data })
			},
	})
}

const useUpdateOrder = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation((data) => UPDATE_ORDER(id, data), {
			...options,
			onSuccess: (data) => {
				onSuccess(data)
				dispatch({ type: ACTION_TYPES.UPDATE, data })
			},
	})
}

const useDeleteOrder = (id, options = {}) => {
	const dispatch = useDispatch()
	const { onSuccess } = options

	return useMutation(() => DELETE_ORDER(id), {
			...options,
			onSuccess: () => {
				onSuccess()
				dispatch({ type: ACTION_TYPES.REMOVE, id })
			},
	})
}

export {
	OrdersProvider,
	useOrders,
	useCreateOrder,
	useOrder,
	useUpdateOrder,
	useDeleteOrder
}
  
