import { API } from './api'

const USERS_ROUTE = "/users";

export async function GET_USERS() {
	const { data } = await API.get(`${USERS_ROUTE}`);

	return data;
}

export async function GET_USER(id) {
	const { data } = await API.get(`${USERS_ROUTE}/${id}`);

	return data;
}

export async function POST_USER(payload) {
	const { data } = await API.post(`${USERS_ROUTE}`, payload);

	return data;
}

export async function UPDATE_USER(id, payload) {
	const { data } = await API.put(`${USERS_ROUTE}/${id}`, payload);

	return data;
}

export async function DELETE_USER(id) {
	const { data } = await API.delete(`${USERS_ROUTE}/${id}`);

	return data;
}
