import { API } from 'services/api'

const DASHBOARDS_ROUTE = "/dashboard";

export async function KIT_STOCK(filters = '') {
	const { data } = await API.get(`${DASHBOARDS_ROUTE}/kit_stock${filters}`);

	return data;
}

export async function KIT_EVALUATIONS(filters = '') {
	const { data } = await API.get(`${DASHBOARDS_ROUTE}/kit_evaluations${filters}`);

	return data;
}

export async function KIT_STATUS(filters = '') {
	const { data } = await API.get(`${DASHBOARDS_ROUTE}/kit_status${filters}`);

	return data;
}