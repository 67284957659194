import React from "react";
import {UnitsProvider} from "../hooks/units";
import {FilterProvider} from "../hooks/dashFilter";
import {EpidemicDashProvider} from "hooks/epidemicDash";

const EpidemicFilteredProvider = ({children}) => {
    return (
        <UnitsProvider>
            <FilterProvider>
                <EpidemicDashProvider>
                    {children}
                </EpidemicDashProvider>
            </FilterProvider>
        </UnitsProvider>
    )
};

export default EpidemicFilteredProvider;
