import Card from "../Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {useForm} from "react-hook-form";
import {
    Button, Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Skeleton,
    Text,
    Textarea,
    useColorModeValue,
    Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import React from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import { GET_URL_DOC_CARE_PLAN } from "services/clients";


const validations = Object.assign({
    result_exams: yup
        .string()
        .required("Campo obrigatório"),
    health_orientation: yup
        .string()
        .required("Campo obrigatório"),
    forwarding_medication: yup
        .string()
        .required("Campo obrigatório"),
});

const schema = yup.object().shape(validations)

export default ({isLoading, onSubmit = (data) => console.log, carePlan = null, getDate, documents}) => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: carePlan,
        resolver: yupResolver(schema),
    })
    const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
    const textColor = useColorModeValue("gray.700", "white");
    const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
    const buttonColor = useColorModeValue("white", "healthlab.900");
    const showDocument = async () => {
        const ret = await GET_URL_DOC_CARE_PLAN(documents[0].id)
        if (ret.link) window.open(ret.link, "blank")
        console.log(ret)
    }

    return (
        <Card>
            <CardHeader mb="22px">
                <Flex
                    justifyContent="center"
                    alignItems="center"
                >
                    <Text
                        color={textColor}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                        >
                        Plano de cuidados
                    </Text>
                </Flex>
            </CardHeader>
            <CardBody px="32px" pb="22px">
                <form style={{width: 100 + '%'}} onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={errors.result_exams} marginTop={"20px"}>
                        <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                        >
                            Resultados dos Exames
                        </FormLabel>
                        {
                            isLoading ?
                                <Skeleton height={"40px"} />
                                :
                                <Textarea
                                    {...register('result_exams')}
                                    defaultValue={carePlan?.result_exams || null}
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                />
                        }
                        {errors.result_exams && <FormErrorMessage>{errors.result_exams.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.health_orientation} marginTop={"20px"}>
                        <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                        >
                            Orientações de saúde
                        </FormLabel>
                        {
                            isLoading ?
                                <Skeleton height={"40px"} />
                                :
                                <Textarea
                                    {...register('health_orientation')}
                                    defaultValue={carePlan?.health_orientation || null}
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                />
                        }
                        {errors.health_orientation && <FormErrorMessage>{errors.health_orientation.message}</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={errors.forwarding_medication} marginTop={"20px"}>
                        <FormLabel
                            color={textColor}
                            fontWeight="bold"
                            fontSize="xs"
                        >
                            Encaminhamentos/medicações
                        </FormLabel>
                        {
                            isLoading ?
                                <Skeleton height={"40px"} />
                                :
                                <Textarea
                                    {...register('forwarding_medication')}
                                    defaultValue={carePlan?.forwarding_medication || null}
                                    focusBorderColor={inputHover}
                                    borderRadius="8px"
                                    fontSize="md"
                                />
                        }
                        {errors.forwarding_medication && <FormErrorMessage>{errors.forwarding_medication.message}</FormErrorMessage>}

                    { !!carePlan?.documents ?
                        <Text mt="12px" fontSize="12px" color="#757575" textAlign="end">Último plano de cuidados gerado em: {carePlan?.documents[0]?.updated_at ? getDate(carePlan.documents[0].updated_at) : null} </Text> :
                        null
                    }
                    </FormControl>

                    <Flex mt="24px" align="center" justify="flex-end">
                    { !!carePlan?.documents ?
                            <Button
                                isLoading={isLoading}
                                _hover={{cursor: 'pointer'}} 
                                onClick={() => showDocument()}
                                fontSize="xs" 
                                color={buttonColor}
                                fontWeight="bold"
                                variant="no-hover"
                                bg={textColor}
                                alignSelf="flex-end"
                                w="150px"
                                h="35px"
                                mr="10px"
                                disabled = {!carePlan.documents || isLoading}
                            >
                                Ver <ExternalLinkIcon mx='2px' />
                            </Button> :
                        null

                    }


                        {/* <Button
                            isLoading={isLoading}
                            variant="no-hover"
                            bg={textColor}
                            alignSelf="flex-end"
                            w="150px"
                            h="35px"
                            mr="10px"
                            onClick={() => {reset({result_exams: '', health_orientation: '', forwarding_medication: ''})}}
                        >
                            <Text fontSize="xs" color="#fff" fontWeight="bold">
                                Limpar
                            </Text>
                        </Button> */}

                        <Button
                            isLoading={isLoading}
                            type='submit'
                            variant="no-hover"
                            bg={buttonBg}
                            alignSelf="flex-end"
                            w="150px"
                            h="35px"
                        >
                            <Text fontSize="xs" color="#FFF" fontWeight="bold">
                                Gerar plano
                            </Text>
                        </Button>

                    </Flex>
                </form>
            </CardBody>
        </Card>
    )
}
