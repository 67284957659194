import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {risksColorsPlus} from "variables/colors";
import Loading from "components/Loading/Loading";
import {useDemographicDash} from "hooks/demographicDash";
import {axisBottom, axisLeft, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";

const colorBy = (key, id) => {
    if (id === "Moderadamente" && key === "Consumo de Gordura Animal") return "#22b357";
    if (id === "Moderadamente " && key === "Consumo de Álcool") return "#22b357";

    switch (id) {
        case "Nenhuma porção":
            return "#CB4333";
        case "Uma porção":
            return "#DF7735";
        case "Duas porções":
            return "#FCB239";
        case "Três ou quatro porções":
            return "#22b357";
        case "Cinco ou mais porções":
            return "#57CF6D";

        case "Nunca":
        case "Nunca ":
            return "#57CF6D";
        case "Frequentemente":
            return "#FCB239";
        case "Quase Sempre":
            return "#DF7735";
        case "Raramente":
            return "#CB4333";

        case "Menos de três doses por semana":
            return "#FCB239";
        case "Três a seis doses por semana":
            return "#DF7735";
        case "Sete ou mais doses por semana":
            return "#CB4333";

        case "Nenhuma vez por semana":
            return "#CB4333";
        case "Menos de três vezes por semana":
            return "#DF7735";
        case "Três vezes por semana":
            return "#FCB239";
        case "Quatro vezes por semana":
            return "#22b357";
        case "Quase sempre":
            return "#57CF6D";

        case "Não se aplica":
            return "#57CF6D";
        case "Nenhuma vez":
            return "#22b357";
        case "Uma vez":
            return "#FCB239";
        case "Duas ou três vezes":
            return "#DF7735";
        case "Mais do que três":
            return "#CB4333";

        default:
            return "#000";
    }
};

const LifeHabitsChart = () => {
    const {isLoadingGetEatGreens: isLoadingGetEatGreens, isLoadingGetEatMeat: isLoadingGetEatMeat, isLoadingGetDrink: isLoadingGetDrink, isLoadingGetActivity: isLoadingGetActivity, dash_val: data} = useDemographicDash();
    const {colorMode} = useColorMode();

    if (!isLoadingGetEatGreens && !isLoadingGetEatMeat && !isLoadingGetDrink && !isLoadingGetActivity && !!data && !!data.get_eat_greens && !!data.get_eat_meat && !!data.get_drink && !!data.get_activity) {
        const ret = [data.get_eat_greens, data.get_eat_meat, data.get_drink, data.get_activity];

        return (
            <ResponsiveBar
                data={ret}
                keys={[
                    "Quase sempre",
                    "Quatro vezes por semana",
                    "Três vezes por semana",
                    "Menos de três vezes por semana",
                    "Nenhuma vez por semana",

                    "Nunca",
                    "Raramente",
                    "Menos de três doses por semana",
                    "Três a seis doses por semana",
                    "Sete ou mais doses por semana",

                    "Sou Vegetariano ou Vegano",
                    "Nunca ",
                    "Raramente ",
                    "Moderadamente ",
                    "Quase sempre ",

                    "Cinco ou mais porções",
                    "Três ou quatro porções",
                    "Duas porções",
                    "Uma porção",
                    "Nenhuma porção",
                ]}
                indexBy="status"
                margin={{top: 10, right: 20, bottom: 35, left: 200}}
                layout="horizontal"
                groupMode="stacked"
                padding={0.3}
                colors={risksColorsPlus}
                // colorBy={({indexValue, id}) => colorBy(indexValue, id)}
                theme={colorMode === "light" ? themeLight : themeDark}
                labelSkipWidth={11}
                axisBottom={axisBottom}
                axisLeft={axisLeft}
                labelTextColor={labelTextColor}
                tooltip={({id, value}) => tooltip(id, value)}
            />
        )
    }
    return <Loading/>;
};

export default LifeHabitsChart
