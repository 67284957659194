import {API} from "services/api"

const DASHBOARDS_ROUTE = "/dashboard";

export async function GET_ALL_EPIS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_all_epis${filters}`);
    return data;
}

export async function GET_ALL_RISKS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_all_risks${filters}`);
    return data;
}

export async function GET_ALL_FACTORS(filters = "") {
    const {data} = await API.get(`${DASHBOARDS_ROUTE}/get_all_factors${filters}`);
    return data;
}
