

import React from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useReverse, useUpdateReverse } from "hooks/reverses";

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Skeleton,
  Grid,
  Input,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {useUnits} from "../../../hooks/units";

const schema = yup.object().shape({
    unit: yup
      .string()
      .required("O campo Unidade é obrigatório"),
    quantity: yup
      .number()
      .integer("Insira um valor numérico")
      .required("O campo é obrigatório"),
})

const EditReverse = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useReverse(id);

  const methods = useForm();
  const toast = useToast();
  const { units } = useUnits();
  const history = useHistory();

  const [unit, setUnit] = React.useState(0);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
      mode: "onBlur",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if(data && data.partner_place_id) {
      setUnit(data.partner_place_id)
      setValue('unit', data.partner_place_id)
    }
  }, [data])

  const { mutateAsync: order, isLoading: isLoadingUpdate } = useUpdateReverse(id, {
    onSuccess: () => {
      toast({
        title: 'Solicitação atualizada com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      }),
      history.push(`/sistema/solicitacoes/coletas/${id}`);
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const buttonBg = useColorModeValue("healthlab.900", "healthlab.900");
  const inputHover = useColorModeValue("healthlab.800", "healthlab.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        pt={{ sm: "120px", lg: "160px" }}
      >
        <Card w="50%">
          <CardHeader mb="40px">
            <Flex direction="column">
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                mb="3px"
              >
                Editar Coleta #{id}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            <FormProvider {...methods}>
              <form style={{width: '100%'}} onSubmit={(handleSubmit(order))}>
                <Flex direction="column" w="100%">
                  <Grid
                    templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                    templateRows={{ md: "repeat(2, 1fr)" }}
                    gap="24px"
                  >
                    <Grid
                        templateColumns={{ sm: "1fr", md: "repeat(1, 1fr)" }}
                        templateRows={{ md: "repeat(1, 1fr)" }}
                        gap="24px"
                      >
                      <FormControl isInvalid={errors.unit}>
                        <FormLabel fontSize="xs" fontWeight="bold" mb="10px">
                          Unidade
                        </FormLabel>
                        {
                          isLoading ?
                          <Skeleton height={"40px"} />
                          :
                          <Select
                            {...register('unit')} 
                            defaultValue={data?.partner_place_id || null}
                            value={unit}
                            focusBorderColor={inputHover}
                            borderRadius="8px"
                            fontSize="md"
                            placeholder="Selecione uma opção..."
                            color="gray.400"
                            onChange={({target}) => setUnit(target.value)}
                          >
                            {units.map(i => (
                                <option value={i.id}>{i.name}</option>
                            ))}
                          </Select>
                        }
                        {errors.unit && <FormErrorMessage>{errors.unit.message}</FormErrorMessage>}
                      </FormControl>
                    </Grid>
                    <FormControl isInvalid={errors.quantity}>
                      <FormLabel
                        color={textColor}
                        fontWeight="bold"
                        fontSize="xs"
                      >
                        Quantidade de amostrar de sangue
                      </FormLabel>
                      {
                        isLoading ?
                        <Skeleton height={"40px"} />
                        :
                        <Input
                          {...register('quantity')} 
                          defaultValue={data?.blood_sample || null}
                          focusBorderColor={inputHover}
                          borderRadius="8px"
                          fontSize="md"
                        />
                      }
                      {errors.quantity && <FormErrorMessage>{errors.quantity.message}</FormErrorMessage>}
                    </FormControl>
                  </Grid>
                  <Flex mt="24px" align={"center"} justify={"flex-end"}>
                    <NavLink to="/sistema/solicitacoes/coletas">
                      <Text mr="20px" fontSize="xs" color={textColor} fontWeight="bold">
                        Cancelar
                      </Text>
                    </NavLink>
                    <Button
                      isLoading={isLoadingUpdate}
                      type='submit'
                      variant="no-hover"
                      bg={buttonBg}
                      alignSelf="flex-end"
                      w="100px"
                      h="35px"
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        Atualizar
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
                  
              </form>
            </FormProvider>
          </CardBody>
        </Card>
      </Flex>
  );
}

export default EditReverse;
