import React, { useMemo } from "react";
import { Link, NavLink } from "react-router-dom";

import { Skeleton, useColorMode } from "@chakra-ui/react";
import {
  Badge,
  Button,
  Flex,
  Icon as ChakraIcon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { usePagination, useSortBy, useTable } from "react-table";

import Icon from "components/FontAwesomeIcons/Icon";

import dateFormat from "functions/dateFormat";
import states from "variables/states";
import cities from "variables/cities";
import statusTranslate from "functions/statusTranslate";

const getStatusName = (status) => {
  switch (status) {
    case 'Open':
      return 'Aberto'
    case 'Closed':
      return 'Fechado'
    case 'Cancelled':
      return 'Cancelado'
    case 'Lost':
      return 'Perdido'
    case 'Dispatched':
      return 'Enviado'
    default:
      return status;
  }
}

const TableActions = ({id, route, removeAction = null, noView = false, noEdit = false, noDelete = false}) => {
	const { colorMode } = useColorMode();
  const color = (colorMode === 'light') ? "#7180961'" : "#718096"
  const hoverColor = "healthlab.800"

  return (
    <>
      {
      !noView &&
      <Text fontWeight="bold" fontSize="sm" mr={4} color={color} _hover={{ color: hoverColor }}>
        <NavLink to={`${route}/${id}`}>
            <Icon icon="view" pointer />
        </NavLink>
      </Text>
      }
      {
        !noEdit &&
        <Text fontWeight="bold" fontSize="sm" mr={4} color={color} _hover={{ color: hoverColor }}>
          <NavLink to={`${route}/${id}/editar`}>
              <Icon icon="edit" pointer />
          </NavLink>
        </Text>
      }
      {
        !noDelete && 
        <Text fontWeight="bold" fontSize="sm" color={color} _hover={{ color: hoverColor }} onClick={() => removeAction(id)} >
          <Icon icon="delete" pointer  />
        </Text>
      }
    </>
  )
}

const getBadge = (value) => {
  let color;
  const lowerValue = value ? value.toLowerCase() : value;
  switch (lowerValue) {
    case 'aberto':
    case 'open':
      color = 'yellow';
      break;
    case 'em andamento':
      color = 'blue';
      break;
    case 'finalizado':
    case 'finished':
    case 'Finished':
    case 'dispatched':
      color = 'green';
      break;
    case 'cancelado':
    case 'cancelled':
      color = 'red';
      break;
    case 'baixa':
      color = 'green';
      break;
    case 'normal':
      color = 'yellow';
      break;
    case 'alta':
      color = 'orange';
      break;
    case 'urgente':
      color = 'red';
      break;
    default:
      color = 'gray';
      break;
  }

  return color;
}

function BasicTable(props) {
  const { columnsData, tableData, actionsData, route, removeAction, isLoading = false, noView = false, noEdit = false, noDelete = false } = props;
  const columns = columnsData
  const data = tableData
  const actions = actionsData

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,

    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Stack
          direction="row"
          spacing="12px"
          align="center"
          my="24px"
          px="22px"
        >
          <Select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            color="gray.500"
            size="sm"
            borderRadius="12px"
            maxW="75px"
            cursor="pointer"
          >
            <option>5</option>
            <option>10</option>
            <option>15</option>
            <option>20</option>
            <option>25</option>
          </Select>
          <Text fontSize="xs" color="gray.400" fontWeight="normal">
            linhas por páginas
          </Text>
        </Stack>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      <ChakraIcon
                        w={{ sm: "10px", md: "14px" }}
                        h={{ sm: "10px", md: "14px" }}
                        color={columns.isSorted ? "gray.500" : "gray.400"}
                        float="right"
                        as={
                          column.isSorted
                            ? column.isSortedDesc
                              ? TiArrowSortedDown
                              : TiArrowSortedUp
                            : TiArrowUnsorted
                        }
                      />
                    </Flex>
                  </Th>
                ))}
                <Th hidden={noView && noEdit && noDelete}>
                    <Flex
                      justify="flex-end"
                      align="right"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      Ações
                    </Flex>
                </Th>
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {
              page.map((row, index) => {
                prepareRow(row);

                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      // console.log(cell);
                      if(['created_at', 'updated_at'].includes(cell.column.id))
                        row.cells[index].row.values[cell.column.id] = dateFormat(cell.value)
                      if(cell.column.id == 'state_id' && !!cell.value){
                         const state = states.find(state => state.id == cell.value)
                         row.cells[index].row.values[cell.column.id] = state?.initials || cell.value
                      }
                      if(cell.column.id == 'city_id' && !!cell.value){
                         const city = cities.find(city => city.id == cell.value)
                         row.cells[index].row.values[cell.column.id] = city?.name || cell.value
                      }
                      if(cell.column.id == 'status' && !!cell.value){
                         const status = statusTranslate[cell.value]
                         row.cells[index].row.values[cell.column.id] = status || cell.value
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          fontSize={{ sm: "14px" }}
                          key={index}
                        >
                          <Skeleton height={"20px"} isLoaded={!isLoading}>
                            {
                              cell.column.id == 'priority' || cell.column.id == 'status' ?
                              <Badge variant='subtle' colorScheme={getBadge(cell.value)}>
                                { getStatusName(cell.render("Cell")) }
                              </Badge>
                              :
                              cell.column.isLink ?
                                <Link to={`${route}/${row.original.id}`}>{cell.render("Cell")}</Link>
                              :
                              cell.render("Cell")
                            }
                          </Skeleton>
                        </Td>
                      );
                    })}
                    {
                      !(noView && noEdit && noDelete) &&
                      <Td>
                        <Flex
                          justify="flex-end"
                          align="right"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          { !isLoading && 
                            <TableActions 
                              id={row.original.id} 
                              route={route} 
                              removeAction={removeAction}
                              noView={noView}
                              noEdit={noEdit}
                              noDelete={noDelete} 
                            /> }
                        </Flex>
                      </Td>
                    }
                  </Tr>
                );
              })
            }
          </Tbody>
        </Table>
        <Flex
          direction={{ sm: "column", md: "row" }}
          w="100%"
          justify="space-between"
          align="center"
          px={{ md: "22px" }}
        >
          <Text
            fontSize="sm"
            color="gray.500"
            fontWeight="normal"
            mb={{ sm: "24px", md: "0px" }}
          >
            Mostrando {pageSize * pageIndex + 1} à {" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            do total de {tableData.length} resultados
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-hover"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="#fff"
              border="1px solid lightgray"
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <ChakraIcon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-hover"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="160px"
                    bg={pageNumber === pageIndex + 1 ? "healthlab.900" : "#fff"}
                    border="1px solid lightgray"
                    key={index}
                  >
                    <Text
                      fontSize="sm"
                      color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-hover"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="160px"
              bg="#fff"
              border="1px solid lightgray"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <ChakraIcon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default BasicTable;
