
import { useHistory, useLocation } from 'react-router-dom'
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'

import { useLogin } from 'hooks/auth';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import basic from "assets/img/basic-auth.png";
import greenBg from "assets/img/backgroundcard.png";
import React from "react";
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { useForgotPassword } from 'hooks/auth';

const schema = yup.object().shape({
    email: yup
      .string()
      .required("Insira seu e-mail"),
})

const ForgotPassword = () => {
  const methods = useForm();
	const [sent, setSent] = React.useState(false);
  
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
      mode: "onBlur",
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
  });
  
  const { isLoading, mutateAsync: data } = useForgotPassword({
    onSuccess: () => setSent(true),
    onError: () => setError("login", { message: "Não foi possível realizar login com as credenciais informadas." })
  })

  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
    >
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ sm: "0px", md: "50px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={greenBg}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex
        direction="column"
        textAlign="center"
        justifyContent="center"
        align="center"
        mt="6.5rem"
        mb="30px"
      >
        <Text fontSize="4xl" color="white" fontWeight="bold">
					Health ID
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px" 
						mr={{ sm: "20px" }}
						ml={{ sm: "20px" }}>
			<FormProvider {...methods}>
        <form onSubmit={(handleSubmit(data))}>
					<Flex
						direction="column"
						w={{md: "445px", sm: "100%"}}
						background="transparent"
						borderRadius="15px"
						p="40px"
						bg={bgColor}
						boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
					>
						{
							!sent ?
							<>
								<Text mb="0.5rem">
									Preencha o seu e-mail para que possamos enviar sua recuperação de senha.
								</Text>
								<FormControl isInvalid={errors.email} mb="24px">
									<FormLabel ms="4px" fontSize="sm" fontWeight="normal">
										E-mail
									</FormLabel>
									<Input
										{...register('email')} 
										fontSize="sm"
										ms="4px"
										borderRadius="15px"
										type="text"
										placeholder="E-mail"
										mb="5px"
										size="lg"
									/>
									{errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
								</FormControl>
								<FormControl isInvalid={errors.password} mb="24px">
									<Button
										isLoading={isLoading}
										type="submit"
										bg="healthlab.900"
										fontSize="sm"
										color="white"
										fontWeight="bold"
										w="100%"
										h="45"
										_hover={{
											bg: "healthlab.800",
										}}
										_active={{
											bg: "healthlab.800",
										}}
									>
										Enviar
									</Button>
									{ errors.login && <Text color="red.300" mt="0.5rem">{errors.login.message}</Text> }
								</FormControl>
							</>
							:
							<Text mb="0.5rem">
								Foi enviado um e-mail contendo instruções para recuperar sua senha.
							</Text>
						}
					
					</Flex>
				</form>
			</FormProvider>
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
