import React from 'react'
import { getPercentage } from 'functions/percentage'

const style = {
	display: 'flex',
	alignItems: 'center',
  padding: 8,
	fontSize: 14,
	color: "#FFF",
	background: '#1a202cf5',
}

const square = {
	width: 10,
	height: 10,
	marginRight: 5,
	marginBottom: 2,
	borderWidth: 1,
	borderColor: '#FFF',
}

const ChartToolTip = ({ label, value = null, total = null, color = null }) => {
  return (
    <div style={style}>
		{
			color && <div style={{...square, backgroundColor: color}}></div>
		}
		<span>
			{label || null}{value && `: ${value}` || null} {total && `(${ getPercentage(value, total) }%)`}
		</span>
	</div>
  )
}

export default ChartToolTip