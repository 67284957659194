import {FilterProvider} from "hooks/dashFilter";
import {UnitsProvider} from "hooks/units";
import {DemographicDashProvider} from "hooks/demographicDash";

const DemographicFilteredProvider = ({children}) => {
    return (
        <UnitsProvider>
            <FilterProvider>
                <DemographicDashProvider>
                    {children}
                </DemographicDashProvider>
            </FilterProvider>
        </UnitsProvider>
    )
};

export default DemographicFilteredProvider;
