import React from "react";
import {ResponsivePie} from "@nivo/pie";
import {useColorMode} from "@chakra-ui/react";
import {risksColorsPlus} from "variables/colors";
import Loading from "components/Loading/Loading";
import {useDemographicDash} from "hooks/demographicDash";
import { useUpdateFilters } from "hooks/dashFilter";
import {border, themeDark, tooltip} from "variables/nivo/default";
import {
    arc,
    arcLabelsTextColor,
    arcLinkLabelsColor,
    legendsDark,
    legendsLight,
    margin,
    pieProps
} from "variables/nivo/pie";
import { useFilter } from "hooks/dashFilter";

const total = (data) => {
    let total = 0;
    data.map(item => {
        total += item.value;
    });

    return total;
};

const isApplied = (filters) => {
    if(!filters) return false;
    const params = filters.replace('?', '').split('&');

    return params.includes('kit_id=5');
}

const notApplyData = (data) => {
    return [
        { 
            id: 'nao-aplica',
            label: 'Não se aplica',
            value: total(data)
        }
    ]
}

const HospitalizationChart = () => {
    const { filters } = useFilter();
    const { isLoadingGetHospitalization: isLoading, dash_val: data } = useDemographicDash();

    const notApply = isApplied(filters);

    const { colorMode } = useColorMode();

    if (!isLoading && !!data && !!data.get_hospitalization) {
        return (
            <ResponsivePie
                data={!notApply ? data.get_hospitalization : notApplyData(data.get_controlled_medication)}
                margin={margin}
                colors={!notApply ? risksColorsPlus : ['#15ADA7']}
                theme={themeDark}
                {...border}
                {...arc}
                arcLinkLabelsColor={arcLinkLabelsColor}
                arcLabelsTextColor={arcLabelsTextColor}
                legends={colorMode === "light" ? legendsLight : legendsDark}
                tooltip={({datum}) => tooltip(datum.label, datum.value, total(data.get_hospitalization), datum.color)}
                {...pieProps}
                innerRadius={0.7}
            />
        )
    }
    return <Loading/>;
};

export default HospitalizationChart
