import React from "react";
import { NavLink, useParams } from "react-router-dom";

import { useReverse } from "hooks/reverses";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Grid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import {useUnits} from "../../../hooks/units";

const ViewReverse = () => {
  const { id } = useParams();
  const { isFetching: isLoading, data } = useReverse(id);
  const { units } = useUnits();

  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      minH="100vh"
      align="center"
      pt={{ sm: "120px", lg: "160px" }}
    >
      <Card w={{ md: "100%", lg: "50%" }}>
        <CardHeader mb="40px">
          <Flex direction="row" w="100%" justify="space-between">
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              mb="3px"
            >
              Solicitação #{id}
            </Text>
            <NavLink to="/sistema/solicitacoes/coletas">
              <Button >
                Voltar
              </Button>
            </NavLink>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Grid
              templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
              templateRows={{ md: "repeat(1, 1fr)" }}
              gap="24px"
            >
              <FormControl >
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Status
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!isLoading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data && data.status ? data.status.toUpperCase() : null}
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl >
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Unidade
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!isLoading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    {data?.partner_place?.name || null}
                  </Text>
                </Skeleton>
              </FormControl>
              <FormControl >
                <FormLabel
                  color={textColor}
                  fontWeight="bold"
                  fontSize="xs"
                >
                  Quantidade de amostras de sangue
                </FormLabel>
                <Skeleton height={"25px"} isLoaded={!isLoading}>
                  <Text
                    color={textColor}
                    fontSize="lg"
                    fontWeight="bold"
                    mb="3px"
                  >
                    { data?.blood_sample || null}
                  </Text>
                </Skeleton>
              </FormControl>
            </Grid>
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  )
}

export default ViewReverse
