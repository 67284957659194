import React from "react";
import {ResponsiveBar} from "@nivo/bar";
import {useColorMode} from "@chakra-ui/react";
import {axisBottom, axisLeft, labelTextColor, themeDark, themeLight, tooltip} from "variables/nivo/default";
import {risksColors} from "variables/colors";
import Loading from "../../../Loading/Loading";
import {useEpidemicDash} from "../../../../hooks/epidemicDash";

const PreliminaryFactorsChart = () => {
    const { isLoadingGetAllFactors: isLoading, dash_val: data } = useEpidemicDash();
    
    const { colorMode } = useColorMode();

    if (!!data && !!data.get_all_factors && !isLoading) {
        return (
            <ResponsiveBar
                data={data.get_all_factors}
                keys={[
                    "Risco Baixo",
                    "Risco Médio",
                    "Risco Alto",
                ]}
                indexBy="status"
                margin={{top: 10, right: 20, bottom: 35, left: 180}}
                layout="horizontal"
                groupMode="stacked"
                padding={0.3}
                colors={risksColors}
                labelSkipWidth={11}
                theme={colorMode === "light" ? themeLight : themeDark}
                axisBottom={axisBottom}
                axisLeft={axisLeft}
                labelTextColor={labelTextColor}
                tooltip={({id, value}) => tooltip(id, value)}
            />
        )
    }

    return <Loading/>
};

export default PreliminaryFactorsChart
